import { createSlice } from '@reduxjs/toolkit';
import {
  getStoreById,
  getStores,
  getStoresRaw,
  getStoreUsers,
} from '../thunks';

export const initialState = {
  isLoading: false,
  stores: [],
  meta: {},
  error: {},
  isError: false,

  store: {},
  storeIsLoading: false,
  storeIsError: false,

  storeUsers: [],
  storeUsersMeta: [],
  storeUsersIsLoading: false,
  storeUsersIsError: false,
  storeUsersError: {},

  allUsers: [],
  allUsersMeta: [],
  allUsersIsLoading: false,
  allUsersIsError: false,
  allUsersError: {},
};

const storesSlice = createSlice({
  name: 'Stores',
  initialState,
  reducers: {
    cleanup: (state) => {
      state.store = {};
      state.storeIsError = false;

      state.storeUsers = [];
      state.storeUsersMeta = [];
      state.storeUsersIsError = false;

      state.allUsers = [];
      state.allUsersMeta = [];
      state.allUsers.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStores.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getStores.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stores = action.payload?.data;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getStores.rejected, (state, action) => {
      state.usersIsError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getStoreById.pending, (state, action) => {
      state.storeIsLoading = true;
    });
    builder.addCase(getStoreById.fulfilled, (state, action) => {
      state.storeIsLoading = false;
      state.store = action.payload;
    });
    builder.addCase(getStoreById.rejected, (state, action) => {
      state.storeIsError = true;
      console.log(action);
    });

    builder.addCase(getStoreUsers.pending, (state, action) => {
      state.storeUsersIsLoading = true;
    });
    builder.addCase(getStoreUsers.fulfilled, (state, action) => {
      state.storeUsersIsLoading = false;
      state.storeUsersMeta = action.payload?.meta;
      state.storeUsers = action.payload?.data;
    });
    builder.addCase(getStoreUsers.rejected, (state, action) => {
      state.storeUsersIsError = true;
      state.storeUsersError = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getStoresRaw.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getStoresRaw.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stores = action.payload;
    });
    builder.addCase(getStoresRaw.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });
  },
});

export const { cleanup } = storesSlice.actions;
export default storesSlice.reducer;
