import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import Breadcrumb from '../../Components/Common/BreadCrumb';
import TableContainer from '../../Components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getStores, getUsers } from '../../slices/thunks';
import { formatDate } from '../../helpers/format';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditStoresForm from './Edit';
import { Can } from '../../helpers/casl';
import Drawer from '../../Components/Common/Drawer';
import useQsParams from '../../Components/Hooks/QueryString';
import Search from '../../Components/Common/Search';
import ExportReport from '../../Components/Common/ExportReport';

const drawerParams = { view: '', edit: '', create: '' };

const drawerProps = {
  className: 'w-50',
  setParamsOnClose: { pageu: '', nameu: '' },
};

const Stores = (props) => {
  document.title = `${props.t('stores.heading')} | Opencashback`;

  const { searchParams, setSearchParams, setSearchParamsAsObject } =
		useQsParams();

  const dispatch = useDispatch();

  const data = useSelector(
    createSelector(
      (store) => store.Stores,
      (slice) => slice
    )
  );

  const getData = () => {
    const page = searchParams.get('page') ?? 1;
    const name = searchParams.get('name') ?? '';
    dispatch(getStores({ page, name, order: 'desc' }));
  };

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    getData();
  }, [searchParams.toString()]);

  const renderDrawer = (slug) => (
    <Drawer slug={slug} {...drawerProps}>
      <EditStoresForm
        onSuccess={() => {
          getData();
          dispatch(getUsers());
          setSearchParamsAsObject(drawerParams);
        }}
      />
    </Drawer>
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: props.t('stores.table.store_name'),
        Cell: ({ value }) => value ?? '-',
      },

      {
        accessor: 'channel',
        Header: props.t('stores.table.channel'),
        Cell: ({ value }) =>
          ({
            store: 'Loja física',
            ecommerce: 'E-commerce',
            integration: 'Integração',
          })[value] || '-',
        reportProps: {
          formatter: {
            type: 'enum',
            properties: {
              options: [
                { key: 'store', value: 'Loja física' },
                { key: 'ecommerce', value: 'E-commerce' },
                { key: 'integration', value: 'Integração' },
              ],
            },
          },
        },
      },

      {
        accessor: 'origin',
        Header: props.t('stores.table.origin'),
        Cell: ({ value }) =>
          ({
            integration: 'Integração',
            manual: 'Manual',
            import: 'Importação',
          })[value] || '-',
        reportProps: {
          formatter: {
            type: 'enum',
            properties: {
              options: [
                { key: 'integration', value: 'Integração' },
                { key: 'manual', value: 'Manual' },
                { key: 'import', value: 'Importação' },
              ],
            },
          },
        },
      },

      {
        accessor: 'created_at',
        Header: props.t('stores.table.created_at'),
        Cell: ({ value }) => formatDate(value, 'DD/MM/YYYY') ?? '-',
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },

      {
        accessor: 'users_count',
        Header: props.t('stores.table.users'),
      },

      {
        accessor: 'id',
        Header: props.t('actions'),
        Cell: ({ value }) => (
          <ul className="list-inline hstack gap-2 mb-0">
            {/* <Can I="update" a="account_users"> */}
            <li className="list-inline-item">
              <button
                type="button"
                className="bg-transparent p-0 border-0"
                onClick={() =>
                  setSearchParamsAsObject({ ...drawerParams, view: value })
                }
              >
                <i className="ri-eye-fill align-bottom text-muted" />
              </button>
            </li>

            <li className="list-inline-item">
              <button
                type="button"
                className="bg-transparent p-0 border-0"
                onClick={() =>
                  setSearchParamsAsObject({ ...drawerParams, edit: value })
                }
              >
                <i className="ri-pencil-fill align-bottom text-muted" />
              </button>
            </li>
            {/* </Can> */}
          </ul>
        ),
        reportProps: {
          exclude: true,
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={props.t('stores.heading')}
            pageTitle={props.t('account')}
            description={props.t('stores.description')}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4">
                    <Col md={6} xl={4}>
                      <Search
                        placeholder={props.t('search-by-name')}
                        value={searchParams?.get('name')}
                        onSubmit={({ id }) => {
                          setSearchParamsAsObject({ page: 1, name: id ?? '' });
                        }}
                      />
                    </Col>

                    {data?.isLoading && (
                      <Col>
                        <Spinner color="secondary" />
                      </Col>
                    )}

                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        {/* <Can I="create" a="account_users"> */}
                        <div>
                          <button
                            type="button"
                            className="btn btn-secondary add-btn"
                            id="create-btn"
                            onClick={() =>
                              setSearchParamsAsObject({
                                ...drawerParams,
                                create: true,
                              })
                            }
                          >
                            <i className="ri-add-line align-bottom me-1" />
                            {props.t('stores.new-store')}
                          </button>
                        </div>

                        <div>
                          <ExportReport
                            className="w-100"
                            pages={data?.meta?.pages}
                            disabled={data?.isLoading}
                            filename="Relatório OCK: Lojas"
                            service="stores"
                            columns={[...columns]}
                            // columns={[...columns, ...reportsOnlyColumns]}
                            // filters={parsedFilters}
                          />

                          {/* </Can> */}
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody className="pt-0">
                  <Can I="read" a="account_users">
                    <div>
                      <TableContainer
                        isLoading={data?.isLoading}
                        columns={columns}
                        data={data?.stores || []}
                        activePage={searchParams.get('page')}
                        setPage={(p) => setSearchParams('page', p)}
                        pageSize={data?.meta?.take || 10}
                        totalPages={data?.meta?.pages || 0}
                        count={data?.meta?.total || 0}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-0"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                    </div>
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ToastContainer position="top-center" closeButton={false} limit={1} />

      {renderDrawer('create')}
      {renderDrawer('view')}
      {renderDrawer('edit')}
    </React.Fragment>
  );
};

export default withTranslation()(Stores);
