import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import React from 'react';
import { requestReportExport } from '../../slices/reports/thunk';
import { getLoggedinUser } from '../../helpers/api_helper';

const ExportReport = ({
  className = '',
  filename = 'relatorio_ock',
  disabled = false,
  filters = {},
  data = {},
  pages = 1,
  take = 10,
  order = 'desc',
  method = 'get',
  service,
  columns,
  t,
}) => {
  const { access_token } = getLoggedinUser();
  const [userStores, setUserStores] = useState([]);

  const dispatch = useDispatch();
  const EXPORT_ITEMS_TAKE = 100;

  const isLoading = useSelector(
    createSelector(
      (state) => state.Reports.isLoading,
      (isLoading) => isLoading
    )
  );

  useEffect(() => {
    if (sessionStorage.getItem('authUser')) {
      const obj = JSON.parse(sessionStorage.getItem('authUser'));
      setUserStores([...obj?.stores]?.map((s) => s.id)?.join(','));
    }
  }, []);

  const opts = {
    filename,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'x-store-ids':
				sessionStorage.getItem('filterByStores') ?? userStores ?? '',
    },
    url: `${process.env.REACT_APP_API_URL}/${service}`,
    method,
  };

  const columnsMap = columns
    .filter((c) => !c?.reportProps?.exclude)
    .map((c) => ({
      header: c?.Header,
      name: c?.reportProps?.accessor ?? c?.accessor,
      isArray: c?.reportProps?.isArray ?? c?.isArray,
      ...(c?.reportProps?.formatter
        ? { formatter: c?.reportProps?.formatter }
        : {}),
    }));

  return (
    <>
      <button
        type="button"
        disabled={(disabled && access_token) || isLoading}
        className={classNames({
          'btn btn-secondary add-btn': true,
          [className]: true,
        })}
        onClick={() => {
          dispatch(
            requestReportExport({
              ...opts,
              columns: columnsMap,
              total_pages: Math.ceil((pages * take) / EXPORT_ITEMS_TAKE),
              data: {
                ...data,
                filters,
                order,
                take: EXPORT_ITEMS_TAKE,
              },
              success: t('export-report.success'),
              error: t('export-report.error'),
            })
          );
        }}
      >
        <i
          className={classNames({
            'align-bottom me-1': true,
            'ri-file-download-line': !isLoading,
            'ri-loader-5-line': isLoading,
          })}
        />
        {t('export-report.label')}
      </button>

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </>
  );
};

export default withTranslation()(ExportReport);
