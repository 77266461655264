import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import TableContainer from '../../Components/Common/TableContainer';
import { formatCurrency, formatDate } from '../../helpers/format';
import { useDispatch } from 'react-redux';
import { getCustomerExpirations } from '../../slices/customers/thunk';

const ExpiryDate = ({ document, setLoading, t }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});

  const getData = async () => {
    setLoading(true);
    const order = 'desc';
    const response = await dispatch(
      getCustomerExpirations({ document, params: { page, order } })
    );
    setLoading(false);

    if (response?.error) {
      return;
    }

    const payload = response.payload;

    setData(payload.data);
    setMeta(payload.meta);
  };

  useEffect(() => {
    getData();
  }, [dispatch, page]);

  const getOperationBadgeClasses = (operation) => {
    switch (operation) {
    case 'credit':
      return 'bg-success-subtle text-success';
    default:
      return 'bg-danger-subtle text-danger';
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: t('identifier'),
        accessor: 'identifier',
        filterable: false,
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        filterable: false,
        Cell: (props) => formatCurrency(props.row.original.amount),
      },
      {
        Header: t('operation'),
        accessor: 'operation',
        filterable: false,
        Cell: (props) => (
          <span
            className={`badge me-1 ${getOperationBadgeClasses(props.row.original.type)} `}
          >
            {t(`${props.row.original.type}`)}
          </span>
        ),
      },
      {
        Header: t('created-at'),
        accessor: 'created_at',
        filterable: false,
        Cell: (props) => formatDate(props.row.original.created_at),
      },
      {
        Header: t('expires-at'),
        accessor: 'expires_at',
        filterable: false,
        Cell: (props) => formatDate(props.row.original.expires_at),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={data || []}
        activePage={page}
        pageSize={meta?.take || 10}
        totalPages={meta?.pages || 0}
        count={meta?.total || 0}
        setPage={setPage}
        className="custom-header-css"
        divClass="table-responsive table-card mb-0"
        tableClass="align-middle table-nowrap"
        theadClass="table-light"
      />
    </React.Fragment>
  );
};

export default withTranslation()(ExpiryDate);
