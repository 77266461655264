import React, { useEffect, useReducer, useState } from 'react';
import { Col, Container, Row, Card, CardBody, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import BreadCrumb from '../../Components/Common/BreadCrumb';
import useQsParams from '../../Components/Hooks/QueryString';
import StepIndicator from '../../Components/Common/StepIndicator';

import {
  getMyWalletConfiguration as getConfigurations,
  setMyWalletConfigurations as setConfigurations,
} from '../../slices/my-wallet/thunk';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Preview from './components/Preview';

const MyWalletSettings = (props) => {
  const [hideOverlay, setHideOverlay] = useState(false);
  const reduxDispatch = useDispatch();
  const { qs } = useQsParams();

  document.title = `${props.t('my-wallet.menu')} | Opencashback`;

  const configuration = useSelector(
    createSelector(
      (store) => store.MyWallet,
      (slice) => slice.configuration
    )
  );

  const isLoading = useSelector(
    createSelector(
      (store) => store.MyWallet,
      (slice) => slice.isLoading
    )
  );

  useEffect(() => {
    reduxDispatch(getConfigurations());
  }, []);

  const updateConfigurations = async (values) => {
    const raw = await reduxDispatch(setConfigurations(values));

    if (raw?.payload?.status !== 200) {
      toast.error('Verifique os dados e tente novamente');
      return false;
    }

    toast.success('Configurações atualizadas!');
    return true;
  };

  const initialValues = {
    step: 'form',
    ...configuration,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,

    initialValues: { ...configuration },

    validationSchema: Yup.object({
      logoUrl: Yup.string().required(),
      spinnerColor: Yup.string().required(),

      auth: Yup.object({
        backgroundColor: Yup.string().required(),
        formBackgroundColor: Yup.string().required(),
        formLabelsColor: Yup.string().required(),
        ctaBackgroundColor: Yup.string().required(),
        ctaTextColor: Yup.string().required(),
      }),

      wallet: Yup.object({
        backgroundColor: Yup.string().required(),
        cashbackAvailableTextColor: Yup.string().required(),
        cashbackAvailableColor: Yup.string().required(),
        cashbackPendingColor: Yup.string().required(),
        cashbackToExpireTextColor: Yup.string().required(),
        cashbackToExpireColor: Yup.string().required(),
      }),
    }),

    onSubmit: updateConfigurations,
  });

  const [state, dispatch] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialValues
  );

  const STEPS = [
    {
      number: 1,
      step: 'general',
      label: props.t('my-wallet.step1.label'),
      description: props.t('my-wallet.step1.description'),
      component: (props) => <Step1 {...props} />,
      active: true,
    },

    {
      number: 2,
      step: 'auth',
      label: props.t('my-wallet.step2.label'),
      description: props.t('my-wallet.step2.description'),
      component: (props) => <Step2 {...props} />,
    },

    {
      number: 3,
      step: 'wallet',
      label: props.t('my-wallet.step3.label'),
      description: props.t('my-wallet.step3.description'),
      component: (props) => <Step3 {...props} />,
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={props.t('my-wallet.menu')}
          pageTitle={props.t('tools')}
        />

        {isLoading && !formik?.values ? (
          <Spinner color="secondary" />
        ) : (
          <>
            <Row className="mb-4">
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <h4>{props.t('my-wallet.heading')}</h4>
                        <span>{props.t('my-wallet.description')}</span>
                      </Col>

                      <Col className="d-flex align-items-center justify-content-end">
                        <>
                          <button
                            type="button"
                            className="btn btn-success btn-label right ms-4"
                            onClick={formik.submitForm}
                          >
                            {props.t('save-all')}
                            <i className="bx bx-save label-icon align-middle fs-16 ms-2" />
                          </button>
                        </>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="gap-5 gap-xl-0 mb-4">
              <Col xl={2}>
                <Row className="vstack g-4">
                  {STEPS.map((step, index) => (
                    <StepIndicator
                      key={`step-cta-${index}`}
                      onClick={() => setHideOverlay(false)}
                      {...step}
                    />
                  ))}
                </Row>
              </Col>

              <Col xl={4}>
                {isLoading ? (
                  <Spinner color="secondary" />
                ) : (
                  STEPS.map(
                    ({ component, ...step }, index) =>
                      Number(qs.get('active') ?? 1) === ++index && (
                        <div key={`step-${index}`}>
                          <StepIndicator heading {...step} />
                          {component({
                            step,
                            state,
                            dispatch,
                            formik,
                            disabled: !hideOverlay,
                            initialValues: formik.values,
                          })}
                        </div>
                      )
                  )
                )}
              </Col>

              <Col xl={6}>
                <Card
                  className="skeleton"
                  style={{
                    borderRadius: '0.3rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                  }}
                >
                  <Preview formik={formik} hideOverlay={setHideOverlay} />

                  {!hideOverlay && (
                    <div className="skeleton position-absolute w-100 h-100 rounded-3 d-flex justify-content-center align-items-center">
                      <div className="text-center text-secondary">
                        <Spinner />
                        <p className="mt-4 fw-semibold text-muted">
                          {props.t('my-wallet.loading')}
                        </p>
                      </div>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </div>
  );
};

export default withTranslation()(MyWalletSettings);
