import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getProducts as getProductsApi,
  getCategories as getCategoriesApi,
  batchImportProducts as batchImportProductsApi,
  deleteProduct as deleteProductApi,
  getBlockedProducts as getBlockedProductsApi,
} from '../../helpers/bff_helper';
import { toast } from 'react-toastify';

export const getProducts = createAsyncThunk(
  'catalogs/getProducts',
  async (params) => {
    try {
      const response = getProductsApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCategories = createAsyncThunk(
  'catalogs/getCategories',
  async (params) => {
    try {
      const response = getCategoriesApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const batchImportProducts = createAsyncThunk(
  'catalogs/batchImportProducts',
  async (params) => {
    try {
      const response = await batchImportProductsApi(params);
      toast.success(params?.successMessage);
      return JSON.stringify(response);
    } catch (error) {
      toast.error(params?.errorMessage);
      return error;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'catalogs/deleteProduct',
  async (params) => {
    try {
      const response = await deleteProductApi(params?.id);
      toast.success(params?.successMessage);
      return JSON.stringify(response);
    } catch (error) {
      toast.error(params?.errorMessage);
      return error;
    }
  }
);

export const getBlockedProducts = createAsyncThunk(
  'strategies/products/blocked',
  async (params) => {
    try {
      const response = await getBlockedProductsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);
