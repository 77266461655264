import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { useBreakpoints } from '../../../../Components/Hooks/BreakpointHooks';
import CurrencyInput from '../../../../Components/Common/CurrencyInput';
import { AbilityContext } from '../../../../helpers/casl';

const CashbackRedeemForm = ({ form, isLoading, ...props }) => {
  const ability = useContext(AbilityContext);
  const { isLg } = useBreakpoints();

  const isInvalid = (form, field) => {
    return form.touched[field] && form.errors[field];
  };

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title mb-0">
          {props.t('setup-cashback-rescue')}{' '}
          {isLoading && <Spinner size="sm" />}
        </h4>
      </CardHeader>
      <CardBody>
        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
        >
          <Input
            type="hidden"
            name="calculation_basis"
            disabled={ability.cannot('update', 'account_strategies')}
          />
          <div>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="minimum-order">
                    {props.t('minimum-order')}
                  </Label>
                  <div className="input-group">
                    <Input
                      name="minimum_order"
                      id="minimum-order"
                      className="form-control"
                      placeholder={`${props.t(
                        'setup-the'
                      )} ${props.t('percentage')}`}
                      type="text"
                      value={form.values.minimum_order}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      invalid={isInvalid(form, 'minimum_order') ? true : false}
                      disabled={ability.cannot('update', 'account_strategies')}
                    />
                    <span className="input-group-text">%</span>
                    {isInvalid(form, 'minimum_order') && (
                      <FormFeedback type="invalid">
                        {form.errors.minimum_order}
                      </FormFeedback>
                    )}
                  </div>
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="maximum-rescue">
                    {props.t('maximum-rescue')}
                  </Label>
                  <div className="input-group">
                    <span className="input-group-text">
                      {props.t('currency')}
                    </span>
                    <CurrencyInput
                      placeholder="0,00"
                      id="maximum-rescue"
                      onChange={(e) => form.setFieldValue('maximum_rescue', e)}
                      onBlur={form.handleBlur}
                      value={form.values.maximum_rescue}
                      invalid={isInvalid(form, 'maximum_rescue')}
                      disabled={ability.cannot('update', 'account_strategies')}
                    />
                    {isInvalid(form, 'maximum_rescue') && (
                      <FormFeedback type="invalid">
                        {form.errors.maximum_rescue}
                      </FormFeedback>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <div className="mb-2">
                  <div
                    className={`form-check form-switch d-flex mt-2 gap-1 align-items-center ${
                      isLg ? 'form-switch-lg' : 'form-switch-sm'
                    }`}
                    dir="ltr"
                  >
                    <Input
                      className="form-check-input mt-0"
                      type="checkbox"
                      value={form.values.block_discounts}
                      defaultChecked={form.values.block_discounts}
                      checked={form.values.block_discounts}
                      name="block_discounts"
                      id="rescue-block-discounts"
                      onChange={form.handleChange}
                      disabled={ability.cannot('update', 'account_strategies')}
                    />
                    <Label
                      className="form-label w-100 form-check-label lh-sm"
                      for="rescue-block-discounts"
                    >
                      {props.t('rescue-block-discounts')}
                    </Label>
                  </div>
                </div>
              </Col>

              <Col xs={12}>
                <div
                  className={`form-check form-switch d-flex mt-2 gap-1 align-items-center ${
                    isLg ? 'form-switch-lg' : 'form-switch-sm'
                  }`}
                  dir="ltr"
                >
                  <Input
                    className="form-check-input mt-0"
                    type="checkbox"
                    value={form.values.block_vtex_any_coupon}
                    defaultChecked={form.values.block_vtex_any_coupon}
                    checked={form.values.block_vtex_any_coupon}
                    name="block_vtex_any_coupon"
                    id="rescue_block_vtex_any_coupon"
                    onChange={form.handleChange}
                    disabled={ability.cannot('update', 'account_strategies')}
                  />
                  <Label
                    className="form-label w-100 form-check-label lh-sm"
                    for="rescue_block_vtex_any_coupon"
                    disabled={ability.cannot('update', 'account_strategies')}
                  >
                    {props.t('rescue-block-vtex-any-coupon')}
                  </Label>
                </div>
              </Col>
            </Row>
          </div>

          <div className="d-flex align-items-start gap-3 mt-4">
            <button
              type="submit"
              disabled={
                ability.cannot('update', 'account_strategies') ||
								!form.isValid ||
								form.isSubmitting
              }
              className="btn btn-success btn-label right ms-auto nexttab nexttab"
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>

              {props.t('save')}
              {isLoading && <Spinner className="ms-2" size="sm" />}
            </button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(CashbackRedeemForm);
