import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import useQsParams from "../../Components/Hooks/QueryString";
import Filter from "./components/Filter";
import Sales from "./components/Sales";
import Cashback from "./components/Cashback";
import { getOrdersFilters } from "../../slices/manager-panel/thunk";
import React, { useEffect } from "react";

const ManagerPanel = ({ t }) => {
  const dispatch = useDispatch();
  const { searchParams } = useQsParams();

  const filters = useSelector((state) => state.ManagerPanel.filters);

  document.title = `${t('manager-panel.heading')} | Opencashback`;

  useEffect(() => {
    dispatch(getOrdersFilters());
  }, [dispatch]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            isLoading={filters.isLoading}
            title={t("manager-panel.heading")}
            pageTitle="Gestão"
          />

          <Filter filters={filters.data} />

          <div className="d-flex flex-column gap-5">
            <Sales />
            <Cashback />
          </div>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(ManagerPanel);

