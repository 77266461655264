import React from 'react';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import InformativeCard from '../../Components/Common/InformativeCard';

const Communication = ({ t, communications }) => {
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <h5 className="text-decoration-underline mb-3 pb-1">
            {t('communications')}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <InformativeCard
            label={t('informative-communications-total-sended')}
            prefix=""
            suffix=""
            separator=""
            counter={communications.total}
            decimals={0}
            duration={4}
            bgcolor="primary"
            icon="bx bx-chat"
          />
        </Col>
        <Col lg={3}>
          <InformativeCard
            label={t('informative-communications-total-email-sended')}
            prefix=""
            suffix=""
            separator=""
            counter={communications.total_emails_sent}
            decimals={0}
            duration={4}
            bgcolor="primary"
            icon="bx bx-chat"
          />
        </Col>
        <Col lg={3}>
          <InformativeCard
            label={t('informative-communications-total-sms-sended')}
            prefix=""
            suffix=""
            separator=""
            counter={communications.total_sms_sent}
            decimals={0}
            duration={4}
            bgcolor="primary"
            icon="bx bx-chat"
          />
        </Col>
        <Col lg={3}>
          <InformativeCard
            label={t('informative-communications-total-whatsapp-sended')}
            prefix=""
            suffix=""
            separator="0"
            counter={communications.total_whatsapp_sent}
            decimals={0}
            duration={4}
            bgcolor="primary"
            icon="bx bx-chat"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(Communication);
