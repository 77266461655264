import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import qs from "qs";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

import { getCommunicationTemplates } from "../../../slices/communication/thunk";
import { formatDate } from "../../../helpers/format";
import { Can } from "../../../helpers/casl";
import { getLanguage } from "../../../i18n";
import { type } from "./types";

import TableContainer from "../../../Components/Common/TableContainer";
import ExportReport from "../../../Components/Common/ExportReport";
import useQsParams from "../../../Components/Hooks/QueryString";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Search from "../../../Components/Common/Search";

import StatusFilter from "./components/StatusFilter";
import Preview from "./components/Preview";
import Drawer from "../../../Components/Common/Drawer";
import TemplateForm from "./components/Form.js";
import classNames from "classnames";
import PresetsFilter from "../../../Components/Common/PresetsFilter.js";

const TemplatesManagement = (props) => {
  const dispatch = useDispatch();

  const { searchParams, setSearchParams, setSearchParamsAsObject } =
    useQsParams();

  const { templates, isLoading, meta } = useSelector(
    createSelector(
      (state) => state.Communication,
      ({ isLoading, templates, meta }) => ({ isLoading, templates, meta }),
    ),
  );

  useEffect(() => {
    dispatch(
      getCommunicationTemplates({
        qs: searchParams.toString(),
        language: getLanguage(),
      }),
    );
  }, [searchParams.toString(), getLanguage()]);

  const columns = [
    {
      Header: props.t("communication-templates.table.type"),
      Cell: (props) => type[props?.value] ?? "-",
      accessor: "type",
    },
    {
      Header: props.t("communication-templates.table.name"),
      accessor: "title",
    },
    {
      Header: props.t("communication-templates.table.created_at"),
      Cell: (props) => formatDate(props.value, "DD/MM/YYYY"),
      accessor: "created_at",
    },
    {
      Header: props.t("communication-templates.table.updated_at"),
      Cell: (props) => formatDate(props.value, "DD/MM/YYYY"),
      accessor: "updated_at",
    },
    {
      Header: props.t("communication-templates.table.rules"),
      accessor: "communication_rules",
      Cell: ({ value }) => {
        return (
          <ul className="list-group ms-3">
            {value.map((rule, key) => (
              <li key={key}>{rule.event_name}</li>
            ))}
          </ul>
        );
      },
      reportProps: {
        accessor: "communication_rules[0].event_name",
        isArray: true,
      },
    },
    {
      Header: props.t("communication-templates.table.status"),
      accessor: "active",
      Cell: ({ value }) => (
        <span
          className={classNames({
            "badge rounded-pill": true,
            "bg-success-subtle text-success": value,
            "bg-danger-subtle text-danger": !value,
          })}
        >
          {props.t(
            `communication-templates.table.${value ? "active" : "inactive"}`
          )}
        </span>
      ),
      reportProps: {
        formatter: {
          type: "boolean",
          properties: {
            option_true: props.t("communication-templates.table.active"),
            option_false: props.t("communication-templates.table.inactive"),
          },
        },
      },
    },
    {
      Header: props.t("actions"),
      Cell: (props) => {
        const id = props?.row?.original.id;
        const type = props?.row?.original.type;

        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <Can I="read" a="templates">
              <li className="list-inline-item">
                <button
                  type="button"
                  className="bg-transparent p-0 border-0"
                  onClick={() => setSearchParams("id", id)}
                >
                  <i className="ri-eye-fill align-bottom text-muted" />
                </button>
              </li>
            </Can>

            <Can I="update" a="templates">
              <li className="list-inline-item">
                <button
                  type="button"
                  className="bg-transparent p-0 border-0"
                  onClick={() =>
                    setSearchParamsAsObject({
                      edit: id,
                      t: type,
                    })
                  }
                >
                  <i className="ri-pencil-fill align-bottom text-muted" />
                </button>
              </li>
            </Can>

          </ul>
        );
      },
      reportProps: {
        exclude: true,
      },
    },
  ];

  document.title = `${props.t(
    "communication-templates.heading",
  )} | Opencashback`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={props.t("communication-templates.heading")}
            description={props.t("communication-templates.description")}
            pageTitle={props.t("communication")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-1 p-0">
                  <div className="p-3">
                    <Row className="row-cols-1 row-cols-sm-2 row-cols-md-12 g-2 g-lg-3">
                      <Col xs={12} xl={4}>
                        <Search
                          placeholder={props.t("search-by-name")}
                          value={searchParams?.get("title")}
                          onSubmit={({ id }) => {
                            const payload = { title: id || "", page: 1 };
                            setSearchParamsAsObject(payload);
                          }}
                        />
                      </Col>

                      {isLoading ? (
                        <Col xs={2} sm={2}>
                          <Spinner color="secondary" className="m-0" />
                        </Col>
                      ) : null}

                      <Col sm={12} md="auto" className="ms-auto">
                        <ExportReport
                          pages={meta?.pages}
                          disabled={isLoading || !templates.length}
                          filename={props.t("communication-templates.report")}
                          data={{
                            ...(searchParams.get("type")
                              ? { type: searchParams.get("type") }
                              : {}),
                            ...(searchParams.get("title")
                              ? { title: searchParams.get("title") }
                              : {}),
                            ...(searchParams.get("isActive")
                              ? { isActive: searchParams.get("isActive") }
                              : {}),
                            ...{ language: getLanguage() },
                          }}
                          filters={qs.parse(searchParams.toString())}
                          service="templates"
                          columns={columns}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-3">
                    <PresetsFilter
                      overwriteFilters={false}
                      header={false}
                      items={[
                        // { label: props.t('communication-templates.type.all'), value: { type: '' }, key: 'all' },
                        { label: props.t('communication-templates.type.sms'), value: { type: 'sms' }, key: 'sms' },
                        { label: props.t('communication-templates.type.email'), value: { type: 'email' }, key: 'email' },
                        { label: props.t('communication-templates.type.whatsapp'), value: { type: 'whatsapp' }, key: 'whatsapp' },
                      ]}
                    />
                  </div>

                  <StatusFilter />
                </CardHeader>

                <CardBody className="pt-0 mt-n3">
                  <TableContainer
                    isLoading={isLoading}
                    columns={columns}
                    data={templates || []}
                    activePage={searchParams.get("page")}
                    pageSize={meta?.take || 10}
                    totalPages={meta?.pages || 0}
                    count={meta?.itemCount || 0}
                    setPage={(p) => setSearchParams("page", p)}
                    className="custom-header-css"
                    divClass="table-responsive table-card"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Drawer
        slug="edit"
        className={["sms", "whatsapp"].includes(searchParams.get("t")) ? "w-50" : "w-75"}
      >
        <TemplateForm />
      </Drawer>

      <Preview />

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(TemplatesManagement);
