import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import ReactSelect from 'react-select';

import { isInvalid } from '../../helpers/functions';
import {
  patchStoreUsers,
  getStoreById,
  createStore,
  getStoreUsers,
  getUsers,
} from '../../slices/thunks';

import ActionButton from '../../Components/Common/ActionButton';
import useQsParams from '../../Components/Hooks/QueryString';
import UsersDataTable from './UserDataTable';
import { cleanup as cleanupStores } from '../../slices/stores/reducer';
import { cleanup as cleanupUsers } from '../../slices/users/reducer';
import QueryString from 'qs';

const storeChannelOptions = [
  { label: 'Loja física', value: 'store' },
  { label: 'E-commerce', value: 'ecommerce' },
];

const EditStoreForm = ({ onSuccess, t }) => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParamsAsObject, resetSearchParams } =
		useQsParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const storeId = searchParams.get('edit') || searchParams.get('view');
  const isView = !!searchParams.get('view');
  const isEdit = !!searchParams.get('edit');
  const isCreate = !!searchParams.get('create');

  useEffect(() => {
    const filters = {
      name: { like: searchParams.get('nameu') || '' },
    };
    if (storeId) {
      dispatch(
        getStoreUsers({
          storeId,
          all: !isView,
          page: searchParams.get('pageu') ?? 1,
          filters,
        })
      );
      dispatch(getStoreById(storeId));
    }

    if (!storeId) {
      dispatch(
        getUsers(
          QueryString.stringify(
            {
              page: searchParams.get('pageu') || 1,
              filters,
            },
            { addQueryPrefix: true }
          )
        )
      );
    }

    return () => {
      cleanupUsers();
      cleanupStores();
    };
  }, [storeId, searchParams.toString()]);

  const data = useSelector(
    createSelector(
      (store) => (isCreate ? store?.User : store?.Stores),
      (slice) => {
        return {
          store: slice?.store,
          isLoading: slice?.storeIsLoading ?? slice?.isLoading,
          meta: isCreate ? slice?.meta : slice?.storeUsersMeta,
          users: isCreate ? slice?.users : slice?.storeUsers,
        };
      }
    )
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      storeId,
      store_name: '',
      channel: data?.store?.channel || '',
      store_users: data?.store?.users,
    },

    validationSchema: Yup.object({
      store_name: Yup.string().when('storeId', {
        is: (value) => [null, undefined].includes(value),
        then: (f) => f.required(t('required')),
      }),
      channel: Yup.string().required(t('required')),
    }),

    onSubmit: async (values) => {
      let action = {};
      const users = values?.store_users?.map(({ id }) => id) || [];

      setIsSubmitting(true);

      if (!values?.storeId) {
        action = await dispatch(
          createStore({
            store: {
              origin: 'manual',
              channel: values.channel,
              name: values.store_name,
              users: [...users],
            },
            successMessage: 'Loja inserida com sucesso!',
            errorMessage:
							'Houve um erro ao inserir a loja. Tente novamente em instantes.',
          })
        );
      } else {
        action = await dispatch(
          patchStoreUsers({
            storeId,
            users: [...users],
            successMessage: 'Loja atualizada com sucesso!',
            errorMessage:
							'Houve um erro ao atualizar a loja. Tente novamente em instantes.',
          })
        );
      }

      setIsSubmitting(false);

      if (!action?.error?.message) {
        onSuccess();
        form.resetForm();
        resetSearchParams();
        cleanup();
      }
    },
  });

  return (
    <>
      <div className="row">
        <div className="col flex-grow-1">
          <Card className="m-0">
            <CardBody>
              <div className="d-flex gap-2">
                <div
                  className="d-flex flex-row gap-3 align-items-center"
                  style={{ height: 40 }}
                >
                  <h4 className="m-0">
                    {isView
                      ? data?.store?.name
                      : storeId
                        ? `Editar ${data?.store?.name}`
                        : 'Adicionar Loja'}
                    {/* {t("edit-user")} */}
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        {storeId || !isView ? (
          <div className="col-lg-auto col-12 mt-lg-0 mt-3">
            <Card className="m-0 h-100">
              <CardBody>
                <ActionButton
                  label={t('save')}
                  icon="bx-check"
                  // disabled={
                  //   data?.stores?.isLoading ||
                  //   data?.roles?.isLoading ||
                  //   isSubmitting
                  // }
                  onClick={form.handleSubmit}
                />
              </CardBody>
            </Card>
          </div>
        ) : null}
      </div>

      <Form className="tablelist-form mt-5" onSubmit={form.handleSubmit}>
        <Row className="g-3">
          {!storeId && !isView && (
            <>
              <Col md={8}>
                <Label htmlFor="store_name-field" className="form-label">
									Nome da loja
                  {/* {t("name")} */}
                </Label>

                <div className="input-group">
                  <Input
                    name="store_name"
                    id="store_name-field"
                    className="form-control"
                    placeholder="Insira o nome da loja"
                    type="text"
                    validate={{ required: { value: true } }}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.store_name || ''}
                    invalid={isInvalid(form, 'store_name')}
                  />
                  {isInvalid(form, 'store_name') && (
                    <FormFeedback type="invalid" display="flex">
                      {form.errors.store_name}
                    </FormFeedback>
                  )}
                </div>
              </Col>

              <Col md={4}>
                <Label htmlFor="channel-field" className="form-label">
									Canal
                  {/* {t("name")} */}
                </Label>

                <div>
                  <ReactSelect
                    id="channel-field"
                    name="channel"
                    // placeholder={t("status")}
                    placeholder="Canal da loja"
                    onChange={(v) => form.setFieldValue('channel', v?.value)}
                    value={storeChannelOptions.find(
                      (item) => item.value === form.values.channel
                    )}
                    errors={form.errors.channel}
                    options={storeChannelOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: form.errors.channel
                          ? '#ed5e5e'
                          : '#ced4da',
                      }),
                    }}
                  />
                  {isInvalid(form, 'channel') && (
                    <FormFeedback type="invalid" style={{ display: 'flex' }}>
                      {form.errors.channel}
                    </FormFeedback>
                  )}
                </div>
              </Col>
            </>
          )}

          <Col lg={12}>
            <UsersDataTable form={form} />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default withTranslation()(EditStoreForm);
