import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from 'reactstrap';
import * as Yup from 'yup';
import * as moment from 'moment';
import { isInvalid } from '../../helpers/functions';
import { useDispatch } from 'react-redux';
import { validateToken, sendToken } from '../../slices/thunks';
import config from '../../common/config';
import classNames from 'classnames';

const TwoFactor = ({
  isOpen,
  toggle,
  document,
  onSuccess,
  onClose,
  t,
  reset,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [canResendToken, setCanResendToken] = useState(true);
  const [timerResendToken, setTimerResendToken] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const createToken = async () => {
    return await dispatch(
      sendToken({
        document: document,
        params: {
          channel: 'sms',
          identifier: 'order-ock-light',
        },
        errorMessage: t('two-factor-authentication-token-error'),
      })
    );
  };
  const handleTwoFactorResend = async () => {
    if (canResendToken) {
      const response = await createToken();

      if (!response?.payload?.error) {
        setTimerResendToken(config.two_factor_authentication.resend_token_time);
        setCanResendToken(false);
      }
    }
  };

  useEffect(() => {
    if (reset) {
      validation.resetForm();
      setTimerResendToken(0);
      setCanResendToken(true);
    }
  }, [reset]);

  useEffect(() => {
    const ONE_SECOND_IN_MS = 1000;

    if (!canResendToken && timerResendToken > 0) {
      const interval = setInterval(() => {
        setTimerResendToken(timerResendToken - ONE_SECOND_IN_MS);
      }, ONE_SECOND_IN_MS);

      return () => clearInterval(interval);
    }

    if (timerResendToken <= 0) {
      setCanResendToken(true);
    }
  }, [canResendToken, timerResendToken]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      token: '',
    },
    validationSchema: Yup.object({
      token: Yup.string().required(t('validation-required-token')),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      const data = await dispatch(
        validateToken({
          document,
          params: {
            identifier: 'order-ock-light',
            token: values.token,
          },
        })
      );

      setIsLoading(false);

      if (data?.error || data.payload.valid === false) {
        validation.setFieldError('token', t('validation-invalid-token'));
        return false;
      }

      onSuccess(data.payload);
    },
  });

  return (
    <React.Fragment>
      <Modal
        onClosed={() => {
          validation.resetForm();
          onClose();
        }}
        id="showModal"
        isOpen={isOpen}
        toggle={toggle}
        centered
      >
        <ModalBody>
          <Form
            className="tablelist-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="g-3">
              <Col>
                <h4 className="text-center">{t('2fa-title')}</h4>
              </Col>
            </Row>
            <Row className="g-3 mt-3 text-center">
              <Col>
                <div className="input-group mb-3 positon-relative">
                  {!validation.errors['token'] ? (
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="position-absolute bg-transparent end-0 translate-middle-y top-50 border-0"
                      style={{ zIndex: 400, lineHeight: 1 }}
                    >
                      <i
                        className={classNames({
                          'bx me-2 fs-4 text-muted': true,
                          'bx-show': !showPassword,
                          'bx-hide': showPassword,
                        })}
                      />
                    </button>
                  ) : null}

                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name="token"
                    mask="999999"
                    placeholder="••••••"
                    value={validation.values.token}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    className="text-center p-2 fs-3 position-relative"
                    style={{ letterSpacing: '.5rem' }}
                    invalid={isInvalid(validation, 'token')}
                    tag={ReactInputMask}
                  />
                  {isInvalid(validation, 'token') && (
                    <FormFeedback type="invalid">
                      {' '}
                      {validation.errors.token}{' '}
                    </FormFeedback>
                  )}
                </div>
                <span className="text-muted text-center">
                  <small>{t('2fa-expiration')}</small>
                </span>
              </Col>
            </Row>
            <Row className="text-center pt-1">
              {!canResendToken &&
							timerResendToken > 0 &&
							moment.utc(timerResendToken).isValid() ? (
                  <span>{moment.utc(timerResendToken).format('mm:ss')}</span>
                ) : (
                  <Button
                    style={{
                      background: 'transparent',
                      border: 'none',
                      textDecoration: 'underline',
                    }}
                    onClick={handleTwoFactorResend}
                  >
                    {t('two-factor-resend-code')}
                  </Button>
                )}
            </Row>
            <Row className="g-3 mt-3 text-center">
              <Col>
                <Button type="submit" color="tertiary">
                  {t('order-step')}
                  {isLoading && <Spinner size="sm" className="ms-2" />}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(TwoFactor);
