import flagus from '../assets/images/flags/us.svg';
import flagbr from '../assets/images/flags/br.svg';

const languages = {
  en: {
    label: 'English',
    flag: flagus,
  },
  pt: {
    label: 'Portugues',
    flag: flagbr,
  },
};

export default languages;
