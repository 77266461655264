import { createSlice } from '@reduxjs/toolkit';
import {
  getStrategyProducts,
  getAccountStrategy,
  getStrategies,
  getStrategyFilters,
} from './thunk';

export const initialState = {
  isLoading: false,
  account: {
    accumulation: {},
    rescue: {},
  },
  strategies: [],
  products: [],
  meta: {},
  error: {},
  isError: false,
  filters: [],
};

const strategiesSlice = createSlice({
  name: 'Strategies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountStrategy.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAccountStrategy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.account = action.payload;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getAccountStrategy.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getStrategies.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getStrategies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.strategies = action.payload?.data;
      state.products = []; // reset/empty slice
      state.meta = action.payload?.meta;
    });
    builder.addCase(getStrategies.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getStrategyProducts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getStrategyProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.meta = action.payload?.meta;
      state.products = action?.payload?.data?.map((item) => {
        item.id = item.external_id;
        return item;
      });
    });
    builder.addCase(getStrategyProducts.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getStrategyFilters.fulfilled, (state, action) => {
      const filters = [
        {
          field: 'strategy_type',
          name: 'strategies.strategy.table.strategy',
          type: 'text',
          values: [
            {
              label: 'strategies.strategy.table.strategy-product',
              value: 'product',
            },
            {
              label: 'strategies.strategy.table.strategy-account',
              value: 'account',
            },
          ],
        },
        {
          field: 'strategy_name',
          name: 'strategies.catalog.table.strategy-name',
          type: 'text',
          values:
						action.payload?.filters
						  ?.find((x) => x.field === 'strategy_name')
						  ?.values.map((x) => ({
						    label: x,
						    value: x,
						  })) || [],
        },
        {
          field: 'category_id',
          name: 'strategies.strategy.table.category-name',
          type: 'in',
          values:
						action.payload?.filters
						  ?.find((x) => x.field === 'category_id')
						  ?.values.map((x) => ({
						    label: x?.name,
						    value: x?.id,
						  })) || [],
        },
        {
          field: 'created_at',
          name: 'created-at',
          type: 'date',
          options: { maxDate: false },
          values: [],
        },
        {
          field: 'external_id',
          name: 'strategies.strategy.table.code',
          type: 'eq-as-raw',
          values: [],
        },
        {
          field: 'name',
          name: 'strategies.strategy.table.name',
          type: 'like',
          values: [],
        },
      ];

      state.filters = filters;
    });
  },
});

export default strategiesSlice.reducer;
