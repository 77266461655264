import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from 'reactstrap';

import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import withRouter from '../../Components/Common/withRouter';
import logo from '../../assets/images/logo.svg';
import { getRecoveryLink } from '../../slices/users/thunk';

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();

  const [recoveryMessage, setRecoveryMessage] = useState({
    label: props.t('password-recovery.instructions'),
    type: 'warning',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(props.t('password-recovery.required'))
        .email(props.t('password-recovery.invalid')),
    }),
    onSubmit: async (values) => {
      const action = await dispatch(getRecoveryLink(values.email));
      const hasError = action?.payload?.error;
      const message = !hasError ? 'sent-success' : 'sent-error';

      setRecoveryMessage({
        label: props.t(`password-recovery.${message}`),
        type: !hasError ? 'success' : 'danger',
      });

      formik.resetForm();
    },
  });

  document.title = 'Recuperar senha | Opencashback';

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={logo} alt="" height="40" />
                </Link>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">
                      {props.t('password-recovery.heading')}
                    </h5>
                  </div>

                  <Alert
                    color={recoveryMessage.type}
                    className="mt-3 text-center"
                  >
                    {recoveryMessage.label}
                  </Alert>

                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">{props.t('email')}</Label>
                        <Input
                          className="form-control"
                          placeholder={props.t('password-recovery.form.email')}
                          invalid={formik.touched.email && formik.errors.email}
                          value={formik.values.email || ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          inputMode="email"
                          name="email"
                          type="email"
                        />

                        {formik.touched.email && formik.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{formik.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-center mt-4">
                        <button
                          className="btn btn-success w-100"
                          disabled={!formik.isValid}
                          type="submit"
                        >
                          {props.t('password-recovery.form.submit')}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center text-primary text-decoration-underline">
                <Link className="fw-semibold" to="/login">
                  {props.t('password-recovery.form.cancel')}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(withTranslation()(ForgetPasswordPage));
