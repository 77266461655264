import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import InformativeCard from '../../Components/Common/InformativeCard';
import classnames from 'classnames';
import Statement from './Statement';
import Operations from './Operations';
import ExpiryDate from './ExpiryDate';
import { useDispatch, useSelector } from 'react-redux';
import { first, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { getCustomer } from '../../slices/customers/thunk';
import { formatDate, formatDocument } from '../../helpers/format';
import * as moment from 'moment';
import CustomerDetails from '../../Components/Common/CustomerDetails';

const DigitalWalletDetail = (props) => {
  const { document: documentParam } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [statementPage, setStatementPage] = useState(1);
  const [operationsPage, setOperationsPage] = useState(1);
  const [expirationsPage, setExpirationsPage] = useState(1);
  const [customer, setCustomer] = useState({});
  const [wallet, setWallet] = useState({});
  const [pendingBalance, setPendingBalance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [statementLoading, setStatementLoading] = useState(false);
  const [operationsLoading, setOperationsLoading] = useState(false);
  const [expirationsLoading, setExpirationsLoading] = useState(false);

  if (isEmpty(documentParam)) {
    navigate('/carteiras');
  }

  const getData = async () => {
    setIsLoading(true);

    const response = await dispatch(getCustomer({ document: documentParam }));
    setIsLoading(false);

    if (response?.error) {
      return;
    }

    const data = response.payload;

    setCustomer(data.customer);
    setWallet(first(data.customer.wallets));
    setPendingBalance(data.pending_balance);
  };

  useEffect(() => {
    getData();
  }, [dispatch, statementPage, operationsPage, expirationsPage]);

  const [cardHeaderTab, setCardHeaderTab] = useState('1');

  document.title = 'Detalhes da carteira | Opencashback';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            showBackButton
            title="Detalhe da carteira"
            pageTitle="Gestão"
          />

          <Row>
            <Col xl={3}>
              <CustomerDetails
                wallet={wallet}
                customer={customer}
                isLoading={isLoading}
              />
            </Col>
            <Col xl={9}>
              <Row>
                <Col lg={4}>
                  <InformativeCard
                    label={props.t('balance')}
                    prefix="R$"
                    suffix=""
                    separator=","
                    counter={(wallet?.balance && wallet.balance / 100) || 0}
                    decimals={2}
                    duration={4}
                    link_to="#"
                    link_name={props.t('balance-description')}
                    link_deactive={true}
                    bgcolor="primary"
                    icon="bx bx-wallet"
                  />
                </Col>
                <Col lg={4}>
                  <InformativeCard
                    label={props.t('pending-cashback')}
                    prefix="R$"
                    suffix=""
                    separator=","
                    counter={(pendingBalance && pendingBalance / 100) || 0}
                    decimals={2}
                    duration={4}
                    link_to="#"
                    link_name={props.t('pending-cashback-description')}
                    link_deactive={true}
                    bgcolor="info"
                    icon="bx bx-timer"
                  />
                </Col>
                <Col lg={4}>
                  <InformativeCard
                    label={props.t('expiring-balance')}
                    prefix="R$"
                    suffix=""
                    separator="."
                    counter={
                      (wallet?.expires_soon && wallet.expires_soon / 100) || 0
                    }
                    decimals={2}
                    duration={4}
                    link_to="#"
                    link_name={props.t('expiring-balance-description')}
                    link_deactive={true}
                    bgcolor="secondary"
                    icon="bx bxs-leaf"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <Nav
                        tabs
                        className="nav nav-justified nav-tabs-custom rounded card-header-tabs border-bottom-0"
                      >
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: cardHeaderTab === '1',
                            })}
                            onClick={() => {
                              setCardHeaderTab('1');
                            }}
                          >
                            {props.t('statement')}
                            {statementLoading && (
                              <Spinner size="sm" className="ms-2" />
                            )}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: cardHeaderTab === '2',
                            })}
                            onClick={() => {
                              setCardHeaderTab('2');
                            }}
                          >
                            {props.t('sales')}
                            {operationsLoading && (
                              <Spinner size="sm" className="ms-2" />
                            )}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: cardHeaderTab === '3',
                            })}
                            onClick={() => {
                              setCardHeaderTab('3');
                            }}
                          >
                            {props.t('expiry-date')}
                            {expirationsLoading && (
                              <Spinner size="sm" className="ms-2" />
                            )}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent
                        activeTab={cardHeaderTab}
                        className="text-muted"
                      >
                        <TabPane tabId="1" id="statements">
                          <Statement
                            document={documentParam}
                            setLoading={setStatementLoading}
                          />
                        </TabPane>

                        <TabPane tabId="2" id="operations">
                          <Operations
                            document={documentParam}
                            setLoading={setOperationsLoading}
                          />
                        </TabPane>

                        <TabPane tabId="3" id="expirations">
                          <ExpiryDate
                            document={documentParam}
                            setLoading={setExpirationsLoading}
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(DigitalWalletDetail);
