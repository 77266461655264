import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import withRouter from '../../Components/Common/withRouter';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// actions
import { loginUser, resetLoginFlag } from '../../slices/thunks';

import logo from '../../assets/images/logo.svg';
import { createSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import Navdata from '../../Layouts/LayoutMenuData';
import { isEmpty } from 'lodash';

const Login = (props) => {
  const dispatch = useDispatch();

  const params = new URLSearchParams(window.location.search);
  const hasTokenError = params.get('te');

  const selectLayoutState = (state) => state.Account;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      user: layout.user,
      errorMsg: layout.errorMsg,
      error: layout.error,
    })
  );

  const { user, errorMsg, error } = useSelector(selectLayoutProperties);
  const menuItems = Navdata().props.children;

  const [userLogin, setUserLogin] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (user && user) {
      const updatedUserData =
				process.env.REACT_APP_DEFAULTAUTH === 'firebase'
				  ? user.multiFactor.user.email
				  : user.user.email;
      const updatedUserPassword =
				process.env.REACT_APP_DEFAULTAUTH === 'firebase'
				  ? ''
				  : user.user.confirm_password;
      setUserLogin({
        email: updatedUserData,
        password: updatedUserPassword,
      });
    }
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Por favor digite o seu Email')
        .email('O email é inválido'),
      password: Yup.string().required('Por favor digite a sua Senha'),
    }),
    onSubmit: async (values) => {
      setDisabled(true);
      const subjects = await dispatch(loginUser(values));

      if (!isEmpty(subjects)) {
        const [redirectTo] = menuItems
          .filter((item) => !item.isHeader)
          .filter((i) => i?.permissions?.some((k) => subjects.includes(k)));

        if (sessionStorage.getItem('authUser')) {
          const link = redirectTo?.subItems?.[0]?.link ?? redirectTo?.link;
          return props.router.navigate(link);
        }
      }

      setDisabled(false);
    },
  });

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  document.title = 'Login | Opencashback';

  return (
    <React.Fragment>
      <ToastContainer />
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logo} alt="" height="40" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">
                        {props.t('welcome-back')}
                      </h5>
                    </div>

                    {hasTokenError ? (
                      <Alert color="danger" className="mt-3 text-center">
                        {props.t(`password-recovery.token-invalid`)}
                      </Alert>
                    ) : null}

                    {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            {props.t('email')}
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="text"
                            disabled={disabled}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
															validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
													validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/recuperar-senha" className="text-muted">
                              {props.t('password-recovery.heading')}
                            </Link>
                          </div>

                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            {props.t('password')}
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ''}
                              type={passwordShow ? 'text' : 'password'}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              disabled={disabled}
                              invalid={
                                validation.touched.password &&
																validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
														validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              onClick={() => setPasswordShow(!passwordShow)}
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            {props.t('remember-me')}
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={disabled}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {disabled ? (
                              <Spinner size="sm" className="me-2" />
                            ) : null}
                            {props.t('signin')}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));
