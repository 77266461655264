import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

const InformativeCard = ({
  label,
  additionalInfo,
  badge,
  badgeClass,
  prefix,
  suffix,
  separator,
  counter,
  decimal,
  decimals,
  duration,
  link_to,
  link_name,
  link_deactive,
  bgcolor,
  icon,
}) => {
  return (
    <React.Fragment>
      <Card className="card-animate">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                {label}
              </p>
            </div>
            <div className="flex-shrink-0">
              {additionalInfo && (
                <h5 className={'fs-14 mb-0 text-' + badgeClass}>
                  {badge ? (
                    <i className={'fs-13 align-middle ' + badge}></i>
                  ) : null}{' '}
                  {additionalInfo}
                </h5>
              )}
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-between mt-4">
            <div>
              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                <span className="counter-value" data-target={counter}>
                  <CountUp
                    start={0}
                    prefix={prefix}
                    suffix={suffix}
                    decimal={decimal || ','}
                    separator={separator}
                    end={counter}
                    decimals={decimals}
                    duration={duration}
                  />
                </span>
              </h4>
              <Link
                to={link_to}
                className={`text-decoration-underline text-muted ${link_deactive ? 'pe-none' : 'pe-auto'}`}
              >
                {link_name}
              </Link>
            </div>

            {icon ? (
              <div className="avatar-sm flex-shrink-0">
                <span
                  className={
                    'avatar-title rounded fs-3 bg-' + bgcolor + '-subtle'
                  }
                >
                  <i className={`text-${bgcolor} ${icon}`}></i>
                </span>
              </div>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default InformativeCard;
