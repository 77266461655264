import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withRouter from '../Components/Common/withRouter';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
  changeSidebarVisibility,
} from '../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { AbilityContext } from '../helpers/casl';
import { useAbilities } from '../Components/Hooks/UserHooks';
import { cleanupStoreHeaders } from '../helpers/api_helper';

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState('');
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
      leftSidebarType: layout.leftSidebarType,
      layoutModeType: layout.layoutModeType,
      layoutWidthType: layout.layoutWidthType,
      layoutPositionType: layout.layoutPositionType,
      topbarThemeType: layout.topbarThemeType,
      leftsidbarSizeType: layout.leftsidbarSizeType,
      leftSidebarViewType: layout.leftSidebarViewType,
      leftSidebarImageType: layout.leftSidebarImageType,
      sidebarVisibilitytype: layout.sidebarVisibilitytype,
    })
  );

  const abilities = useAbilities();

  // Inside your component
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
  } = useSelector(selectLayoutProperties);

  /*
  layout settings
  */
  useEffect(() => {
    if (
      layoutType ||
			leftSidebarType ||
			layoutModeType ||
			layoutWidthType ||
			layoutPositionType ||
			topbarThemeType ||
			leftsidbarSizeType ||
			leftSidebarViewType ||
			leftSidebarImageType ||
			sidebarVisibilitytype
    ) {
      window.dispatchEvent(new Event('resize'));
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarImageType(leftSidebarImageType));
      dispatch(changeSidebarVisibility(sidebarVisibilitytype));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
    dispatch,
  ]);
  /*
  call dark/light mode
  */
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  });
  function scrollNavigation() {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow');
    } else {
      setHeaderClass('');
    }
  }

  useEffect(() => {
    if (
      sidebarVisibilitytype === 'show' ||
			layoutType === 'vertical' ||
			layoutType === 'twocolumn'
    ) {
      document.querySelector('.hamburger-icon').classList.remove('open');
    } else {
      document.querySelector('.hamburger-icon').classList.add('open');
    }
  }, [sidebarVisibilitytype, layoutType]);

  const [hasTawkToWidget, setTawkToWidget] = useState(false);

  useEffect(() => {
    // remove header when page is updated (page navigation)
    cleanupStoreHeaders();
  }, [window?.location?.pathname]);

  useEffect(() => {
    if (!hasTawkToWidget) {
      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_API.customStyle = { zIndex: 2 };

      (function () {
        const s1 = document.createElement('script');
        const s0 = document.getElementsByTagName('script')[0];

        s1.async = true;
        s1.src = 'https://embed.tawk.to/65e634929131ed19d974ecb0/1ho5jqnu6';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);

        setTawkToWidget(true);
      })();
    }

    return () => {
      const authScreens = ['/login', '/logout'];
      const isAuthScreen = authScreens.includes(window?.location?.pathname);
      const [widgetEl] = document.getElementsByClassName('widget-visible');

      if (isAuthScreen && widgetEl) {
        setTawkToWidget(false);
        widgetEl.remove();
      }
    };
  }, [window?.location?.pathname, hasTawkToWidget]);

  return (
    <React.Fragment>
      <AbilityContext.Provider value={abilities}>
        <div id="layout-wrapper">
          <Header
            headerClass={headerClass}
            layoutModeType={layoutModeType}
            onChangeLayoutMode={onChangeLayoutMode}
          />
          <Sidebar layoutType={layoutType} />
          <div className="main-content">
            {props.children}
            <Footer />
          </div>
        </div>
      </AbilityContext.Provider>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);
