import React from 'react';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import InformativeBar from '../../Components/Common/InformativeBar';

const Customer = ({ t, customers }) => {
  const format_percentage = (value) =>
    (value && (value * 100).toFixed(2)) || '0';

  const infos = [
    {
      col_lg: 4,
      col_xl: 2,
      label: t('informative-customers-total-wallets'),
      icon: 'ri-space-ship-line',
      value: customers.total,
    },
    {
      col_lg: 4,
      col_xl: 2,
      label: t('informative-customers-total-wallets-with-phone'),
      icon: 'ri-space-ship-line',
      value: customers.total_with_cellphone,
    },
    {
      col_lg: 4,
      col_xl: 2,
      label: t('informative-customers-total-wallets-with-email'),
      icon: 'ri-space-ship-line',
      value: customers.total_with_email,
    },
    {
      col_lg: 4,
      col_xl: 2,
      label: t('informative-customers-total-wallets-with-name'),
      icon: 'ri-space-ship-line',
      value: customers.total_with_name,
    },
    {
      col_lg: 4,
      col_xl: 2,
      label: t('informative-customers-total-wallets-with-birthday'),
      icon: 'ri-space-ship-line',
      value: customers.total_with_birthdate,
    },
    {
      col_lg: 4,
      col_xl: 2,
      label: t('informative-customers-total-wallets-with-document'),
      icon: 'ri-space-ship-line',
      value: customers.total_with_document,
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <h5 className="text-decoration-underline mb-3 pb-1">
            {t('customers')}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <InformativeBar infos={infos} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(Customer);
