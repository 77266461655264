import React, { useEffect, useState, useMemo, useReducer } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from 'react-redux';
import Filter, { getFilters } from '../../Components/Common/Filter';

import { createSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { getOrdersFilters, getOrders } from '../../slices/orders/thunk';
import {
  formatDocument,
  formatDate,
  formatCurrency,
} from '../../helpers/format';
import { isEmpty } from 'lodash';
import { Can } from '../../helpers/casl';
import { cleanDocument } from '../../helpers/functions';
import moment from 'moment';
import TableOrderManagement from './components/TableOrderManagement';
import { translations } from './statuses';
import StatusDropdown from './details/components/StatusDropdown';
import useQsParams from '../../Components/Hooks/QueryString';
import { cleanupStoreHeaders } from '../../helpers/api_helper';

const OrdersManagement = (props) => {
  const dispatch = useDispatch();
  const { searchParams, setSearchParams } = useQsParams();

  const orderState = createSelector(
    (state) => state.Order.orders,
    (orders) => orders
  );

  const loadingState = createSelector(
    (state) => state.Order.isLoading,
    (isLoading) => isLoading
  );

  const metaState = createSelector(
    (state) => state.Order.meta,
    (meta) => meta
  );

  const filtersState = createSelector(
    (state) => state.Order.filters,
    (filters) => filters
  );

  const orders = useSelector(orderState);
  const isLoading = useSelector(loadingState);
  const meta = useSelector(metaState);
  const orderFilters = useSelector(filtersState);
  const [filtersParams, setFiltersParams] = useState({});

  useEffect(() => {
    getData();
    dispatch(getOrdersFilters());
  }, [dispatch, searchParams.toString()]);

  const filters = [
    {
      field: 'total',
      name: 'Total da venda',
      type: 'range',
    },

    {
      field: 'generated_cashback',
      name: 'Cashback gerado',
      type: 'range',
    },

    {
      field: 'used_cashback',
      name: 'Cashback consumido',
      type: 'range',
    },

    {
      field: 'cashback_available',
      name: 'Cashback disponível',
      type: 'range',
    },

    {
      field: 'customer_cellphone',
      name: 'Telefone completo',
      type: 'radio',
    },

    {
      field: 'customer_cellphone_verified',
      name: 'Telefone validado',
      type: 'radio',
    },

    {
      field: 'customer_email',
      name: 'Cliente com e-mail',
      type: 'radio',
    },

    {
      field: 'origin',
      name: props.t('order-origin'),
      type: 'checkbox',
    },
    {
      field: 'channel',
      name: props.t('order-channel'),
      type: 'checkbox',
    },
    {
      field: 'seller',
      name: props.t('order-seller'),
      type: 'checkbox',
    },

    {
      field: 'customer_birthdate',
      name: 'Data de aniversário',
      type: 'date',
      options: {
        maxDate: false,
      },
    },

    {
      field: 'cashback_expires_at',
      name: 'Data expiração cashback',
      type: 'date',
      options: {
        maxDate: false,
      },
    },

    {
      field: 'availability_date',
      name: 'Data disponibilização cashback',
      type: 'date',
      options: {
        maxDate: false,
      },
    },

    {
      field: 'created_at',
      name: 'Data da venda',
      type: 'date',
    },
  ];

  const orderStatus = [
    {
      label: props.t(`orders-management.cashback-status-created`),
      value: 'created',
    },
    {
      label: props.t(`orders-management.cashback-status-scheduled`),
      value: 'scheduled',
    },
    {
      label: props.t(`orders-management.cashback-status-erro`),
      value: 'invalid',
    },
    {
      label: props.t(`orders-management.cashback-status-done`),
      value: 'done',
    },
    {
      label: props.t(`orders-management.cashback-status-cancelled`),
      value: 'cancelled',
    },
    {
      label: props.t(`orders-management.cashback-status-expired`),
      value: 'expired',
    },
  ];

  const getData = () => {
    const params = { page: searchParams.get('page') ?? 1 };
    const appliedFilters = getFilters(filters, [
      { field: 'external_id', type: 'like' },
      { field: 'document', type: 'like' },
      { field: 'status', type: 'in' },
      { field: 'customer_cellphone_verified', type: 'isnull' },
      { field: 'customer_cellphone', type: 'isnull' },
      { field: 'customer_email', type: 'isnull' },
      { field: 'filterBy', type: 'eq-as-array' },
    ]);

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    const storesIds = appliedFilters?.filterBy;
    if (storesIds) {
      sessionStorage.setItem('filterByStores', storesIds?.join(','));
    }

    setFiltersParams(params.filters);
    dispatch(getOrders(params));
  };

  const getTranslationOrderStatus = (status) => {
    return translations[status];
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t('ticket'),
        Cell: (props) => (
          <Link
            to={`${props?.row?.original?.id}`}
            style={{ borderBottom: '1px solid lightgray' }}
          >
            {props?.row?.original?.ticket}
          </Link>
        ),
        reportProps: {
          accessor: 'ticket',
        },
      },
      {
        Header: props.t('document'),
        accessor: 'document',
        filterable: false,
        Cell: (props) => {
          if (props?.row?.original?.document)
            return formatDocument(props.row.original.document);

          return '-';
        },
        reportProps: {
          formatter: {
            type: 'mask',
            properties: { custom: 'document' },
          },
        },
      },
      {
        Header: props.t('order-date'),
        accessor: 'date',
        filterable: false,
        Cell: (props) => formatDate(props.row.original.date),
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },
      {
        Header: props.t('order-origin'),
        accessor: 'origin',
        filterable: false,
        Cell: (info) => {
          const value = info.row.original.origin || '-';
          if (value !== '-') {
            return props.t(`order-origin-${value}`);
          }

          return value;
        },
        reportProps: {
          accessor: 'origin',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'vtex',
                  value: props.t('order-origin-vtex'),
                },
                {
                  key: 'panel_ock',
                  value: props.t('order-origin-panel_ock'),
                },
                {
                  key: 'linx_pos',
                  value: props.t('order-origin-linx_pos'),
                },
                {
                  key: 'dito',
                  value: props.t('order-origin-dito'),
                },
                {
                  key: 'pdv',
                  value: props.t('order-origin-pdv'),
                },
                {
                  key: 'not_informed',
                  value: props.t('order-origin-not_informed'),
                },
                {
                  key: 'SmartNfe',
                  value: props.t('order-origin-SmartNfe'),
                },
                {
                  key: 'mobility_food',
                  value: props.t('order-origin-mobility_food'),
                },
              ],
            },
          },
        },
      },
      {
        Header: props.t('order-channel'),
        accessor: 'channel',
        filterable: false,
        Cell: (info) => {
          const value = info.row.original.channel || '-';
          if (value !== '-') {
            return props.t(`order-channel-${value}`);
          }

          return value;
        },
        reportProps: {
          accessor: 'channel',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'ecommerce',
                  value: props.t('order-channel-ecommerce'),
                },
                {
                  key: 'store',
                  value: props.t('order-channel-store'),
                },
              ],
            },
          },
        },
      },
      {
        Header: props.t('order-store'),
        accessor: 'store',
        filterable: false,
        Cell: (props) => props.row.original.store || '-',
        reportProps: {
          accessor: 'store',
        },
      },
      {
        Header: props.t('order-seller'),
        accessor: 'seller',
        filterable: false,
        Cell: (props) => props.row.original.seller || '-',
        reportProps: {
          accessor: 'seller',
        },
      },
      {
        Header: props.t('order-total'),
        accessor: 'total',
        filterable: false,
        Cell: (props) => formatCurrency(props.row.original.total),
        reportProps: {
          formatter: {
            type: 'currency',
            properties: {
              currency: 'BRL',
              centsToCurrency: true,
            },
          },
        },
      },
      {
        Header: props.t('order-status'),
        accessor: 'status',
        filterable: false,
        Cell: (props) => {
          const translation = getTranslationOrderStatus(
            props.row.original.status
          );

          return (
            <React.Fragment>
              <span className={`badge rounded-pill ${translation.color}`}>
                {translation.label}
              </span>
            </React.Fragment>
          );
        },
        reportProps: {
          accessor: 'status',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'created',
                  value: getTranslationOrderStatus('created')?.label,
                },
                {
                  key: 'scheduled',
                  value: getTranslationOrderStatus('scheduled')?.label,
                },
                {
                  key: 'done',
                  value: getTranslationOrderStatus('done')?.label,
                },
                {
                  key: 'cancelled',
                  value: getTranslationOrderStatus('cancelled')?.label,
                },
                {
                  key: 'expired',
                  value: getTranslationOrderStatus('expired')?.label,
                },
                {
                  key: 'invalid',
                  value: getTranslationOrderStatus('invalid')?.label,
                },
                {
                  key: 'abandoned',
                  value: getTranslationOrderStatus('abandoned')?.label,
                },
              ],
            },
          },
        },
      },
      {
        Header: props.t('order-generated-cashback'),
        accessor: 'generated_cashback',
        filterable: false,
        Cell: (props) => formatCurrency(props.row.original.generated_cashback),
        reportProps: {
          accessor: 'generated_cashback',
          formatter: {
            type: 'currency',
            properties: {
              currency: 'BRL',
              centsToCurrency: true,
            },
          },
        },
      },
      {
        Header: props.t('order-used-cashback2'),
        accessor: 'used_cashback',
        filterable: false,
        Cell: (props) => formatCurrency(props.row.original.used_cashback),
        reportProps: {
          formatter: {
            type: 'currency',
            properties: {
              currency: 'BRL',
              centsToCurrency: true,
            },
          },
        },
      },
      {
        Header: props.t('order-cashback-available'),
        accessor: 'cashback_available',
        filterable: false,
        Cell: (props) =>
          props.row.original?.cashback_available
            ? formatCurrency(props.row.original.cashback_available)
            : '-',
        reportProps: {
          formatter: {
            type: 'currency',
            properties: {
              currency: 'BRL',
              centsToCurrency: true,
            },
          },
        },
      },
      {
        Header: props.t('order-cashback-expires-at'),
        accessor: 'cashback_expires_at',
        filterable: false,
        Cell: (props) =>
          props.row.original?.cashback_expires_at
            ? formatDate(props.row.original.cashback_expires_at, 'DD-MM-Y')
            : '-',
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },
      {
        Header: props.t('order-availability-date'),
        accessor: 'availability_date',
        filterable: false,
        Cell: (props) =>
          props.row.original?.availability_date
            ? formatDate(props.row.original.availability_date, 'DD-MM-Y')
            : '-',
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },
      {
        id: props.t('name'),
        Header: props.t('name'),
        accessor: 'customer_name',
        reportProps: {},
      },
      {
        id: props.t('phone'),
        Header: props.t('phone'),
        accessor: 'customer_cellphone',
        reportProps: {},
      },
      {
        id: props.t('verified-phone'),
        Header: props.t('verified-phone'),
        accessor: 'customer_cellphone_verified',
        reportProps: {},
      },
      {
        id: props.t('email'),
        Header: props.t('email'),
        accessor: 'customer_email',
      },
      {
        id: props.t('birthdate'),
        Header: props.t('birthdate'),
        accessor: 'customer_birthdate',
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },
      {
        Header: props.t('actions'),
        Cell: (props) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <Can I="read" a="sales_management">
                <li className="list-inline-item">
                  <Link to={`${props?.row?.original?.id}`}>
                    <i className="ri-eye-fill align-bottom text-muted" />
                  </Link>
                </li>
              </Can>

              <Can I="update" a="sales_management">
                <li className="list-inline-item" title="Edit">
                  <StatusDropdown order={props.row.original} />
                </li>
              </Can>
            </ul>
          );
        },
        reportProps: {
          exclude: true,
        },
      },
    ],
    []
  );

  const hiddenColumns = [
    props.t('name'),
    props.t('phone'),
    props.t('verified-phone'),
    props.t('email'),
    props.t('birthdate'),
  ];

  document.title = 'Gestão de vendas | Opencashback';

  return (
    <React.Fragment>
      <TableOrderManagement
        props={props}
        columns={columns}
        orderStatus={orderStatus}
        searchParams={searchParams}
        isLoading={isLoading}
        orders={orders}
        filtersParams={filtersParams}
        meta={meta}
        page={searchParams.get('page')}
        setPage={(p) => setSearchParams('page', p)}
        hiddenColumns={hiddenColumns}
      />

      <Filter
        filters={filters}
        values={orderFilters}
        onClearFilter={cleanupStoreHeaders}
        localePrefix="orders-management"
        showUserStoresFilter
        useGroups
      />

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(OrdersManagement);
