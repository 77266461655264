import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import 'react-csv-importer/dist/index.css';
import { formatDate } from '../../../helpers/format';
import { deleteProduct } from '../../../slices/catalogs/thunk';

const CatalogRemoveItem = ({
  isOpen,
  toggle,
  onSuccess,
  product,
  t,
  tReady,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...product },
    onSubmit: async (values) => {
      setIsLoading(true);

      const action = await dispatch(
        deleteProduct({
          id: values?.id,
          successMessage: t('catalog-item-remove-success'),
          erroressage: t('catalog-item-remove-error'),
        })
      );

      setIsLoading(false);

      if (!action.payload.error) {
        resetState();
        onSuccess();
      }
    },
  });

  const resetState = () => {
    setTimeout(() => {
      formik.resetForm();
    }, 1000);
    toggle();
  };

  return (
    <>
      <Modal
        centered
        isOpen={isOpen}
        toggle={resetState}
        id="showModal"
        size="lg"
      >
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {tReady && t('catalog-item-remove-heading')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <h6>{tReady && t('catalog-item-remove-heading2')}</h6>
            <p className="d-w-100">
              {tReady && t('catalog-item-remove-description')}
            </p>

            <Table bordered striped>
              <tbody>
                <tr>
                  <th style={{ width: '30%' }} scope="row">
                    {t('catalog-table-sku_name')}
                  </th>
                  <td>{product?.name ?? t('unavailable')}</td>
                </tr>
                <tr>
                  <th style={{ width: '30%' }} scope="row">
                    {t('catalog-table-category_name')}
                  </th>
                  <td>{product?.category?.name ?? t('unavailable')}</td>
                </tr>
                <tr>
                  <th style={{ width: '30%' }} scope="row">
                    {t('catalog-table-created_at')}
                  </th>
                  <td>{formatDate(product?.createdAt) ?? t('unavailable')}</td>
                </tr>
              </tbody>
            </Table>

            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={resetState}
                disabled={isLoading}
              >
                {t('cancel')}
              </button>

              <button
                type="submit"
                className="btn btn-danger"
                disabled={isLoading}
              >
                {t('confirm')}
              </button>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
};

export default withTranslation()(CatalogRemoveItem);
