import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateBlockedProduct } from '../../../slices/strategies/thunk';

const BlockedStatusEdit = ({ isOpen, toggle, skuList, onSuccess, t }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: undefined,
    },
    validationSchema: Yup.object({
      status: Yup.object({
        status: Yup.string(),
        label: Yup.string(),
      }).required(t('validation-required-value')),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      let hasError = false;

      for (const sku of skuList) {
        let rescue = false;
        let accumulation = false;

        switch(values.status.value) {
          case 'blocked':
            rescue = true;
            accumulation = true;
            break;
          case 'block_redeem':
            rescue = true;
            break;
          case 'block_accumulation':
            accumulation = true;
            break;
        }

        const action = await dispatch(
          updateBlockedProduct({
            externalId: sku,
            rescue,
            accumulation,
            successMessage: t('catalog-edit-blocked-success'),
            errorMessage: t('catalog-edit-blocked-error'),
          })
        );

        if (action.payload.error) {
          hasError = true;
          break;
        }
      }

      setIsLoading(false);

      if (!hasError) {
        validation.resetForm();
        onSuccess();
        toggle();
      }
    },
  });

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {t('bulk-edit-one-blocked-product')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <h4 className="mb-4">
              {t('bulk-edit-one-blocked-product-description')}
            </h4>

            <Row className="g-3">
              <Col lg={12}>
                <div className="gap-2 w-100 justify-content-start">
                  <Label className="form-label" htmlFor="wallet-status">
                    {t('status')}
                  </Label>
                  <Select
                    name="status"
                    id="wallet-status"
                    onChange={(e) => validation.setFieldValue('status', e)}
                    value={validation.values.status}
                    placeholder={t('select-status')}
                    options={[
                      {
                        label: t('customer-wallet-status-blocked_redeem'),
                        value: 'block_redeem',
                      },
                      {
                        label: t('customer-wallet-status-blocked_accumulation'),
                        value: 'block_accumulation',
                      },
                      {
                        label: t('customer-wallet-status-blocked'),
                        value: 'blocked',
                      },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={toggle}>
                {t('close')}
              </button>
              <button
                type="submit"
                disabled={!validation.values.status || !skuList?.length}
                className="btn btn-success"
                id="add-btn"
              >
                <div className="d-flex">
                  {isLoading && <Spinner size="sm" className="me-3" />}
                  {t('confirm')}
                </div>
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(BlockedStatusEdit);