import { cpf, cnpj } from 'cpf-cnpj-validator';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'numberString', function (errorMessage) {
  return this.test('numberString', errorMessage, function (value) {
    const { path, createError } = this;

    if (isEmpty(value)) {
      return true;
    }

    if (
      !isNaN(Number(String(value).replaceAll('.', '').replaceAll(',', '.')))
    ) {
      return true;
    }

    return createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, 'document', function (errorMessage) {
  return this.test('document', errorMessage, function (value) {
    const { path, createError } = this;

    const isValid = cpf.isValid(value) || cnpj.isValid(value);

    if (isValid) {
      return true;
    }

    return createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.array, 'dateRange', function (errorMessage) {
  return this.test('dateRange', errorMessage, function (value) {
    const { path, createError } = this;

    if (!isEmpty(value) && value?.length === 2) {
      return true;
    }

    return createError({ path, message: errorMessage });
  });
});

export default Yup;
