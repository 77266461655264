import React from 'react';
import { withTranslation } from 'react-i18next';
import { formatDocument, formatCellphone } from '../../helpers/format';
import * as moment from 'moment';
import { formatDate } from '../../helpers/format';
import { Card, CardBody, Table, UncontrolledTooltip } from 'reactstrap';

const CustomerDetails = ({ isLoading, customer, wallet, t }) => {
  const getStatusBadgeClasses = (status) => {
    switch (status) {
    case 'active':
      return 'bg-success-subtle text-success';
    case 'inactive':
      return 'bg-warning-subtle text-warning';
    default:
      return 'bg-danger-subtle text-danger';
    }
  };

  const getAvatar = (nameState) => {
    if (!nameState) {
      return '-';
    }

    const name = nameState.split(' ');

    if (name.length > 1) {
      return `${name[0][0]}${name[1][0]}`;
    }

    return `${nameState[0][1]}`;
  };

  const renderVerifiedBadge = () => (
    <>
      <i
        className="bx bxs-badge-check fs-5 text-success ms-1"
        id="verified_phone"
      />
      <UncontrolledTooltip target="verified_phone" placement="top">
        {t('verified-phone')}
      </UncontrolledTooltip>
    </>
  );

  return (
    <React.Fragment>
      <Card id="contact-view-detail">
        <CardBody className="text-center">
          {wallet?.origin === 'lead_capture' ? (
            <div className="position-absolute top-0 end-0 pe-3 pt-3">
              <span className={`badge bg-success-subtle text-warning fs-6`}>
                {t('lead')}
              </span>
            </div>
          ) : null}

          <div className="position-relative d-inline-block">
            <div className="avatar-lg">
              <div className="avatar-title rounded-circle bg-warning-subtle text-warning">
                {getAvatar(customer?.name)}
              </div>
            </div>
            <span className="contact-active position-absolute rounded-circle bg-success">
              <span className="visually-hidden"></span>
            </span>
          </div>
          <h5 className="mt-4 mb-1">
            {!isLoading && customer && customer?.name}
            {isLoading && <div className="placeholder bg-light w-50"></div>}
          </h5>
        </CardBody>
        <CardBody>
          <div className="table-responsive table-card">
            <Table className="table table-borderless mb-0">
              <tbody>
                <tr>
                  <td className="fw-medium">{t('document')}</td>
                  <td>
                    {!isLoading &&
											customer &&
											formatDocument(customer?.document)}
                    {isLoading && (
                      <div className="placeholder bg-light w-100"></div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">{t('email')}</td>
                  <td>
                    {!isLoading && wallet?.email && wallet.email}
                    {isLoading && (
                      <div className="placeholder bg-light w-100"></div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">{t('cellphone')}</td>
                  <td>
                    {!isLoading && wallet?.cellphone && (
                      <div className="d-flex align-items-center">
                        {formatCellphone(wallet.cellphone)}
                        {wallet?.cellphone_verified
                          ? renderVerifiedBadge()
                          : null}
                      </div>
                    )}
                    {isLoading && (
                      <div className="placeholder bg-light w-100"></div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">{t('birthdate')}</td>
                  <td>
                    {!isLoading &&
											customer?.birthdate &&
											moment(customer.birthdate).format('DD/MM/YYYY')}
                    {isLoading && (
                      <div className="placeholder bg-light w-100"></div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">{t('status')}</td>
                  <td>
                    <span
                      className={`badge me-1 ${getStatusBadgeClasses(
                        wallet?.status
                      )} `}
                    >
                      {!isLoading &&
												wallet?.status &&
												t(`customer-wallet-status-${wallet.status}`)}
                    </span>
                    {isLoading && (
                      <div className="placeholder bg-light w-100"></div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">{t('origin')}</td>
                  <td>
                    <span>
                      {!isLoading &&
												wallet?.origin &&
												t(`wallet-origins.${wallet.origin}`)}
                    </span>
                    {isLoading && (
                      <div className="placeholder bg-light w-100"></div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">{t('created-at')}</td>
                  <td>
                    {!isLoading &&
											wallet?.created_at &&
											formatDate(wallet.created_at)}
                    {isLoading && (
                      <div className="placeholder bg-light w-100"></div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-medium">{t('updated-at')}</td>
                  <td>
                    {!isLoading &&
											wallet?.updated_at &&
											formatDate(wallet.updated_at)}
                    {isLoading && (
                      <div className="placeholder bg-light w-100"></div>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(CustomerDetails);
