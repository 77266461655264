import React, { useContext, useEffect, useState } from 'react';
import { Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import { AbilityContext } from '../../../../helpers/casl';

const ExpirationTimeForm = ({ form, ...props }) => {
  const [isDynamic, setDynamic] = useState();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    setDynamic(form.values.dynamic_expiration_enabled);
  }, [form.values.dynamic_expiration_enabled]);

  const isInvalid = (form, field) => {
    return form.touched[field] && form.errors[field];
  };

  const dynamicExpirationOptions = [
    { label: 'Fixo', value: false },
    { label: 'Dinâmico', value: true },
  ];

  const renderFixedExpirationFields = () => (
    <>
      <Label className="form-label" htmlFor="days_to_expire">
        {props.t('expiration-time')}
      </Label>
      <div className="input-group">
        <Input
          name="days_to_expire"
          type="number"
          min={1}
          inputMode="numeric"
          className="form-control"
          id="days_to_expire"
          placeholder="7"
          invalid={isInvalid(form, 'days_to_expire') ? true : false}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.days_to_expire}
          disabled={ability.cannot('update', 'account_strategies')}
        />

        <span className="input-group-text">{props.t('days')}</span>

        {isInvalid(form, 'days_to_expire') && (
          <FormFeedback type="invalid">
            {form.errors.days_to_expire}
          </FormFeedback>
        )}
      </div>
    </>
  );

  const renderDynamicExpirationFields = () => (
    <div className="d-flex flex-column gap-3">
      <div>
        <Label className="form-label" htmlFor="days_to_expire">
          {props.t('expiration-time-for-new-customers')}
        </Label>
        <div className="input-group">
          <Input
            id="days_to_expire"
            name="days_to_expire"
            type="number"
            min={1}
            inputMode="numeric"
            placeholder="10"
            className="form-control"
            invalid={isInvalid(form, 'days_to_expire') ? true : false}
            disabled={ability.cannot('update', 'account_strategies')}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.days_to_expire}
          />

          <span className="input-group-text">{props.t('days')}</span>

          {isInvalid(form, 'days_to_expire') && (
            <FormFeedback type="invalid">
              {form.errors.days_to_expire}
            </FormFeedback>
          )}
        </div>
      </div>

      <div>
        <Label
          className="form-label"
          htmlFor="dynamic_expiration_reduction_percentage"
        >
          {props.t('percentage-reduction')}
        </Label>
        <div className="input-group">
          <Input
            id="dynamic_expiration_reduction_percentage"
            name="dynamic_expiration_reduction_percentage"
            type="number"
            inputMode="numeric"
            min={0}
            max={100}
            placeholder="20"
            className="form-control"
            invalid={isInvalid(form, 'dynamic_expiration_reduction_percentage')}
            disabled={ability.cannot('update', 'account_strategies')}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.dynamic_expiration_reduction_percentage}
          />

          <span className="input-group-text">%</span>

          {isInvalid(form, 'dynamic_expiration_reduction_percentage') && (
            <FormFeedback type="invalid">
              {form.errors.dynamic_expiration_reduction_percentage}
            </FormFeedback>
          )}
        </div>
      </div>

      <div>
        <Label className="form-label" htmlFor="minimum_dynamic_expiration">
          {props.t('minimum-expiration-time')}
        </Label>
        <div className="input-group">
          <Input
            id="minimum_dynamic_expiration"
            name="minimum_dynamic_expiration"
            type="number"
            inputMode="numeric"
            placeholder="20"
            className="form-control"
            invalid={
              isInvalid(form, 'minimum_dynamic_expiration') ? true : false
            }
            disabled={ability.cannot('update', 'account_strategies')}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.minimum_dynamic_expiration}
          />

          <span className="input-group-text">{props.t('days')}</span>

          {isInvalid(form, 'minimum_dynamic_expiration') && (
            <FormFeedback type="invalid">
              {form.errors.minimum_dynamic_expiration}
            </FormFeedback>
          )}
        </div>
      </div>

      <div>
        <Label className="form-label" htmlFor="maximum_dynamic_expiration">
          {props.t('maximum-expiration-time')}
        </Label>
        <div className="input-group">
          <Input
            id="maximum_dynamic_expiration"
            name="maximum_dynamic_expiration"
            type="number"
            inputMode="numeric"
            placeholder="60"
            className="form-control"
            invalid={
              isInvalid(form, 'maximum_dynamic_expiration') ? true : false
            }
            disabled={ability.cannot('update', 'account_strategies')}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.maximum_dynamic_expiration}
          />

          <span className="input-group-text">{props.t('days')}</span>

          {isInvalid(form, 'maximum_dynamic_expiration') && (
            <FormFeedback type="invalid">
              {form.errors.maximum_dynamic_expiration}
            </FormFeedback>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="d-flex flex-column gap-3 mb-2 pb-4"
      style={{ borderBottom: '2px solid #eee' }}
    >
      <div>
        <h6 style={{ fontWeight: 'bold' }}>Prazo de Expiração</h6>

        <Row className="flex-column gap-4 flex-lg-row mt-3">
          <Col>
            <Label className="form-label" htmlFor="dynamic_expiration_enabled">
              {props.t('expiration-type')}
            </Label>
            <Select
              isDisabled={
                ability.cannot('update', 'account_strategies') || form.isLoading
              }
              placeholder={form.IsLoading ? 'Aguarde...' : 'Selecione'}
              name="dynamic_expiration_enabled"
              id="dynamic_expiration_enabled"
              value={dynamicExpirationOptions.find(
                (item) => item.value === isDynamic
              )}
              onChange={(e) => {
                form.setFieldValue('dynamic_expiration_enabled', e.value);
                setDynamic(e.value);
              }}
              options={dynamicExpirationOptions}
            />
          </Col>

          <Col>
            {form.values.dynamic_expiration_enabled !== undefined
              ? isDynamic
                ? renderDynamicExpirationFields()
                : renderFixedExpirationFields()
              : null}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(ExpirationTimeForm);
