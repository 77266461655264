import React from 'react';
import InformativeCard from '../../Components/Common/InformativeCard';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const Revenue = ({ t, billing, cashback }) => {
  const format_number = (value) => {
    return value / 100 || 0;
  };

  const format_percentage = (value) => {
    return (value && `${(value * 100).toFixed(2)}%`) || '0%';
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <h5 className="text-decoration-underline mb-3 pb-1">
            {t('company-revenue')}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Row>
            <Col>
              <InformativeCard
                label={t('informative-total-revenue')}
                prefix="R$"
                suffix=""
                separator="."
                decimal=","
                counter={format_number(billing.total)}
                decimals={2}
                duration={2}
                bgcolor="primary"
                icon="bx bx-shopping-bag"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          <Row>
            <Col>
              <InformativeCard
                label={t('informative-total-revenue-impacted')}
                prefix="R$"
                suffix=""
                decimal=","
                separator="."
                counter={format_number(billing.generation)}
                decimals={2}
                duration={2}
                bgcolor="primary"
                icon="bx bx-shopping-bag"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          <Row>
            <Col>
              <InformativeCard
                label={t('informative-total-revenue-incremented')}
                prefix="R$"
                suffix=""
                separator="."
                decimal=","
                counter={format_number(billing.rescue)}
                decimals={2}
                duration={2}
                bgcolor="primary"
                icon="bx bx-shopping-bag"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Row>
            <Col>
              <InformativeCard
                label={t('informative-total-revenue-cashback-accumulated')}
                prefix="R$"
                suffix=""
                separator="."
                decimal=","
                counter={format_number(cashback.generated)}
                decimals={2}
                duration={2}
                bgcolor="primary"
                icon="bx bx-shopping-bag"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          <Row>
            <Col>
              <InformativeCard
                label={t('informative-total-revenue-cashback-redeemed')}
                prefix="R$"
                suffix=""
                separator="."
                decimal=","
                counter={format_number(cashback.rescued)}
                decimals={2}
                duration={2}
                bgcolor="primary"
                icon="bx bx-shopping-bag"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          <Row>
            <Col>
              <InformativeCard
                label={t('informative-total-revenue-cashback-expired')}
                prefix="R$"
                suffix=""
                decimal=","
                separator="."
                counter={format_number(cashback.expired)}
                decimals={2}
                duration={2}
                bgcolor="primary"
                icon="bx bx-shopping-bag"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          <Row>
            <Col>
              <InformativeCard
                label={t('informative-total-revenue-cashback-available')}
                prefix="R$"
                suffix=""
                separator="."
                decimal=","
                counter={format_number(cashback.available)}
                decimals={2}
                duration={2}
                bgcolor="primary"
                icon="bx bx-shopping-bag"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(Revenue);
