import { useReducer, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import 'react-csv-importer/dist/index.css';

import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { batchImportProducts } from '../../../slices/catalogs/thunk';

import Wizard from '../../../Components/Common/Wizard';
import CsvImporter from '../../../Components/Common/CsvImporter';
import { removeBomFromBinaryFile } from '../../../helpers/encode_file';

const initialValues = {
  file: undefined,
  fields: undefined,
  rows: 0,
};

const CatalogImporter = ({ isOpen, toggle, onSuccess, t, tReady }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();

  const [payload, setPayload] = useReducer(
    (acc, item) => ({ ...acc, ...item }),
    initialValues
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...payload },
    onSubmit: async () => {
      setIsLoading(true);

      const cleanFile = await removeBomFromBinaryFile(payload.file);

      const { columnFields, preview } = payload;
      const configurationParse = columnFields.reduce(
        (currentvalue, value, index) => {
          const column = preview.columns.find((val) => val.index === index);

          return {
            ...currentvalue,
            [value || '']: { column: column?.header },
          };
        },
        {}
      );

      const data = {
        file: cleanFile,
        configuration_parse: JSON.stringify(configurationParse),
      };

      const action = await dispatch(
        batchImportProducts({
          ...data,
          successMessage: t('import-success'),
          erroressage: t('import-error'),
        })
      );

      setIsLoading(false);

      if (!action.payload.error) {
        resetState();
        onSuccess();
      }
    },
  });

  const resetState = () => {
    setTimeout(() => {
      setActiveTab(1);
      setPayload(initialValues);
      formik.resetForm();
    }, 1000);
    toggle();
  };

  return (
    <>
      <Modal
        centered
        isOpen={isOpen}
        size={activeTab >= 2 ? 'lg' : 'xl'}
        style={{ transition: 'all 0.2s ease' }}
        toggle={resetState}
        id="showModal"
      >
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {tReady && t('catalog-import-modal-heading')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Wizard
              showSteps
              activeTab={activeTab}
              tabs={[
                {
                  label: tReady && t('catalog-import-step1-heading'),
                  component: (
                    <>
                      <h6>{tReady && t('catalog-import-step1-heading')}</h6>
                      <p className="d-w-100">
                        {tReady && t('catalog-import-step1-description')}
                      </p>

                      <CsvImporter
                        onClose={() => setActiveTab(2)}
                        onComplete={(data) => setPayload({ ...data })}
                        dataHandler={(raw) => {
                          const rows = raw?.reduce(
                            (acc, i) => acc + i,
                            payload.rows
                          );
                          setPayload({ rows });
                        }}
                        fields={[
                          { name: 'sku_id', label: 'Cód. do Produto' },
                          { name: 'sku_name', label: 'Nome do Produto' },
                          {
                            name: 'category_id',
                            label: 'ID da Categoria',
                            optional: true,
                          },
                          {
                            name: 'category_name',
                            label: 'Categoria',
                            optional: true,
                          },
                          // { name: 'collection_id', label: 'Coleção', optional: true },
                          // { name: 'collection_name', label: 'ID da Coleção', optional: true },
                        ]}
                      />
                    </>
                  ),
                },
                {
                  label: tReady && t('catalog-import-step2-heading'),
                  component: (
                    <>
                      <h6>{tReady && t('catalog-import-step2-heading')}</h6>
                      <p className="d-w-100">
                        {tReady && t('catalog-import-step2-description')}
                      </p>

                      <Table bordered striped>
                        <tbody>
                          <tr>
                            <th style={{ width: '30%' }} scope="row">
                              {t('catalog-import-review-amount')}
                            </th>
                            <td>
                              {payload?.rows} {t('items')}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={resetState}
                          disabled={isLoading}
                        >
                          {t('close')}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={isLoading}
                        >
                          {t('confirm')}
                        </button>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
};

export default withTranslation()(CatalogImporter);
