import React, { useEffect, useState, useMemo } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../../Components/Common/TableContainer';
import ExportReport from '../../../Components/Common/ExportReport';
import Filter from '../../../Components/Common/Filter';

import 'react-toastify/dist/ReactToastify.css';
import { createSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { getStrategies } from '../../../slices/strategies/thunk';
import { formatCurrency } from '../../../helpers/format';
import _, { isEmpty } from 'lodash';
import { Can } from '../../../helpers/casl';
import EmptyList from '../../../Components/Common/EmptyList';

const CatalogStrategy = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const strategiesSelector = createSelector(
    (state) => state.Strategy.strategies,
    (strategies) => strategies
  );

  const metaSelector = createSelector(
    (state) => state.Strategy.meta,
    (meta) => meta
  );

  const isLoadingSelector = createSelector(
    (state) => state.Strategy.isLoading,
    (isLoading) => isLoading
  );

  const strategies = useSelector(strategiesSelector);
  const meta = useSelector(metaSelector);
  const isLoading = useSelector(isLoadingSelector);

  const [showFilters, setShowFilters] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [appliesAtFilter, setAppliesAtFilter] = useState([]);

  const [page, setPage] = useState(1);

  const getParams = () => {
    const params = {
      page,
      category: 'accumulation',
      priority: { gte: 2 },
    };

    if (!isEmpty(statusFilter)) {
      params.status = {
        in: statusFilter,
      };
    }

    if (!isEmpty(statusFilter)) {
      params.applies_at = {
        in: appliesAtFilter,
      };
    }

    return params;
  };

  const submitFilter = (e) => {
    e.preventDefault();
    dispatch(getStrategies(getParams()));
    setShowFilters(!showFilters);
  };

  const clearFilter = (e) => {
    e.preventDefault();
    setStatusFilter([]);
    setAppliesAtFilter([]);
  };

  const getStatusBadgeClasses = (status) => {
    switch (status) {
    case 'active':
      return 'bg-success-subtle text-success';
    case 'inactive':
      return 'bg-warning-subtle text-warning';
    default:
      return 'bg-danger-subtle text-danger';
    }
  };

  useEffect(() => {
    dispatch(getStrategies(getParams()));
  }, [dispatch, page]);

  const filters = [
    {
      type: 'checkbox',
      name: props.t('status'),
      value: statusFilter,
      onChange: setStatusFilter,
      values: [
        { label: props.t('strategy-status-active'), value: 'active' },
        { label: props.t('strategy-status-inactive'), value: 'inactive' },
        { label: props.t('strategy-status-expired'), value: 'expired' },
      ],
    },
    {
      type: 'checkbox',
      name: props.t('applies-at'),
      value: appliesAtFilter,
      onChange: setAppliesAtFilter,
      values: [
        { label: props.t('category'), value: 'category' },
        { label: props.t('collection'), value: 'collection' },
        { label: props.t('product'), value: 'product' },
      ],
    },
  ];

  const _formatCashback = (data) => {
    if (!data) return 0;

    const type = data?.row?.original?.type;
    const cashback = data?.row?.original?.cashback;

    const opts = {
      fixed: { style: 'currency' },
      percentage: {
        style: 'percent',
        convert: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    }[type];

    return formatCurrency(cashback, opts);
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t('strategies.catalog.table.strategy-name'),
        Cell: (data) => `Estratégia ${_formatCashback(data)}`,
        reportProps: {
          accessor: 'strategy_name',
          formatter: {
            type: 'mask',
            properties: { prefix: 'Estratégia ' },
          },
        },
      },
      {
        Header: props.t('strategies.catalog.table.cashback-applied'),
        Cell: _formatCashback,
        reportProps: {
          accessor: 'cashback',
          formatter: {
            type: 'condition',
            properties: {
              fieldComparer: 'type',
              valueComparer: 'percentage',
              formatterTrue: {
                type: 'decimal_percentage',
                properties: {
                  value: 10,
                },
              },
              formatterFalse: {
                type: 'currency',
                properties: {
                  currency: 'BRL',
                  centsToCurrency: true,
                },
              },
            },
          },
        },
      },
      {
        Header: props.t('strategies.catalog.table.application'),
        Cell: () => props.t('strategies.catalog.table.application-default'),
        reportProps: {
          accessor: 'application',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'product',
                  value: props.t(
                    'strategies.catalog.table.application-default'
                  ),
                },
              ],
            },
          },
        },
      },
      {
        Header: props.t('strategies.catalog.table.products-amount'),
        Cell: (data) => data?.row?.original?.total ?? '-',
        reportProps: {
          accessor: 'total',
        },
      },
      {
        Header: props.t('actions'),
        Cell: (data) => (
          <Can I="read" a="catalog_strategies">
            <Link
              title="Visualizar"
              to={`/estrategias/detalhes/${data?.row?.original?.id}`}
            >
              <i className="bx bx-cog align-bottom text-muted" />
            </Link>
          </Can>
        ),
        reportProps: {
          exclude: true,
        },
      },
    ],
    []
  );

  document.title = 'Estratégia por catalogo | Opencashback';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Estratégia por catalogo" pageTitle="Gestão" />

          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={1}>{isLoading && <Spinner color="primary" />}</Col>

                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        <Can I="create" a="catalog_strategies">
                          <Link to="/estrategias/novo/produto">
                            <button
                              type="button"
                              className="btn btn-secondary add-btn"
                              id="create-btn"
                              onClick={() => {}}
                            >
                              <i className="ri-add-line align-bottom me-1" />
                              {props.t('new-strategy')}
                            </button>
                          </Link>
                        </Can>
                        <ExportReport
                          pages={meta?.pages}
                          disabled={isLoading || !strategies?.length}
                          filename="Relatório OCK: Estratégia por Catalogo"
                          service="strategies"
                          columns={columns}
                          data={{}}
                          filters={{}}
                          order="asc"
                        />
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody className="pt-0">
                  {!isLoading && !strategies?.length ? (
                    <EmptyList
                      body={props.t('strategies.catalog.empty')}
                      button={{
                        icon: 'ri-add-line',
                        label: props.t('new-strategy'),
                        onClick: () => navigate('/estrategias/novo/produto'),
                      }}
                    />
                  ) : null}

                  {!isLoading && strategies?.length ? (
                    <TableContainer
                      columns={columns}
                      data={strategies || []}
                      activePage={page}
                      pageSize={meta?.take || 10}
                      totalPages={meta?.pages || 0}
                      isLoading={isLoading}
                      count={meta?.total || 0}
                      setPage={setPage}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Filter
        show={showFilters}
        onCloseClick={() => setShowFilters(false)}
        onClearFilter={clearFilter}
        onSubmitFilter={submitFilter}
        filters={filters}
        isLoading={isLoading}
        values={[]}
      />
    </React.Fragment>
  );
};

export default withTranslation()(CatalogStrategy);
