import _ from 'lodash';
import { UncontrolledCollapse } from 'reactstrap';
import classNames from 'classnames';

import useQsParams from '../../../Components/Hooks/QueryString';

export default function RenderGroup({ id, title, children, opened = false }) {
  const { qs, setSearchParams } = useQsParams();
  const isOpened = qs.get('opened')?.split(',')?.includes(id);

  const _manageArrayParamAsString = (_key, value) => {
    const raw = qs.get(_key);
    const ARRAY = raw ? raw.split(',') : [];
    const existing = _.uniq([...ARRAY].map(String));

    if (existing.indexOf(value) != -1) {
      existing.splice(existing.indexOf(value), 1);
    } else {
      existing.push(value);
    }

    setSearchParams(_key, existing.join(','));
  };

  return (
    <div key={`filter-group-${id}`} className="filter-drawer-group-wrapper">
      <span id={id} className="px-3">
        <div
          style={{ cursor: 'pointer' }}
          className="d-flex justify-content-between align-items-center px3"
          onClick={() => _manageArrayParamAsString('opened', id)}
        >
          <span className="fw-semibold fs-5">{title}</span>

          <i
            className={classNames({
              'transition bx bxs-down-arrow': true,
              rotate180deg: isOpened,
            })}
          />
        </div>
      </span>

      <UncontrolledCollapse
        className="px3"
        defaultOpen={opened || isOpened}
        toggler={`#${id}`}
      >
        {children}
      </UncontrolledCollapse>
    </div>
  );
}
