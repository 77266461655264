import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createSelector } from 'reselect';
import { useFormik } from 'formik';

import {
  getAccountStrategy,
  updateAccountStrategy,
} from '../../../slices/thunks';

import { Can, AbilityContext } from '../../../helpers/casl';
import Yup from '../../../helpers/yup_extras';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CashbackValueForm from './generation/CashbackValueForm';
import ExpirationTimeForm from './generation/ExpirationTimeForm';
import RedeemTimeForm from './generation/RedeemTimeForm';
import OtherSettingsForm from './generation/OtherSettingsForm';
import CashbackRedeemForm from './redeem/CashbackRedeemForm';

const AccountStrategy = (props) => {
  document.title = 'Estratégia por conta | Opencashback';

  const dispatch = useDispatch();
  const [isLoadingAccumulation, setIsLoadingAccumulation] = useState(false);
  const [isLoadingRescue, setIsLoadingRescue] = useState(false);

  const ability = useContext(AbilityContext);

  const accumulationState = createSelector(
    (state) => state.Strategy.account.accumulation,
    (accumulation) => accumulation
  );

  const rescueState = createSelector(
    (state) => state.Strategy.account.rescue,
    (rescue) => rescue
  );

  const isLoadingState = createSelector(
    (state) => state.Strategy.isLoading,
    (rescue) => rescue
  );

  const accumulation = useSelector(accumulationState);
  const rescue = useSelector(rescueState);
  const isLoading = useSelector(isLoadingState);

  useEffect(() => {
    dispatch(getAccountStrategy());
  }, [dispatch]);

  const toNumber = (value) => {
    return Number(String(value).replaceAll('.', '').replace(',', ''));
  };

  const accumulationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      generation: (accumulation && accumulation?.generation) || '',
      cashback: (accumulation && accumulation?.cashback) || '',
      days_to_expire: (accumulation && accumulation?.days_to_expire) || 1,
      calculation_basis:
				(accumulation && accumulation?.calculation_basis) || '',
      store_order_availability_time:
				(accumulation && accumulation?.store_order_availability_time) || '',
      ecommerce_order_availability_time:
				(accumulation && accumulation?.ecommerce_order_availability_time) || '',
      block_discounts: (accumulation && accumulation?.block_discounts) || false,
      block_vtex_any_coupon:
				(accumulation && accumulation?.block_vtex_any_coupon) || false,
      minimum_accumulation: accumulation?.minimum_accumulation ?? 0,

      dynamic_expiration_enabled: accumulation.dynamic_expiration_enabled,
      dynamic_expiration_reduction_percentage:
				accumulation.dynamic_expiration_reduction_percentage * 100,
      maximum_dynamic_expiration:
				accumulation.maximum_dynamic_expiration || 200,
      minimum_dynamic_expiration: accumulation.minimum_dynamic_expiration || 10,
    },
    validationSchema: Yup.object({
      generation: Yup.string().required(
        props.t('validation-required-generation-type')
      ),
      cashback: Yup.string()
        .required(props.t('validation-required-value'))
        .numberString(props.t('validation-number-value')),
      days_to_expire: Yup.string()
        .required(props.t('validation-required-expiration-time'))
        .numberString(props.t('validation-number-expiration-time')),
      store_order_availability_time: Yup.string()
        .required(props.t('validation-required-store-order-availability-time'))
        .numberString(
          props.t('validation-number-store-order-availability-time')
        ),
      ecommerce_order_availability_time: Yup.string()
        .required(
          props.t('validation-required-ecommerce-order-availability-time')
        )
        .numberString(
          props.t('validation-number-ecommerce-order-availability-time')
        ),
      calculation_basis: Yup.string().required(
        props.t('validation-required-calculation-basis')
      ),
      block_discounts: Yup.boolean(),
      block_vtex_any_coupon: Yup.boolean(),
      dynamic_expiration_enabled: Yup.boolean(),
      dynamic_expiration_reduction_percentage: Yup.number()
        .min(
          0,
          props.t(
            'validation-required-dynamic-expiration-reduction-percentage-greater'
          )
        )
        .max(
          100,
          props.t(
            'validation-required-dynamic-expiration-reduction-percentage-lower'
          )
        )
        .when('dynamic_expiration_enabled', {
          is: true,
          then: (f) =>
            f.required(
              props.t(
                'validation-required-dynamic-expiration-reduction-percentage'
              )
            ),
        }),
      maximum_dynamic_expiration: Yup.number().when(
        'dynamic_expiration_enabled',
        {
          is: true,
          then: (f) => f.required(props.t('validation-required-value')),
        }
      ),
      minimum_dynamic_expiration: Yup.number().when(
        'dynamic_expiration_enabled',
        {
          is: true,
          then: (f) => f.required(props.t('validation-required-value')),
        }
      ),
    }),
    onSubmit: async (values) => {
      setIsLoadingAccumulation(true);

      const data = {
        strategy_id: accumulation.strategy_id,
        type: 'accumulation',
        cashback_type: values.generation,
        generation: values.generation,
        cashback: toNumber(values.cashback),
        days_to_expire: toNumber(values.days_to_expire),
        calculation_basis: values.calculation_basis,
        store_order_availability_time: values.store_order_availability_time,
        ecommerce_order_availability_time:
					values.ecommerce_order_availability_time,
        block_discounts: values.block_discounts,
        block_vtex_any_coupon: values.block_vtex_any_coupon,
        minimum_accumulation: toNumber(values.minimum_accumulation),
        dynamic_expiration_enabled: values.dynamic_expiration_enabled,
        dynamic_expiration_reduction_percentage:
					values.dynamic_expiration_reduction_percentage / 100,
        maximum_dynamic_expiration: toNumber(values.maximum_dynamic_expiration),
        minimum_dynamic_expiration: toNumber(values.minimum_dynamic_expiration),
      };

      try {
        await dispatch(
          updateAccountStrategy({
            data,
            successMessage: props.t('strategy-account-accumulation-success'),
            errorMessage: props.t('strategy-account-accumulation-error'),
          })
        );
      } finally {
        setIsLoadingAccumulation(false);
      }
    },
  });

  const rescueForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      minimum_order: (rescue && rescue?.minimum_order) || '',
      maximum_rescue: (rescue && rescue?.maximum_rescue) || '',
      calculation_basis:
				(accumulation && accumulation?.calculation_basis) || '',
      block_discounts: (rescue && rescue?.block_discounts) || false,
      block_vtex_any_coupon: (rescue && rescue?.block_vtex_any_coupon) || false,

      dynamic_expiration_enabled: accumulation.dynamic_expiration_enabled,
      dynamic_expiration_reduction_percentage:
				accumulation.dynamic_expiration_reduction_percentage || 0.2,
      maximum_dynamic_expiration:
				accumulation.maximum_dynamic_expiration || 200,
      minimum_dynamic_expiration: accumulation.minimum_dynamic_expiration || 10,
    },
    validationSchema: Yup.object({
      minimum_order: Yup.string()
        .required(props.t('validation-required-minimum-order'))
        .numberString(props.t('validation-number-minimum-order')),
      maximum_rescue: Yup.string().numberString(
        props.t('validation-number-maximum-rescue')
      ),
      block_discounts: Yup.boolean(),
      block_vtex_any_coupon: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      setIsLoadingRescue(true);
      const data = {
        strategy_id: rescue.strategy_id,
        type: 'rescue',
        calculation_basis: values.calculation_basis,
        minimum_order: toNumber(values.minimum_order),
        maximum_rescue: toNumber(values.maximum_rescue),
        block_discounts: values.block_discounts,
        block_vtex_any_coupon: values.block_vtex_any_coupon,
      };

      await dispatch(
        updateAccountStrategy({
          data,
          successMessage: props.t('strategy-account-rescue-success'),
          errorMessage: props.t('strategy-account-rescue-error'),
        })
      );

      setIsLoadingRescue(false);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Estratégia da conta" pageTitle="Gestão" />

          <Row>
            <Can I="read" a="account_strategies">
              <Col lg={6}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">
                      {props.t('setup-cashback-generation')}{' '}
                      {isLoading && <Spinner size="sm" />}
                    </h4>
                  </CardHeader>

                  <CardBody>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        accumulationForm.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="d-flex flex-column gap-3">
                        <CashbackValueForm form={accumulationForm} />
                        <ExpirationTimeForm form={accumulationForm} />
                        <RedeemTimeForm form={accumulationForm} />
                        <OtherSettingsForm form={accumulationForm} />
                      </div>

                      <div className="d-flex mt-4">
                        <button
                          type="submit"
                          disabled={
                            ability.cannot('update', 'account_strategies') ||
														!accumulationForm.isValid ||
														accumulationForm.isSubmitting
                          }
                          className="btn btn-success btn-label right ms-auto"
                          onClick={() => {}}
                        >
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          {props.t('save')}
                          {isLoadingAccumulation && (
                            <Spinner className="ms-2" size="sm" />
                          )}
                        </button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={6}>
                <CashbackRedeemForm
                  isLoading={isLoading || isLoadingRescue}
                  form={rescueForm}
                />
              </Col>
            </Can>
          </Row>
        </Container>

        <ToastContainer position="top-center" closeButton={false} limit={1} />
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(AccountStrategy);
