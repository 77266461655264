import { useEffect } from 'react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import ColorPicker from '../../../../../Components/Common/ColorPicker';
import ActionButton from '../../../../../Components/Common/ActionButton';
import FileUpload from '../../../../../Components/Common/FileUpload';
import useQsParams from '../../../../../Components/Hooks/QueryString';

const Step1 = ({ state, dispatch, formik, initialValues }) => {
  const { setQs } = useQsParams();

  useEffect(() => {
    dispatch({ step: 'form' });
  }, []);

  const VISUAL_PICKERS = [
    {
      label: 'Cor de fundo',
      description: 'Aplicada à captura de lead',
      state: 'backgroundColor',
    },

    {
      label: 'Cor dos textos',
      description: 'Utilizada em todos os textos',
      state: 'textColor',
    },

    {
      label: 'Cor do botão de ação',
      description: 'Utilizada em todos os botões',
      state: 'ctaBackgroundColor',
    },

    {
      label: 'Cor de texto do botão de ação',
      description: 'Utilizada em todos os botões',
      state: 'ctaTextColor',
    },

    {
      label: 'Cor de título na etapa inicial',
      description: 'Aplicada ao título junto ao formulário',
      state: 'step1HeadingColor',
    },

    {
      label: 'Cor de título na etapa de sucesso',
      description: 'Utilizada quando ocorre a criação da carteira',
      state: 'step2HeadingColor',
    },

    {
      label: 'Cor de título na etapa de erro',
      description: 'Utilizada quando <u>não</u> ocorre a criação da carteira',
      state: 'step3HeadingColor',
    },
  ];

  return (
    <Card>
      <CardBody>
        <h3 className="mb-4">Logotipo</h3>
        <FileUpload
          invalid={formik.errors?.logoUrl}
          onRemoveFile={() => {
            dispatch({ logoUrl: '' });
            formik?.setFieldValue('logoUrl', null);
          }}
          onFinish={(file) => {
            if (file) {
              dispatch({ logoUrl: file?.url });
              formik.setFieldValue('logoUrl', file?.url);
            }
          }}
          values={
            formik?.values?.logoUrl
              ? [
                {
                  source: formik?.values?.logoUrl,
                  options: { type: 'local' },
                },
              ]
              : []
          }
        />

        <h3 className="my-4 pt-4">Definição de cores</h3>
        <Row className="row row-cols-1 row-cols-lg-2 g-4">
          {Object.keys(formik?.values)?.length
            ? VISUAL_PICKERS?.map(({ state: key, ...props }, i) => (
              <Col key={`pickr-${i}`}>
                <ColorPicker
                  {...props}
                  value={formik?.values?.[key] || '#fff'}
                  invalid={formik?.errors[key]}
                  onChange={(c) => {
                    dispatch({ [key]: c });
                    formik.setFieldValue(key, c);
                  }}
                />
              </Col>
            ))
            : null}
        </Row>
      </CardBody>

      <CardFooter className="text-end">
        <ActionButton
          icon="bx-right-arrow-alt"
          label="Salvar e continuar"
          onClick={async () => {
            const response = await formik.submitForm();
            if (response) setQs('active', 2);
          }}
        />
      </CardFooter>
    </Card>
  );
};

export default Step1;
