import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUsers as getUsersApi,
  getRoles as getRolesApi,
  getStores as getStoresApi,
  getRolesPaginated as getRolesPaginatedApi,
  createUser as createUserApi,
  updateUser as updateUserApi,
  updatePassword as updatePasswordApi,
  createStore as createStoreApi,
  getRecoveryLink as getRecoveryLinkApi,
  verifyToken as verifyTokenApi,
  createNewPassword as createNewPasswordApi,
  getUsersFromStore as getUsersFromStoreApi,
} from '../../helpers/bff_helper';
import { toast } from 'react-toastify';

export const getUsers = createAsyncThunk('users/getUsers', async (params) => {
  try {
    const response = getUsersApi(params ?? '');

    return response;
  } catch (error) {
    return error;
  }
});

export const getRoles = createAsyncThunk('users/getRoles', async (params) => {
  try {
    const response = getRolesApi(params);

    return response;
  } catch (error) {
    return error;
  }
});

export const getStores = createAsyncThunk('users/getStores', async (params) => {
  try {
    const response = getStoresApi(params);
    return response;
  } catch (error) {
    return error;
  }
});

export const getRolesPaginated = createAsyncThunk(
  'users/getRolesPaginated',
  async (params) => {
    try {
      const response = getRolesPaginatedApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async ({ user, successMessage, conflictMessage, errorMessage }) => {
    try {
      const response = await createUserApi(user);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      if (error === 'conflict') {
        toast.error(conflictMessage, { autoClose: 5000 });
        return {
          error: true,
          message: error,
        };
      }

      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ id, user, successMessage, errorMessage }) => {
    try {
      const response = await updateUserApi(id, user);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });

      return {
        error: true,
        message: error,
      };
    }
  }
);

export const updatePassword = createAsyncThunk(
  'users/updatePassword',
  async ({ data, invalidMessage, errorMessage }) => {
    try {
      const response = await updatePasswordApi(data);

      return response;
    } catch (error) {
      if (error === 'bad-request') {
        return {
          error: true,
          message: invalidMessage,
        };
      }

      toast.error(errorMessage, { autoClose: 5000 });

      return {
        error: true,
        message: error,
      };
    }
  }
);

export const createStore = createAsyncThunk(
  'users/createStore',
  async ({ store, successMessage, errorMessage }) => {
    try {
      const response = await createStoreApi(store);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const getRecoveryLink = createAsyncThunk(
  'users/recovery',
  async (email) => {
    try {
      await getRecoveryLinkApi({ email });
      return { status: 200, error: false, message: 'Request sent' };
    } catch (error) {
      return { error: true, message: error };
    }
  }
);

export const verifyToken = createAsyncThunk(
  'users/verify-token',
  async (token) => {
    try {
      await verifyTokenApi(token);
      return { status: 200, error: false, message: 'Request sent' };
    } catch (error) {
      return { error: true, message: error };
    }
  }
);

export const createNewPassword = createAsyncThunk(
  'users/recovery/new-password',
  async (data) => {
    try {
      await createNewPasswordApi(data);
      return { status: 200, error: false, message: 'Request sent' };
    } catch (error) {
      return { error: true, message: error };
    }
  }
);

// export const getUsersFromStore = createAsyncThunk(
//   "users/getUsersFromStore",
//   async (params) => {
//     try {
//       return getUsersFromStoreApi(params);
//     } catch (error) {
//       return error;
//     }
//   }
// );
