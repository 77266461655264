import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { SwiperSlide } from 'swiper/react';

const RuleItem = ({ event, active, rules, transactionalType, t }) => {
  const channels = ['sms', 'email', 'whatsapp'];
  const iconChannels = {
    sms: 'las la-sms',
    email: 'las la-at',
    whatsapp: 'lab la-whatsapp',
  };

  const [transactionalRules, setTransactionalRules] = useState([]);

  useEffect(() => {
    setTransactionalRules(rules);
  }, [rules]);
  const name = t(`transactional-rule-${event}`);
  return (
    <React.Fragment>
      <div className="card pt-2 border-0 item-box text-center d-flex align-items-center -">
        <div className="time">
          <span className="badge bg-success fs-13">{name}</span>
        </div>
        <div
          className="timeline-content p-3 rounded"
          style={{ maxHeight: 110 }}
        >
          <div>
            <h6 className="my-3">{name}</h6>

            <Row>
              {channels.map((channel, key) => {
                const rule = transactionalRules.find(
                  (trRule) => trRule.channel === channel && trRule.enabled
                );

                return (
                  <Col key={key} xs={4}>
                    <div className="avatar-sm flex-shrink-0 mb-2">
                      <span
                        className={`avatar-title fs-3 bg-success${
                          rule ? '' : '-subtle'
                        }`}
                        style={{ borderRadius: '50%' }}
                      >
                        {iconChannels[channel] && (
                          <i
                            className={`text-white ${iconChannels[channel]}`}
                          ></i>
                        )}
                      </span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(RuleItem);
