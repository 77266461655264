import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import ExportReport from '../../Components/Common/ExportReport';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import TableContainer from '../../Components/Common/TableContainer';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyInput from '../../Components/Common/CurrencyInput';
import Select from 'react-select';
import { getCategories } from '../../slices/catalogs/thunk';
import { getStrategyFilters } from '../../slices/strategies/thunk';
import { useFormik } from 'formik';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  createStrategy,
  getStrategyProducts,
  deleteStrategyProducts,
} from '../../slices/thunks';
import Yup from '../../helpers/yup_extras';
import Search from '../../Components/Common/Search';
import { formatCurrency } from '../../helpers/format';
import qs from 'qs';
import { AbilityContext } from '../../helpers/casl';
import useQsParams from '../../Components/Hooks/QueryString';
import Importer from './catalog/Importer';
import ReactSelect from 'react-select';
import { getFilters } from '../../Components/Common/Filter';
import DateFilter from '../../Components/Common/Filter/Date';

const NewStrategy = (props) => {
  let { type, slug } = useParams();
  const initialType = slug?.split('_')[0];
  const initialCashback = slug?.split('_')[1];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchParams, setSearchParams, setSearchParamsAsObject } =
		useQsParams();

  const TITLE = slug ? 'Configurar estratégia' : 'Nova estratégia';
  document.title = `${TITLE} | Opencashback`;

  const [isLoadingSubmit, setIsloadingSubmit] = useState(false);
  const [payload, setPayload] = useState({});
  const [showCsvImportModal, setCsvImportModal] = useState(false);

  const ability = useContext(AbilityContext);

  const getStrategyType = (type) => {
    switch (type) {
    case 'categoria':
      return 'category';
    case 'colecao':
      return 'collection';
    case 'produto':
      return 'product';
    default:
      return 'product';
    }
  };

  const generationOption = [
    { label: 'Percentual', value: 'percentage' },
    { label: 'Valor em reais', value: 'fixed' },
  ];

  const dataSelector = createSelector(
    (state) => state.Strategy,
    (catalog) => {
      const strategy_type = getStrategyType(type);

      if (strategy_type === 'product') {
        return catalog.products;
      }

      if (strategy_type === 'category') {
        return catalog.categories;
      }
    }
  );

  const metaSelector = createSelector(
    (state) => state.Strategy.meta,
    (meta) => meta
  );

  const isLoadingSelector = createSelector(
    (state) => state.Strategy.isLoading,
    (isLoading) => isLoading
  );

  const strategyFiltersSelector = createSelector(
    ({ Strategy }) => Strategy.filters,
    (slice) => slice
  );

  const data = useSelector(dataSelector);
  const meta = useSelector(metaSelector);
  const isLoading = useSelector(isLoadingSelector);
  const filters = useSelector(strategyFiltersSelector);

  const setNameOrExternalIdFilter = (value) => {
    setSearchParamsAsObject({ name: null, external_id: null, page: 1 });

    if (_.isNaN(_.toNumber(value))) {
      setSearchParamsAsObject({ name: value, page: 1 });
      return;
    }

    setSearchParamsAsObject({ external_id: value, page: 1 });
  };

  const generateServiceUrl = (options) => {
    const opts = { addQueryPrefix: true };
    return qs.stringify(
      {
        filters: {
          type: initialType,
          cashback: initialCashback,
          ...getFilters(filters),
        },
        ...options,
      },
      opts
    );
  };

  const getData = () => {
    const options = { page: searchParams.get('page') ?? 1 };

    if (getStrategyType(type) === 'product') {
      dispatch(getStrategyProducts(generateServiceUrl(options)));
    }

    if (getStrategyType(type) === 'category') {
      dispatch(getCategories(options));
    }
  };

  const translateStrategyName = (strategy) => {
    const [type, cashback] = strategy?.split('_');
    const opts = {
      fixed: { style: 'currency' },
      percentage: {
        style: 'percent',
        convert: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    }[type];

    return formatCurrency(cashback, opts);
  };

  useEffect(() => {
    getData();
    dispatch(getStrategyFilters());
  }, [searchParams.toString()]);

  useEffect(() => {
    if (data?.length) {
      const selectedRows = data?.reduce((acc, row) => {
        const newItem = row?.included && { [row?.external_id]: true };
        return { ...acc, ...newItem };
      }, {});

      setPayload({ ...selectedRows, ...payload });
    }
  }, [data]);

  const submitNameFilter = () => {
    setSearchParams('page', 1);
    getData();
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t('strategies.strategy.table.code'),
        accessor: 'external_id',
        reportProps: {
          accessor: 'external_id',
        },
      },
      {
        Header: props.t('strategies.strategy.table.name'),
        accessor: 'name',
        filterable: true,
        reportProps: {
          accessor: 'name',
        },
      },
      {
        Header: props.t('strategies.strategy.table.category'),
        Cell: (v) => v?.row?.original?.category ?? '-',
        reportProps: {
          accessor: 'category',
        },
      },
      {
        id: 'cashback-applied',
        Header: props.t('strategies.strategy.table.cashback-applied'),
        Cell: (v) => {
          const strategy = v?.row?.original?.strategy;
          const valueToSplit = strategy || slug;
          const [type, value] = valueToSplit.split('_');

          const opts = {
            fixed: { style: 'currency' },
            percentage: {
              style: 'percent',
              convert: true,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          }[type];

          return formatCurrency(value, opts);
        },
        reportProps: {
          accessor: 'cashback',
          formatter: {
            type: 'condition',
            properties: {
              fieldComparer: 'cashback_type',
              valueComparer: 'percentage',
              formatterTrue: {
                type: 'decimal_percentage',
                properties: {
                  value: 10,
                },
              },
              formatterFalse: {
                type: 'currency',
                properties: {
                  currency: 'BRL',
                  centsToCurrency: true,
                },
              },
            },
          },
        },
      },
      {
        Header: props.t('strategies.strategy.table.strategy'),
        Cell: (v) => {
          const type = v?.row?.original?.strategy_type;
          const str = {
            product: 'strategy-product',
            account: 'strategy-account',
          }[type];

          return props.t(`strategies.strategy.table.${str}`);
        },
        reportProps: {
          accessor: 'strategy_type',
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'product',
                  value: props.t('strategies.strategy.table.strategy-product'),
                },
                {
                  key: 'account',
                  value: props.t('strategies.strategy.table.strategy-account'),
                },
              ],
            },
          },
        },
      },
    ],
    []
  );

  const toNumber = (value, asString = false) => {
    const raw = String(value).replaceAll('.', '').replace(',', '.');
    return asString ? raw : Number(raw);
  };

  const generateInitialValues = () => {
    const values = {
      generation_type: '',
      cashback: '',
    };

    if (slug) {
      values.generation_type = initialType;
      values.cashback =
				initialType === 'fixed' ? initialCashback : initialCashback * 100;
    }

    return values;
  };

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: generateInitialValues(),
    validationSchema: Yup.object({
      generation_type: Yup.string().required(
        props.t('validation-required-generation-type')
      ),
      cashback: Yup.string()
        .required(props.t('validation-required-value'))
        .numberString(props.t('validation-number-value')),
    }),
    onSubmit: async (values) => {
      setIsloadingSubmit(true);

      const toBeRemoved = Object.keys(payload).filter((k) => !payload[k]);
      const toBeUpserted = Object.keys(payload).filter((k) => payload[k]);

      if (!_.isEmpty(toBeRemoved)) {
        await dispatch(
          deleteStrategyProducts({
            items: toBeRemoved,
            errorMessage: props.t('new-strategy-error'),
            successMessage: props.t(
              initialCashback
                ? 'update-strategy-success'
                : 'new-strategy-success'
            ),
          })
        );
      }

      if (!_.isEmpty(toBeUpserted)) {
        const isFixed = values?.generation_type === 'fixed';
        const rawCashback = toNumber(values.cashback, true);
        const isDifferentCashback = initialCashback !== rawCashback;

        let cashback = parseFloat(rawCashback);
        if (isFixed && isDifferentCashback) cashback = cashback * 100;
        if (!isFixed) cashback = cashback / 100;

        await dispatch(
          createStrategy({
            strategy: {
              type: values.generation_type,
              items: toBeUpserted,
              cashback,
            },
            errorMessage: props.t('new-strategy-error'),
            successMessage: props.t(
              initialCashback
                ? 'update-strategy-success'
                : 'new-strategy-success'
            ),
          })
        );
      }

      setTimeout(() => {
        validation.resetForm();
        navigate('/estrategias/catalogo');
      }, 4000);

      setIsloadingSubmit(false);
    },
  });

  const isInvalid = (form, field) => {
    return form.touched[field] && form.errors[field];
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            showBackButton
            backTo="/estrategias/catalogo"
            title={TITLE}
            pageTitle="Gestão"
          />
          <Row>
            <Col lg={12} xl={8}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col
                      className="d-flex flex-row align-items-center justify-content-between"
                      sm={12}
                      xl={5}
                      lg={12}
                    >
                      <h4
                        className="card-title mb-sm-3 mb-xl-0 align-items-center"
                        style={{ maxWidth: '307px' }}
                      >
                        {props.t(`select-${getStrategyType(type)}-items`)}
                      </h4>
                      {isLoading && (
                        <Spinner
                          color="secondary"
                          style={{ marginLeft: '15px' }}
                        />
                      )}
                    </Col>

                    <Col sm={12} xl={4} lg={8} className="flex-grow-1 mb-2">
                      <Search
                        placeholder={props.t('search-by-name-or-code')}
                        onChange={setNameOrExternalIdFilter}
                        onSubmit={submitNameFilter}
                      />
                    </Col>

                    {slug && (
                      <Col sm={12} xl={2} lg={3} className="text-md-end">
                        <ExportReport
                          pages={meta?.pages}
                          disabled={isLoading || !data?.length}
                          filename="Relatório OCK: Produtos da Estratégia"
                          service={`strategies/details${generateServiceUrl()}`}
                          columns={columns}
                          data={{}}
                          filters={{}}
                        />
                      </Col>
                    )}
                  </Row>
                  {filters?.length > 0 && (
                    <Row className="mt-4">
                      <Col>
                        <Label className="form-label text-muted text-uppercase fw-semibold mb-3">
                          {props.t(
                            filters.find((x) => x.field === 'strategy_type')
                              ?.name
                          )}
                        </Label>
                        <ReactSelect
                          name="strategy_type_filter"
                          isClearable
                          isSearchable
                          isDisabled={isLoading}
                          placeholder={props.t(
                            filters.find((x) => x.field === 'strategy_type')
                              ?.name
                          )}
                          onChange={(v) => {
                            setSearchParamsAsObject({
                              strategy_type: v?.value ?? '',
                              page: 1,
                            });
                          }}
                          value={(() => {
                            const filter = filters?.find(
                              (f) => f?.field === 'strategy_type'
                            );
                            const val = filter?.values?.find(
                              (x) =>
                                x?.value === searchParams?.get('strategy_type')
                            );
                            return val
                              ? { ...val, label: props.t(val?.label) }
                              : undefined;
                          })()}
                          options={(() => {
                            const strValues = filters?.find(
                              (x) => x?.field === 'strategy_type'
                            )?.values;
                            return strValues.map((x) => ({
                              ...x,
                              label: props.t(x?.label),
                            }));
                          })()}
                        />
                      </Col>

                      <Col>
                        <Label className="form-label text-muted text-uppercase fw-semibold mb-3">
                          {props.t(
                            filters.find((x) => x?.field === 'strategy_name')
                              ?.name
                          )}
                        </Label>
                        <ReactSelect
                          name="strategy_name_filter"
                          isClearable
                          isSearchable
                          isDisabled={isLoading}
                          placeholder={props.t(
                            filters.find((x) => x?.field === 'strategy_name')
                              ?.name
                          )}
                          onChange={(v) => {
                            setSearchParamsAsObject({
                              strategy_name: v?.value ?? '',
                              page: 1,
                            });
                          }}
                          value={(() => {
                            const filter = filters?.find(
                              (f) => f?.field === 'strategy_name'
                            );
                            const val = filter?.values?.find(
                              (x) =>
                                x?.value === searchParams?.get('strategy_name')
                            );
                            return val
                              ? {
                                ...val,
                                label: translateStrategyName(val?.label),
                              }
                              : undefined;
                          })()}
                          options={filters
                            ?.find((x) => x?.field === 'strategy_name')
                            ?.values.map((x) => ({
                              label: translateStrategyName(x?.label),
                              value: x?.value,
                            }))}
                        />
                      </Col>

                      <Col>
                        <Label className="form-label text-muted text-uppercase fw-semibold mb-3">
                          {props.t(
                            filters.find((x) => x?.field === 'category_id')
                              ?.name
                          )}
                        </Label>
                        <ReactSelect
                          name="category_id_filter"
                          isClearable
                          isSearchable
                          isMulti
                          isDisabled={isLoading}
                          placeholder={props.t(
                            filters.find((x) => x?.field === 'category_id')
                              ?.name
                          )}
                          onChange={(selectedOptions) => {
                            const selectedIds = selectedOptions
                              ? selectedOptions?.map((option) => option?.value)
                              : [];
                            setSearchParamsAsObject({
                              category_id: selectedIds.join(','),
                              page: 1,
                            });
                          }}
                          options={
                            filters?.find((x) => x?.field === 'category_id')
                              ?.values
                          }
                          styles={{
                            multiValue: (base) => ({
                              ...base,
                              backgroundColor: '#49c997',
                              color: 'white',
                            }),
                            multiValueLabel: (base) => ({
                              ...base,
                              color: 'white',
                            }),
                            multiValueRemove: (base) => ({
                              ...base,
                              color: 'white',
                              ':hover': {
                                backgroundColor: '#48bb8e',
                                color: 'white',
                              },
                            }),
                          }}
                        />
                      </Col>

                      <Col>
                        <DateFilter
                          isLoading={isLoading}
                          item={(() => {
                            const filter = filters?.find(
                              (x) => x?.field === 'created_at'
                            );
                            const { name, ...rest } = filter;
                            return {
                              name: props.t(name),
                              ...rest,
                            };
                          })()}
                          value={[
                            searchParams?.get('created_at_start')
                              ? new Date(searchParams?.get('created_at_start'))
                              : null,
                            searchParams?.get('created_at_end')
                              ? new Date(searchParams?.get('created_at_end'))
                              : null,
                          ]}
                          onChange={(v) => {
                            if (v[0] && v[1]) {
                              const d1 = moment(v[0]).unix();
                              const d2 = moment(v[1]).unix();
                              setSearchParamsAsObject({
                                created_at: !d2 ? d1 : `${d1},${d2}`,
                                page: 1,
                              });
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </CardHeader>

                <CardBody className="pt-0">
                  <TableContainer
                    isLoading={isLoading}
                    columns={columns}
                    data={isLoading ? [] : [...data]}
                    pageSize={meta?.take || 10}
                    totalPages={meta?.pages || 10}
                    count={meta?.total || 10}
                    activePage={searchParams.get('page')}
                    setPage={(p) => setSearchParams('page', p)}
                    selectedRows={payload}
                    setSelectedRows={setPayload}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    disabled={
                      ability.cannot('update', 'catalog_strategies') ||
											validation.isSubmitting
                    }
                  />
                </CardBody>
              </Card>
            </Col>

            <Col lg={12} xl={4}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row gap-3">
                    <h4 className="card-title mb-0">
                      {props.t('setup-cashback-generation')}
                    </h4>

                    <button
                      type="button"
                      // disabled={(disabled && access_token) || isLoading}
                      className="btn btn-secondary add-btn"
                      onClick={() => setCsvImportModal(true)}
                    >
                      <i className="align-bottom me-1 ri-file-upload-line" />
                      {props.t('strategies.catalog.import.cta')}
                    </button>
                  </div>
                </CardHeader>

                <CardBody>
                  <Form
                    className="tablelist-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div>
                      <Row>
                        <Col>
                          <p className="border shadow-lg mb-4 border-text-muted rounded text-center p-3">
                            {props.t('strategies.strategy.warn')}
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="generation-type"
                            >
                              {props.t('cashback-generation')}
                            </Label>
                            <Select
                              isDisabled={ability.cannot(
                                'update',
                                'catalog_strategies'
                              )}
                              name="generation_type"
                              id="generation-type"
                              value={generationOption.find((item) => {
                                return (
                                  item.value ===
																	validation.values.generation_type
                                );
                              })}
                              onChange={(e) => {
                                validation.setFieldValue(
                                  'generation_type',
                                  e.value
                                );
                              }}
                              options={generationOption}
                              placeholder={props.t('select-placeholder')}
                            />
                            {isInvalid(validation, 'generation_type') && (
                              <FormFeedback className="d-block" type="invalid">
                                {validation.errors.generation_type}
                              </FormFeedback>
                            )}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3 input-group">
                            <Label className="form-label" htmlFor="cashback">
                              {props.t('setup-the')} {` `}
                              {validation.values.generation_type ===
															'percentage'
                                ? props.t('percentage')
                                : props.t('fixed_amount')}
                            </Label>
                            {validation.values.generation_type ===
															'percentage' && (
                              <div className="input-group">
                                <Input
                                  name="cashback"
                                  className="form-control"
                                  placeholder={`${props.t(
                                    'setup-the'
                                  )} ${props.t('percentage')}`}
                                  type="number"
                                  min="0"
                                  max="100"
                                  step="1"
                                  pattern="[0-9]"
                                  onKeyDown={(e) => {
                                    if (e.key === '.') e.preventDefault();
                                  }}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^0-9]*/g,
                                      ''
                                    ))
                                  }
                                  validate={{ required: { value: true } }}
                                  value={validation.values.cashback}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    isInvalid(validation, 'cashback')
                                      ? true
                                      : false
                                  }
                                />
                                <span className="input-group-text">%</span>
                                {isInvalid(validation, 'cashback') && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.cashback}
                                  </FormFeedback>
                                )}
                              </div>
                            )}
                            {validation.values.generation_type === 'fixed' && (
                              <div className="input-group">
                                <span className="input-group-text">
                                  {props.t('currency')}
                                </span>
                                <CurrencyInput
                                  name="cashback"
                                  placeholder="0,00"
                                  onChange={(e) =>
                                    validation.setFieldValue('cashback', e)
                                  }
                                  onBlur={validation.handleBlur}
                                  value={validation.values.cashback}
                                  invalid={isInvalid(validation, 'cashback')}
                                  disabled={ability.cannot(
                                    'update',
                                    'catalog_strategies'
                                  )}
                                />
                                {isInvalid(validation, 'cashback') && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.cashback}
                                  </FormFeedback>
                                )}
                              </div>
                            )}
                            {!['fixed', 'percentage'].includes(
                              validation.values.generation_type
                            ) && (
                              <div className="input-group">
                                <span className="text-muted mt-2">
																	Selecione a geração de cashback
                                </span>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col>
                        <span className="lh-1 fs-small">
                          {props.t('strategies.strategy.instruction')}
                        </span>
                      </Col>

                      <Col
                        md={12}
                        xxl={6}
                        className="mt-sm-3 mt-xxl-0 d-flex align-items-center justify-content-sm-start justify-content-lg-end"
                      >
                        <button
                          type="button"
                          onClick={() => navigate('/estrategias/catalogo')}
                          className="btn btn-light"
                        >
                          {props.t('back')}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success btn-label right ms-4"
                          disabled={
                            isLoadingSubmit ||
														isLoading ||
														ability.cannot('update', 'catalog_strategies')
                          }
                        >
                          <span className="label-icon align-middle fs-16 ms-2">
                            <i
                              className={
                                isLoadingSubmit || isLoading
                                  ? 'ri-loader-4-line rotate'
                                  : 'ri-arrow-right-line'
                              }
                            />
                          </span>
                          {props.t('save')}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Importer
          isOpen={showCsvImportModal}
          toggle={() => setCsvImportModal(!showCsvImportModal)}
          onSuccess={() => {
            navigate('/estrategias/catalogo');
            setTimeout(() => toast.success(props.t('import-success')), 2000);
          }}
        />

        <ToastContainer position="top-center" closeButton={false} />
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(NewStrategy);
