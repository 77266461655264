import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { updateGeneralSettings } from '../../../helpers/bff_helper';

const isInvalid = (form, field) => {
  return form.touched[field] && form.errors[field];
};

const MasterToken = (props) => {
  const title = props.t('general-settings.master-token.title');
  const description = props.t('general-settings.master-token.description');

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const { settings, isLoading } = useSelector(
    createSelector(
      (state) => state.GeneralSettings,
      (slice) => slice
    )
  );

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    initialValues: {
      master_token: settings?.master_token ?? '',
    },

    validationSchema: Yup.object({
      master_token: Yup.string().required(props.t('required')),
    }),

    onSubmit: async (values) => {
      const response = await dispatch(
        updateGeneralSettings({
          ...values,
          successMessage: props.t('general-settings.master-token.success'),
          errorMessage: props.t('general-settings.master-token.error'),
        })
      );

      if (response.success) {
        toast.success(props.t('general-settings.master-token.success'));
      } else {
        toast.error(props.t('general-settings.master-token.error'));
      }
      return response;
    },
  });

  return (
    <Card>
      <CardHeader>
        <h4 className="mb-1">{title}</h4>
        <span className="text-muted">{description}</span>
      </CardHeader>

      <CardBody>
        <Form onSubmit={form.handleSubmit} autoComplete="off">
          <Row>
            <Col lg={8} xl={6}>
              <Label className="form-label" htmlFor="master_token">
                {props.t('general-settings.master-token.label')}
              </Label>

              <div className="position-relative">
                {!isInvalid(form, 'master_token') &&
								form.values.master_token?.toString()?.length ? (
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="position-absolute bg-transparent end-0 translate-middle-y top-50 border-0"
                      style={{ zIndex: 400, lineHeight: 1 }}
                    >
                      <i
                        className={classNames({
                          'bx me-1 fs-4 text-muted': true,
                          'bx-show': !showPassword,
                          'bx-hide': showPassword,
                        })}
                      />
                    </button>
                  ) : null}

                <Input
                  name="master_token"
                  id="master_token"
                  className="form-control"
                  style={{ letterSpacing: '.2rem' }}
                  placeholder="••••••"
                  inputMode="numeric"
                  autoComplete="off"
                  disabled={isLoading}
                  type={showPassword ? 'text' : 'password'}
                  invalid={isInvalid(form, 'master_token')}
                  value={form.values.master_token}
                  onBlur={form.handleBlur}
                  onChange={(v) => {
                    const raw = v?.target?.value?.toString();
                    const value = raw.replace(/\D/g, '');
                    if (value?.length <= 6) {
                      form.setFieldValue('master_token', value);
                    }
                  }}
                />

                {isInvalid(form, 'master_token') && (
                  <FormFeedback type="invalid">
                    {form.errors.master_token}
                  </FormFeedback>
                )}
              </div>
            </Col>
          </Row>

          <div className="d-flex align-items-start gap-3 mt-4">
            <button
              type="submit"
              className="btn btn-success btn-label right ms-auto nexttab nexttab"
              disabled={
                form?.values?.master_token?.length < 6 ||
								form.isSubmitting ||
								isLoading
              }
            >
              <span className="label-icon ms-2">
                <i
                  className={classNames({
                    'bx align-middle': true,
                    'bx-loader-alt bx-spin fs-20': form.isSubmitting,
                    'bx-check fs-20': !form.isSubmitting,
                  })}
                />
              </span>

              {props.t('save')}
            </button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(MasterToken);
