import { createSlice } from '@reduxjs/toolkit';
import { getGeneralSettings } from './thunk';

export const initialState = {
  settings: {},
  isLoading: false,
  isError: false,
  error: {},
};

const generalSettingsSlice = createSlice({
  name: 'GeneralSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeneralSettings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getGeneralSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.settings = action.payload;
    });
    builder.addCase(getGeneralSettings.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload || null;
      console.log(action);
    });
  },
});

export default generalSettingsSlice.reducer;
