import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { approveOrder } from '../../../slices/orders/thunk';

import { useDispatch } from 'react-redux';

const ApproveOrder = ({ isOpen, toggle, order, onSuccess, t }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const encodeId = (id) => { 
    return encodeURIComponent(id);
  } 

  const approveOrderApi = async () => {
    const id = encodeId(order?.ticket || order?.external_id)

    setIsLoading(true);

    const action = await dispatch(
      approveOrder({
        external_id: id,
        successMessage: t('orders-management.approve-order.successMessage'),
        errorMessage: t('orders-management.approve-order.errorMessage'),
      })
    );

    setIsLoading(false);
    toggle();

    if (!action.payload.error) {
      onSuccess();
    }
  };

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {t('orders-management.approve-order.approve')}
        </ModalHeader>

        <ModalBody>
          <h4 className="mb-4 text-center">
            {t('orders-management.approve-order.modal-description')}
          </h4>
          <br></br>
          <h4 className="mb-4 text-center">
            {t('orders-management.approve-order.question')}
          </h4>
        </ModalBody>

        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={toggle}>
              {t('close')}
            </button>
            <button
              onClick={approveOrderApi}
              type="submit"
              disabled={!order}
              className="btn btn-success"
              id="add-btn"
            >
              <div className="d-flex">
                {isLoading && <Spinner size="sm" className="me-3" />}
                {t('confirm')}
              </div>
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(ApproveOrder);
