import React, { useContext } from 'react';
import { Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import CurrencyInput from '../../../../Components/Common/CurrencyInput';
import { AbilityContext } from '../../../../helpers/casl';

const CashbackValueForm = ({ form, ...props }) => {
  const ability = useContext(AbilityContext);

  const isInvalid = (form, field) => {
    return form.touched[field] && form.errors[field];
  };

  const calculationBasisOptions = [
    { label: 'Valor total do pedido', value: 'total' },
    { label: 'Valor total pago', value: 'paid' },
  ];

  const generationOption = [
    { label: 'Percentual', value: 'percentage' },
    { label: 'Valor em reais', value: 'fixed' },
  ];

  return (
    <div
      className="d-flex flex-column gap-3 mb-2 pb-2"
      style={{ borderBottom: '2px solid #eee' }}
    >
      <div>
        <h6 style={{ fontWeight: 'bold' }}>Valor do Cashback</h6>

        <Row className="flex-column gap-3 flex-lg-row mt-3">
          <Col>
            <Label className="form-label" htmlFor="generation-type">
              {props.t('cashback-generation')}
            </Label>

            <Select
              name="generation"
              id="generation-type"
              value={generationOption.find((item) => {
                return item.value === form.values.generation;
              })}
              onChange={(e) => {
                form.setFieldValue('generation', e.value);
              }}
              options={generationOption}
              isDisabled={
                ability.cannot('update', 'account_strategies') || form.isLoading
              }
              placeholder={form.IsLoading ? 'Aguarde...' : 'Selecione'}
            />

            {isInvalid(form, 'generation') && (
              <FormFeedback className="d-block" type="invalid">
                {form.errors.generation}
              </FormFeedback>
            )}
          </Col>

          <Col>
            <Label className="form-label" htmlFor="cashback">
              {props.t('setup-the')} {` `}
              {form.values.generation === 'percentage'
                ? props.t('percentage')
                : props.t('fixed_amount')}
            </Label>

            {form.values.generation === 'percentage' && (
              <div className="input-group">
                <Input
                  name="cashback"
                  id="cashback"
                  className="form-control"
                  placeholder={`${props.t(
                    'setup-the'
                  )} ${props.t('percentage')}`}
                  type="text"
                  validate={{ required: { value: true } }}
                  value={form.values.cashback}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  invalid={isInvalid(form, 'cashback') ? true : false}
                  disabled={ability.cannot('update', 'account_strategies')}
                />
                <span className="input-group-text">%</span>
                {isInvalid(form, 'cashback') && (
                  <FormFeedback type="invalid">
                    {form.errors.cashback}
                  </FormFeedback>
                )}
              </div>
            )}

            {form.values.generation === 'fixed' && (
              <div className="input-group">
                <span className="input-group-text">{props.t('currency')}</span>
                <CurrencyInput
                  name="cashback"
                  placeholder="0,00"
                  onChange={(e) => form.setFieldValue('cashback', e)}
                  onBlur={form.handleBlur}
                  value={form.values.cashback}
                  invalid={isInvalid(form, 'cashback')}
                  disabled={ability.cannot('update', 'account_strategies')}
                />
                {isInvalid(form, 'cashback') && (
                  <FormFeedback type="invalid">
                    {form.errors.cashback}
                  </FormFeedback>
                )}
              </div>
            )}

            {!['fixed', 'percentage'].includes(form.values.generation) && (
              <div className="input-group">
                <span className="text-muted mt-2">
                  {!form.IsLoading ? 'Aguarde...' : null}
                </span>
              </div>
            )}
          </Col>

          <Col>
            <Label className="form-label" htmlFor="calculation-basis">
              {props.t('calculation-basis')}
            </Label>
            <Select
              name="calculation_basis"
              id="calculation-basis"
              value={calculationBasisOptions.find((item) => {
                return item.value === form.values.calculation_basis;
              })}
              onChange={(e) => {
                form.setFieldValue('calculation_basis', e.value);
              }}
              options={calculationBasisOptions}
              isDisabled={
                ability.cannot('update', 'account_strategies') || form.isLoading
              }
              placeholder={form.IsLoading ? 'Aguarde...' : 'Selecione'}
            />
            {isInvalid(form, 'calculation_basis') && (
              <FormFeedback className="d-block" type="invalid">
                {form.errors.calculation_basis}
              </FormFeedback>
            )}{' '}
          </Col>
        </Row>
      </div>

      <Row>
        <Col xs={12}>
          <div className="mb-3">
            <Label className="form-label" htmlFor="minimum_accumulation">
              {props.t('minimum_accumulation')}
            </Label>

            <div className="input-group">
              <span className="input-group-text">{props.t('currency')}</span>

              <CurrencyInput
                placeholder="0,00"
                id="minimum-cashback-value"
                onChange={(e) => form.setFieldValue('minimum_accumulation', e)}
                onBlur={form.handleBlur}
                value={form.values.minimum_accumulation}
                invalid={isInvalid(form, 'minimum_accumulation')}
                disabled={ability.cannot('update', 'account_strategies')}
              />

              {isInvalid(form, 'minimum_accumulation') && (
                <FormFeedback type="invalid">
                  {form.errors.minimum_accumulation}
                </FormFeedback>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(CashbackValueForm);
