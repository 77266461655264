import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import parseHtml from 'html-react-parser';
import classNames from 'classnames';

import { getCommunicationTemplate } from '../../../../slices/communication/thunk';
import useQsParams from '../../../../Components/Hooks/QueryString';
import { cleanup } from '../../../../slices/communication/reducer';
import { type } from '../types';
import { sanitizeSmsBodyAndAppendSuffix } from '../helper';

const TemplatePreview = (props) => {
  const dispatch = useDispatch();
  const { qs, setQs } = useQsParams();

  const { template, isLoading } = useSelector(
    createSelector(
      (state) => state.Communication,
      ({ isLoading, template }) => ({ isLoading, template })
    )
  );

  useEffect(() => {
    const id = qs.get('id');
    if (id) dispatch(getCommunicationTemplate(id));
  }, [qs]);

  const toggleAndCleanup = () => {
    setQs('id', '');
    setTimeout(() => {
      dispatch(cleanup());
    }, 800);
  };

  const isEmail = ['email'].includes(template?.type);

  return (
    <Modal
      isOpen={Boolean(qs.get('id'))}
      toggle={toggleAndCleanup}
      contentClassName="border-0 bg-transparent"
      size={isEmail ? 'lg' : 'md'}
      centered
      fade
    >
      <button
        type="button"
        style={{ position: 'absolute', top: -60, right: 0 }}
        className="bg-transparent border-0"
        onClick={toggleAndCleanup}
      >
        <i className="bx bx-x bx-md lh-1 p-1 m-0 bg-success rounded-circle text-white shadow-lg" />
      </button>

      <ModalBody className="p-0" style={{ border: 0 }}>
        <div
          style={{
            border: '15px solid black',
            borderRadius: 30,
            width: '100%',
          }}
        >
          <div
            style={{ borderRadius: '15px 15px 0 0' }}
            className="p-4 fs-5 bg-light"
          >
            <div className="d-flex justify-content-between">
              <div>
                <p className="m-0">
                  {props.t('communication-templates.preview.title')}:
                </p>
                <span className="fw-semibold">{template.title ?? '-'}</span>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                {template.type ? (
                  <div className="border border-2 border-success py-2 px-4 rounded-pill shadow-lg">
                    <span className="fs-5 fw-semibold">
                      {type[template.type] ?? '-'}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div
            className="w-100 bg-white"
            style={{
              borderRadius: '0 0 15px 15px',
              height: isLoading ? 100 : isEmail ? 700 : 200,
              transition: 'all 500ms',
              overflowX: 'auto',
            }}
          >
            {!isLoading && template?.content ? (
              <div className={classNames({ 'p-4': !isEmail })}>
                {isEmail
                  ? parseHtml(template?.content)
                  : sanitizeSmsBodyAndAppendSuffix(
                    template?.content,
                    props.t('communication-templates.form.sms.rules')
                  )}
              </div>
            ) : (
              <div className="w-100 h-100 p-4 d-flex justify-content-center align-items-center">
                <Spinner color="secondary" />
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(TemplatePreview);
