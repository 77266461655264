import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

const IncentiveDropdown = ({ incentive, onClickDelete, t }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const navigate = useNavigate();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const toggleTooltip = () =>
    setTooltipOpen(deleteGroupDisabled() ? !tooltipOpen : tooltipOpen);

  const deleteGroupDisabled = () => {
    if (incentive?.total_wallets) return true;
    if (!isEmpty(incentive?.incentives)) return true;

    return false;
  };

  return (
    <div className="text-left">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="start">
        <DropdownToggle className="text-muted w-auto border-0 bg-transparent p-0">
          <i className="bx bx-list-ul bx-sm align-bottom" />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem onClick={() => navigate(`/incentivos/${incentive.id}`)}>
            <div className="d-flex align-top">
              <i className="bx bx-show text-muted bx-xs me-2" />
              <span>{t('incentives-management.detail-label')}</span>
            </div>
          </DropdownItem>

          <div id="tooltipDeleteGroup">
            <Tooltip
              placement={'top'}
              isOpen={tooltipOpen}
              target={'tooltipDeleteGroup'}
              toggle={toggleTooltip}
            >
              {t('incentives-management.delete-group-validation')}
            </Tooltip>
            <DropdownItem
              onClick={onClickDelete}
              disabled={deleteGroupDisabled()}
            >
              <div className="d-flex align-top">
                <i className="bx bx-trash text-muted bx-xs me-2" />
                <span>
                  {t('incentives-management.delete-group.delete-label')}
                </span>
              </div>
            </DropdownItem>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(IncentiveDropdown);
