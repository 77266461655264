import React, { useReducer, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import {
  Badge,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from 'reactstrap';

import { useFormik } from 'formik';
import Select from 'react-select';
import Wizard from '../../../Components/Common/Wizard';
import CsvImporter from '../../../Components/Common/CsvImporter';
import { batchImportBlockedProducts } from '../../../slices/strategies/thunk';
import { useDispatch } from 'react-redux';
import { removeBomFromBinaryFile } from '../../../helpers/encode_file';

const initialValues = {
  status: undefined,
  file: undefined,
  fields: undefined,
  rows: 0,
};

const CatalogBlockedImporter = ({ isOpen, toggle, onSuccess, t, tReady }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();

  const [payload, setPayload] = useReducer(
    (acc, item) => ({ ...acc, ...item }),
    initialValues
  );

  const statuses = [
    {
      label: t('customer-wallet-status-blocked_redeem'),
      value: 'block_redeem',
    },
    {
      label: t('customer-wallet-status-blocked_accumulation'),
      value: 'block_accumulation',
    },
    { label: t('customer-wallet-status-blocked'), value: 'blocked' },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async () => {
      setIsLoading(true);

      const cleanFile = await removeBomFromBinaryFile(payload.file);

      const { columnFields, preview, status: block_type } = payload;
      const configurationParse = columnFields.reduce(
        (currentvalue, value, index) => {
          const column = preview.columns.find((val) => val.index === index);

          return {
            ...currentvalue,
            [value || '']: { column: column?.header },
          };
        },
        {}
      );

      const data = {
        file: cleanFile,
        block_type,
        configuration_parse: JSON.stringify(configurationParse),
      };

      const action = await dispatch(
        batchImportBlockedProducts({
          ...data,
          successMessage: t('import-success'),
          errorMessage: t('import-error'),
        })
      );

      setIsLoading(false);

      if (!action.payload.error) {
        resetState();
        onSuccess();
      }
    },
  });

  const resetState = () => {
    setTimeout(() => {
      setActiveTab(1);
      setPayload(initialValues);
      formik.resetForm();
    }, 1000);
    toggle();
  };

  return (
    <>
      <Modal
        centered
        isOpen={isOpen}
        size={activeTab >= 2 ? 'lg' : 'xl'}
        style={{ transition: 'all 0.2s ease' }}
        toggle={resetState}
        id="showModal"
      >
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {tReady && t('catalog-blocked-products-modal-heading')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Wizard
              showSteps
              activeTab={activeTab}
              tabs={[
                {
                  label:
										tReady && t('catalog-blocked-products-modal-step1-heading'),
                  component: (
                    <>
                      <h6>
                        {tReady &&
													t('catalog-blocked-products-modal-step1-heading')}
                      </h6>
                      <p className="d-w-100">
                        {tReady &&
													t('catalog-blocked-products-modal-step1-description')}
                      </p>

                      <CsvImporter
                        onClose={() => setActiveTab(2)}
                        onComplete={(data) => setPayload({ ...data })}
                        dataHandler={(raw) => {
                          const rows = raw?.reduce(
                            (acc, i) => acc + i,
                            payload.rows
                          );
                          setPayload({ rows });
                        }}
                        fields={[
                          { name: 'sku_id', label: t('catalog-table-sku_id') },
                        ]}
                      />
                    </>
                  ),
                },

                {
                  label: tReady && t('csv-edit-wallet-step2-heading'),
                  component: (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h6>
                        {tReady &&
													t('catalog-blocked-products-modal-step2-heading')}
                      </h6>
                      <span>
                        {tReady &&
													t('catalog-blocked-products-modal-step2-description')}
                      </span>
                      <Trans
                        t={t}
                        i18nKey={'catalog-blocked-products-modal-step2-body'}
                        components={{
                          b: <strong style={{ display: 'contents' }} />,
                        }}
                      />

                      <div className="gap-2 mt-4 mb-2 w-50 justify-content-start">
                        <Label className="form-label" htmlFor="wallet-status">
                          {t('block-type')}{' '}
                          <span className="text-danger">*</span>
                        </Label>

                        <Select
                          name="status"
                          onChange={({ value }) => {
                            formik.setFieldValue({ status: value });
                            setPayload({ status: value });
                          }}
                          value={formik.values.status}
                          placeholder={t('select-placeholder')}
                          options={statuses}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn btn-success align-right"
                        style={{ alignSelf: 'end' }}
                        disabled={!payload?.status}
                        onClick={() => setActiveTab(3)}
                      >
                        {t('confirm')}
                      </button>
                    </div>
                  ),
                },

                {
                  label:
										tReady && t('catalog-blocked-products-modal-step3-heading'),
                  component: (
                    <>
                      <h6>
                        {tReady &&
													t('catalog-blocked-products-modal-step3-heading')}
                      </h6>
                      <p className="d-w-100">
                        {tReady &&
													t('catalog-blocked-products-modal-step3-description')}
                      </p>

                      <Table bordered striped>
                        <tbody>
                          <tr>
                            <th style={{ width: '30%' }} scope="row">
                              {t('catalog-import-review-amount')}
                            </th>
                            <td>
                              {payload?.rows} {t('items')}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ width: '30%' }} scope="row">
                              {t('blocked-status')}
                            </th>
                            <td>
                              {payload?.status && (
                                <Badge color="danger">
                                  {
                                    statuses.find(
                                      (s) => s.value === payload.status
                                    ).label
                                  }
                                </Badge>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={resetState}
                          disabled={isLoading}
                        >
                          {t('close')}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={isLoading}
                        >
                          {t('confirm')}
                        </button>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
};

export default withTranslation()(CatalogBlockedImporter);
