import { withTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from 'reactstrap';
import useQsParams from '../../../../Components/Hooks/QueryString';

const StatusFilter = ({ t }) => {
  const { searchParams, setSearchParamsAsObject } = useQsParams();

  const filterNavItems = [
    { label: t('communication-templates.filter.all'), value: '' },
    { label: t('communication-templates.filter.active'), value: 'true' },
    { label: t('communication-templates.filter.inactive'), value: 'false' },
  ];

  return (
    <div style={{ borderTop: '1px dashed #e9ebec' }} className="px-3 pt-2">
      <Nav className="filter">
        {filterNavItems.map((item, key) => {
          const value = item.value;
          const status = searchParams.get('isActive');
          const isEmpty = !status && value === '';

          return (
            <NavItem key={key}>
              <NavLink
                active={isEmpty || status === value}
                onClick={() =>
                  setSearchParamsAsObject({ isActive: value, page: '' })
                }
                href="#"
              >
                {item.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    </div>
  );
};

export default withTranslation()(StatusFilter);
