import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import useQsParams from '../../Components/Hooks/QueryString';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import TableContainer from '../../Components/Common/TableContainer';
import ExportReport from '../../Components/Common/ExportReport';
import Filter, { getFilters } from '../../Components/Common/Filter';
import Search from '../../Components/Common/Search';
import EditDigitalWallet from '../DigitalWallets/Edit';

import {
  formatDocument,
  formatDate,
  formatCurrency,
  convertToCents,
} from '../../helpers/format';
import { getCustomers } from '../../slices/customers/thunk';
import { cleanDocument } from '../../helpers/functions';
import { Can } from '../../helpers/casl';

const DATE_FORMAT = 'DD/MM/YYYY HH:MM';

const LeadCaptureDashboard = (props) => {
  document.title = `${props.t('lead-capture.dashboard.title')} | Opencashback`;

  const dispatch = useDispatch();
  const { searchParams, setSearchParams, resetSearchParams } = useQsParams();
  const [createdAtFilter, setCreatedAtFilter] = useState(null);
  const [editWalletModal, setEditWalletModal] = useState(false);
  const [editWalletData, setEditWalletData] = useState({});
  const datePickerRef = useRef(null);

  const customersState = createSelector(
    (s) => s.Customer.customers,
    (customers) => customers
  );

  const loadingState = createSelector(
    (s) => s.Customer.isLoading,
    (isLoading) => isLoading
  );

  const metaState = createSelector(
    (s) => s.Customer.meta,
    (meta) => meta
  );

  const meta = useSelector(metaState);
  const customers = useSelector(customersState);
  const isLoading = useSelector(loadingState);

  useEffect(() => {
    getData();
  }, [window.location.search]);

  const filters = [
    {
      field: 'amount_available',
      type: 'range',
      name: props.t('balance'),
      value: {
        from: searchParams.getAll('balance')[0] || 0,
        to: searchParams.getAll('balance')[1] || 0,
      },
      onChange: (v) => setSearchParams('balance', [v?.from, v?.to]),
    },
    {
      field: 'status',
      type: 'checkbox',
      name: props.t('status'),
      value: searchParams.getAll('status') || [],
      onChange: (v) => setSearchParams('status', v),
      values: [
        { label: props.t('customer-wallet-status-active'), value: 'active' },
        {
          label: props.t('customer-wallet-status-inactive'),
          value: 'inactive',
        },
        { label: props.t('customer-wallet-status-blocked'), value: 'blocked' },
        {
          label: props.t('customer-wallet-status-blocked_accumulation'),
          value: 'blocked_accumulation',
        },
        {
          label: props.t('customer-wallet-status-blocked_redeem'),
          value: 'blocked_redeem',
        },
      ],
    },
    {
      field: 'date',
      type: 'date',
      name: props.t('created_at'),
      value: createdAtFilter,
      onChange: setCreatedAtFilter,
      options: {
        altInput: true,
        altFormat: 'd/m/Y',
        mode: 'range',
        dateFormat: 'd.m.y',
      },
      ref: datePickerRef,
    },
  ];

  const getStatusBadgeClasses = (status) => {
    switch (status) {
    case 'active':
      return 'bg-success-subtle text-success';
    case 'inactive':
      return 'bg-warning-subtle text-warning';
    default:
      return 'bg-danger-subtle text-danger';
    }
  };

  const getData = () => {
    const appliedFilters = getFilters(filters, [
      { field: 'document', type: 'like' },
    ]);
    const params = {
      page: searchParams.get('page') || 1,
      filters: {
        origin: {
          in: ['lead_capture'],
        },
      },
    };

    if (!_.isEmpty(appliedFilters)) {
      params.filters = { ...params?.filters, ...appliedFilters };
    }

    dispatch(getCustomers(params));
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t('document'),
        accessor: 'document',
        filterable: false,
        Cell: (props) => formatDocument(props.row.original.document),
        reportProps: {
          formatter: {
            type: 'mask',
            properties: { custom: 'document' },
          },
        },
      },
      {
        Header: props.t('name'),
        accessor: 'name',
        filterable: false,
      },
      {
        Header: props.t('balance'),
        accessor: 'balance',
        filterable: false,
        Cell: (props) => formatCurrency(props.row.original?.amount_available),
        reportProps: {
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('status'),
        accessor: 'status',
        filterable: false,
        Cell: (info) => {
          const status = info.row.original?.status;
          const text = props.t(`customer-wallet-status-${status}`);
          const cn = getStatusBadgeClasses(status);

          return <span className={`badge rounded-pill ${cn}`}>{text}</span>;
        },
        reportProps: {
          formatter: {
            type: 'enum',
            properties: {
              options: [
                {
                  key: 'active',
                  value: props.t('customer-wallet-status-active'),
                },
                {
                  key: 'inactive',
                  value: props.t('customer-wallet-status-inactive'),
                },
                {
                  key: 'blocked',
                  value: props.t('customer-wallet-status-blocked'),
                },
                {
                  key: 'blocked_redeem',
                  value: props.t('customer-wallet-status-blocked_redeem'),
                },
                {
                  key: 'blocked_accumulation',
                  value: props.t('customer-wallet-status-blocked_accumulation'),
                },
              ],
            },
          },
        },
      },
      {
        Header: props.t('created_at'),
        accessor: 'date',
        filterable: false,
        Cell: (props) => formatDate(props.row.original?.date, DATE_FORMAT),
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: DATE_FORMAT },
          },
        },
      },
      {
        Header: props.t('updated_at'),
        accessor: 'updated_at',
        filterable: false,
        Cell: (props) =>
          formatDate(props.row.original?.updated_at, DATE_FORMAT),
        reportProps: {
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: DATE_FORMAT },
          },
        },
      },
      {
        Header: props.t('actions'),
        Cell: (cellProps) => {
          const document = cellProps.row.original.document;
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li>
                <Link to={`/carteiras/detalhes/${document}`}>
                  <i className="ri-eye-fill align-bottom text-muted" />
                </Link>
              </li>

              <Can I="update" a="lead_capture">
                <li>
                  <Link
                    className="edit-item-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditWalletData(cellProps.row.original);
                      setEditWalletModal(true);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom text-muted" />
                  </Link>
                </li>
              </Can>
            </ul>
          );
        },
        reportProps: {
          exclude: true,
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={props.t('lead-capture.dashboard.title')}
            pageTitle="Gestão"
          />

          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col xs={12} sm={5} lg={6} xl={3}>
                      <Search
                        inputMode="numeric"
                        placeholder={props.t('search-by-document')}
                        value={formatDocument(searchParams?.get('document'))}
                        onSubmit={({ id }) =>
                          setSearchParams('document', cleanDocument(id))
                        }
                      />
                    </Col>

                    <Col sm={1} hidden={!isLoading}>
                      {isLoading && <Spinner color="secondary" />}
                    </Col>

                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setSearchParams('showFilter', true)}
                          disabled={isLoading}
                        >
                          <i className="ri-filter-3-line align-bottom me-1" />
                          {props.t('filters')}
                        </button>

                        <ExportReport
                          pages={meta?.pages}
                          disabled={isLoading || !customers?.length}
                          filename={props.t(
                            'lead-capture.dashboard.report-name'
                          )}
                          service="customers"
                          columns={columns}
                          data={{
                            ...(searchParams.get('document')
                              ? {
                                document: cleanDocument(
                                  searchParams.get('document')
                                ),
                              }
                              : {}),
                          }}
                          filters={{
                            origin: {
                              in: ['lead_capture'],
                            },

                            ...(createdAtFilter?.length
                              ? {
                                created_at: { between_date: createdAtFilter },
                              }
                              : {}),

                            ...(searchParams?.getAll('balance')?.length === 2
                              ? {
                                balance: {
                                  between: [
                                    convertToCents(
                                      searchParams.getAll('balance')[0]
                                    ),
                                    convertToCents(
                                      searchParams.getAll('balance')[1]
                                    ),
                                  ],
                                },
                              }
                              : {}),

                            status: {
                              in: searchParams?.getAll('status')?.length
                                ? searchParams?.getAll('status')
                                : [
                                  'active',
                                  'inactive',
                                  'blocked',
                                  'blocked_accumulation',
                                  'blocked_redeem',
                                ],
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody className="pt-0">
                  <Can I="read" a="lead_capture">
                    <TableContainer
                      isLoading={isLoading}
                      columns={columns}
                      data={customers || []}
                      activePage={Number(searchParams.get('page')) || 1}
                      pageSize={meta?.take || 10}
                      totalPages={meta?.pages || 0}
                      count={meta?.total || 0}
                      setPage={(p) => setSearchParams('page', p)}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Filter
        filters={filters}
        values={filters.map(({ field }) => ({ field }))}
      />

      <EditDigitalWallet
        isOpen={editWalletModal}
        toggle={() => setEditWalletModal(!editWalletModal)}
        onSuccess={() => getData()}
        customer={editWalletData}
      />

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </>
  );
};

export default withTranslation()(LeadCaptureDashboard);
