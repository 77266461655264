import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAccountStrategy as getAccountStrategyApi,
  updateAccountStrategy as updateAccountStrategyApi,
  getStrategies as getStrategiesApi,
  createStrategy as createStrategyApi,
  batchImportBlockedProducts as batchImportBlockedProductsApi,
  deleteBlockedProduct as deleteBlockedProductApi,
  updateBlockedProduct as updateBlockedProductApi,
  getStrategyDetails as getStrategyDetailsApi,
  deleteStrategyProducts as deleteStrategyProductsApi,
  batchImportCatalogStrategy as batchImportCatalogStrategyApi,
  getStrategyFilters as getStrategyFiltersApi,
} from '../../helpers/bff_helper';
import { toast } from 'react-toastify';

export const getAccountStrategy = createAsyncThunk(
  'strategies/getAccountStrategy',
  async (params) => {
    try {
      const response = getAccountStrategyApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateAccountStrategy = createAsyncThunk(
  'strategies/updateAccountStrategy',
  async ({ data, successMessage, errorMessage }) => {
    try {
      const response = await updateAccountStrategyApi(data);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const getStrategies = createAsyncThunk(
  'strategies/getCatalogStrategies',
  async (params) => {
    try {
      const response = getStrategiesApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createStrategy = createAsyncThunk(
  'strategies/createStrategy',
  async ({ strategy, successMessage, errorMessage }) => {
    try {
      const response = await createStrategyApi(strategy);
      toast.success(successMessage, { autoClose: 5000 });
      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });

      return {
        error: true,
        message: error,
      };
    }
  }
);

export const batchImportBlockedProducts = createAsyncThunk(
  'strategies/upload-block-products-csv',
  async (params) => {
    try {
      const response = await batchImportBlockedProductsApi(params);
      toast.success(params?.successMessage);
      return JSON.stringify(response);
    } catch (error) {
      toast.error(params?.errorMessage);
      return error;
    }
  }
);

export const batchImportCatalogStrategy = createAsyncThunk(
  'strategies/upload-catalog-strategy-csv',
  async (params) => {
    try {
      const response = await batchImportCatalogStrategyApi(params);
      toast.success(params?.successMessage);
      return JSON.stringify(response);
    } catch (error) {
      toast.error(params?.errorMessage);
      return error;
    }
  }
);

export const deleteBlockedProduct = createAsyncThunk(
  'DELETE - strategies/products/blocked/:external_id',
  async ({ successMessage, errorMessage, externalId }) => {
    try {
      const response = await deleteBlockedProductApi(externalId);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });

      return {
        error: true,
        message: error,
      };
    }
  }
);

export const updateBlockedProduct = createAsyncThunk(
  'PATCH - strategies/products/blocked/:external_id',
  async ({ successMessage, errorMessage, externalId, ...data }) => {
    try {
      const response = await updateBlockedProductApi(externalId, data);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });

      return {
        error: true,
        message: error,
      };
    }
  }
);

export const getStrategyProducts = createAsyncThunk(
  'strategies/getStrategyProducts',
  async (params) => {
    try {
      return await getStrategyDetailsApi(params);
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const deleteStrategyProducts = createAsyncThunk(
  'strategies/deleteStrategyProducts',
  async ({ items, successMessage, errorMessage }) => {
    try {
      await deleteStrategyProductsApi({ items });
      toast.success(successMessage, { autoClose: 5000 });
      return { message: 'ok', error: undefined };
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const getStrategyFilters = createAsyncThunk(
  'strategies/getFilters',
  async () => {
    try {
      return await getStrategyFiltersApi();
    } catch (error) {
      return error;
    }
  }
);
