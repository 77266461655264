import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getStoresRaw as getStoresRawApi,
  getStoreUsers as getStoreUsersApi,
  getStoreUsersAll as getStoreUsersAllApi,
  patchStoreUsers as patchStoreUsersApi,
  getStore as getStoreApi,
  createStore as createStoreApi,
} from '../../helpers/bff_helper';
import qs from 'qs';
import { toast } from 'react-toastify';

export const getStoreById = createAsyncThunk('stores/getById', async (id) => {
  try {
    return getStoreApi(id);
  } catch (error) {
    return error;
  }
});

export const getStoresRaw = createAsyncThunk(
  'users/getStoresRaw',
  async (params) => {
    try {
      const response = getStoresRawApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStoreUsers = createAsyncThunk(
  'stores/getStoreUsers',
  async ({ storeId, all, ...params }) => {
    const QS = qs.stringify(params, { addQueryPrefix: true });
    try {
      return all
        ? getStoreUsersAllApi(storeId, QS)
        : getStoreUsersApi(storeId, QS);
    } catch (error) {
      return error;
    }
  }
);

export const patchStoreUsers = createAsyncThunk(
  'stores/patchStoreUsers',
  async ({ storeId, users, successMessage, errorMessage }) => {
    try {
      const response = patchStoreUsersApi(storeId, users);
      toast.success(successMessage, { autoClose: 5000 });
      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);
