export const isInvalid = (form, field) => {
  return !!(form.touched[field] && form.errors[field]);
};

export const cleanDocument = (value) =>
  value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');

export const currencyFieldToNumber = (value) => {
  return parseInt(
    parseFloat(String(value).replaceAll('.', '').replaceAll(',', ''))
  );
};

export const currencyFieldToFloat = (value) => {
  return parseFloat(String(value).replaceAll('.', '').replaceAll(',', '.'));
};
