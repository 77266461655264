import Quill from 'quill';
import { Component } from 'react';
import { Label } from 'reactstrap';
import '../../assets/vendor/quill.snow.css';

const quill_i18n = () => `
  .ql-tooltip[data-mode=link]::before {
    content: "Insira o link" !important;
  }

  .ql-snow .ql-tooltip::before {
    content: "Visitar";
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    content: "Editar" !important;
  }

  .ql-snow .ql-tooltip a.ql-remove::before {
    content: "Remover" !important;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: "Salvar" !important;
  }
`;

function validateUrl(value) {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

class Editor extends Component {
  invalid = this.props.invalid;
  quill = null;
  limit = 1000;

  componentDidMount() {
    this.quill = new Quill(`#${this.props.id ?? 'editor'}`, {
      theme: 'snow',
      modules: {
        toolbar: [['bold', 'italic', 'underline'], ['link']],
      },
      formats: {
        link: { target: '_blank' },
      },
    });

    const Link = Quill.import('formats/link');
    // Override the existing property on the Quill global object and add custom protocols
    Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'sms'];

    // https://github.com/quilljs/quill/issues/262#issuecomment-948890432
    class CustomLinkSanitizer extends Link {
      static sanitize(url) {
        // Run default sanitize method from Quill
        const sanitizedUrl = super.sanitize(url);

        // Not whitelisted URL based on protocol so, let's return `blank`
        if (!sanitizedUrl || sanitizedUrl === 'about:blank')
          return sanitizedUrl;

        // Verify if the URL already have a whitelisted protocol
        const isWhiteListed = this.PROTOCOL_WHITELIST.some(function (protocol) {
          return sanitizedUrl.startsWith(protocol);
        });

        if (isWhiteListed) return sanitizedUrl;

        // if not, then append only 'http' to not to be a relative URL
        return `https://${sanitizedUrl}`;
      }
    }

    // https://github.com/quilljs/quill/issues/262#issuecomment-948890432
    Quill.register(CustomLinkSanitizer, true);

    // modifying a default blot
    let Bold = Quill.import('formats/bold');
    Bold.tagName = 'B';
    Quill.register(Bold, true);

    if (this.props.value && this.quill) {
      const delta = this.quill.clipboard.convert(this.props.value);
      this.quill.setContents(delta, 'silent');
    }

    this.quill.on('text-change', (x) => {
      if (this.quill.getLength() > this.limit) {
        this.quill.deleteText(this.limit, this.quill.getLength());
        this.props.onChange(this.quill.root.innerHTML);
      }

      this.props.onChange(this.quill.root.innerHTML);
    });
  }

  componentDidUpdate() {
    this.quill.enable(!this.props.disabled);

    if (this.props.disabled !== undefined) {
      const editor = document.querySelectorAll('.ql-editor');
      const toolbar = document.querySelectorAll('.ql-toolbar');
      const color = this.props.disabled ? '#f0f2f6' : '#fff';

      Array?.from(editor)?.map((e) => {
        e.style.background = color;
        e.style.cursor = 'default';
      });

      Array?.from(toolbar)?.map((t) => {
        t.style.background = color;
      });
    }
  }

  render() {
    return (
      <div>
        {this.props.label && (
          <Label className="form-label fs-5 fw-semibold text-muted">
            {this.props.label}
          </Label>
        )}

        <style>{quill_i18n()}</style>
        <div id={this.props.id ?? 'editor'} />

        {this.quill && (
          <div className="text-end">
            {this.quill.getLength() - 1}/{this.limit}
          </div>
        )}
      </div>
    );
  }
}

export default Editor;
