import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import Breadcrumb from '../../Components/Common/BreadCrumb';
import TableContainer from '../../Components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getRolesPaginated, getUsers } from '../../slices/thunks';
import { formatCurrency, formatDate } from '../../helpers/format';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { first } from 'lodash';
import { Link } from 'react-router-dom';

const Permission = (props) => {
  document.title = 'Permissões | Opencashback';

  const [createUserModal, setCreateUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [editUser, setEditUser] = useState({});

  const toggleCreateUser = () => {
    setCreateUserModal(!createUserModal);
  };

  const toggleEditUser = () => {
    setEditUserModal(!editUserModal);
  };

  const dispatch = useDispatch();
  const rolesSelector = createSelector(
    (state) => state.User.roles,
    (roles) => roles
  );

  const metaSeletor = createSelector(
    (state) => state.User.rolesMeta,
    (meta) => meta
  );

  const isLoadingSelector = createSelector(
    (state) => state.User.isLoadingRoles,
    (isLoading) => isLoading
  );

  const roles = useSelector(rolesSelector);
  const meta = useSelector(metaSeletor);
  const isLoading = useSelector(isLoadingSelector);

  const [page, setPage] = useState(1);

  const getData = () => {
    const params = {
      page,
    };

    dispatch(getRolesPaginated(params));
  };

  useEffect(() => {
    getData();
  }, [dispatch, page]);

  const getStatusBadgeClasses = (status) => {
    switch (status) {
    case 'active':
      return 'bg-success-subtle text-success';
    case 'inactive':
      return 'bg-warning-subtle text-warning';
    default:
      return 'bg-danger-subtle text-danger';
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t('name'),
        accessor: 'name',
        filterable: false,
        Cell: (info) => info.row.original.name,
      },
      {
        Header: props.t('created-at'),
        accessor: 'created_at',
        filterable: false,
        Cell: (info) => formatDate(info.row.original.created_at),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Permissões" pageTitle="Configurações" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm={1}>{isLoading && <Spinner color="primary" />}</Col>
                    <div className="col-sm-auto ms-auto">
                      <div className="hstack gap-2">
                        {/* <button type="button" className="btn btn-secondary" onClick={() => {}}>
                          <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                          {props.t('filters')}
                        </button> */}
                        {/* <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={toggleCreateUser}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          {props.t('new-user')}
                        </button> */}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={roles || []}
                      activePage={page}
                      pageSize={meta?.take || 10}
                      totalPages={meta?.pages || 0}
                      count={meta?.total || 0}
                      setPage={setPage}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(Permission);
