import { useState } from 'react';
import _, { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';

import Search from '../Search';
import useQsParams from '../../Hooks/QueryString';

function CheckboxFilter({ item, values, t }) {
  const { qs, setSearchParams, setSearchParamsAsObject } = useQsParams();
  let ARRAY_ITEMS = values.filter((v) => v.value);

  if (item?.values?.length) {
    ARRAY_ITEMS = item?.values.filter((preset) =>
      [...ARRAY_ITEMS].find((dynamic) => dynamic.value === preset?.value)
    );
  }

  const [list, setList] = useState([...ARRAY_ITEMS]);
  const [result] = useState('');

  const _manageArrayParamAsString = (_key, value) => {
    const raw = qs.get(_key);
    const ARRAY = raw ? raw.split(',') : [];
    const existing = _.uniq([...ARRAY]);

    if (existing.indexOf(value) != -1) {
      existing.splice(existing.indexOf(value), 1);
    } else {
      existing.push(value);
    }

    setSearchParamsAsObject({
      [_key]: existing.join(','),
      page: 1,
    });
  };

  const filterResults = (v) => {
    if (!v?.length) return setList(ARRAY_ITEMS);

    setList(
      list.filter((item) =>
        item?.label?.toString().toLowerCase()?.includes(v?.toLowerCase())
      )
    );
  };

  return (
    <div className="mb-4 filter-item">
      <Label className="form-label text-muted text-uppercase fw-semibold mb-3 d-flex justify-content-between">
        <span>{item.name}</span>

        {qs.get(item.field)?.length && list?.length ? (
          <span
            className="fw-normal"
            onClick={() => setSearchParams(item.field, '')}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              fontSize: 11,
            }}
          >
            {t('clear')}
          </span>
        ) : null}
      </Label>

      {values?.length >= 10 ? (
        <div className="mb-2">
          <Search
            placeholder={`Filtrar ${list?.length} itens`}
            onSubmit={({ id }) => filterResults(id)}
            onChange={filterResults}
            value={result}
          />
        </div>
      ) : null}

      <div
        className="px-1"
        style={{
          maxHeight: 150,
          borderRadius: 4,
          border: '1px solid #ced4da',
          overflowY: 'scroll',
          overflowX: 'auto',
        }}
      >
        {(
          (!isEmpty(list) && list) ||
					(!isEmpty(item.values) && item.values) ||
					[]
        ).map((value, index) => {
          const i18nPattern = `filters-values.${value.label}`;
          const i18nParsed = t(i18nPattern);
          const hasI18n = i18nParsed !== i18nPattern;

          return (
            <div className="form-check my-1" key={index}>
              <Input
                type="checkbox"
                className="form-check-input"
                defaultValue={value.value}
                id={`checkbox-${item.field}-${index}`}
                checked={
                  qs
                    .get(item.field)
                    ?.split(',')
                    ?.includes(String(value.value)) ?? false
                }
                onChange={(e) => {
                  _manageArrayParamAsString(item.field, e.target.value);
                }}
              />

              <Label
                className="form-check-label"
                htmlFor={`checkbox-${item.field}-${index}`}
              >
                {hasI18n ? i18nParsed : value.label}
              </Label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withTranslation()(CheckboxFilter);
