import { createSlice } from '@reduxjs/toolkit';
import {
  getIncentiveGroups,
  getIncentiveGroup,
  getIncentiveGroupUsers,
  getSmartIncentives,
  getIncentiveDetailsFilters,
} from './thunk';

export const initialState = {
  isLoading: false,
  groups: [],
  group: {},
  users: [],
  filters: [],
  meta: {},
  error: {},
  smart: {},
  isError: false,
};

const incetiveSlice = createSlice({
  name: 'Incentives',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIncentiveGroups.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getIncentiveGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.groups = action.payload?.data;
      state.group = {};
      state.users = [];
      state.meta = action.payload?.meta;
    });
    builder.addCase(getIncentiveGroups.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getIncentiveGroup.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getIncentiveGroup.fulfilled, (state, action) => {
      const rawData = [...action.payload.data];

      state.isLoading = false;
      state.group = {
        group: action.payload.group,
        data: rawData?.length
          ? rawData.map((item) => ({
            id: item?.incentive_id,
            ...item,
          }))
          : [],
      };
      state.meta = action.payload?.meta;
    });
    builder.addCase(getIncentiveGroup.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getIncentiveGroupUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getIncentiveGroupUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      const raw = action?.payload || action?.payload?.data;
      if (raw?.length) {
        state.users = raw.map((u) => ({
          label: u.name,
          value: parseInt(u.id),
        }));
      }
    });
    builder.addCase(getIncentiveGroupUsers.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getSmartIncentives.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSmartIncentives.fulfilled, (state, action) => {
      state.isLoading = false;
      const payload = action.payload?.map((p, k) => ({ id: ++k, ...p }));
      state.smart = Object.groupBy(payload, ({ type }) => type);
      state.meta = action.payload?.meta;
    });
    builder.addCase(getSmartIncentives.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });

    builder.addCase(getIncentiveDetailsFilters.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIncentiveDetailsFilters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filters = action.payload?.filters;
    });
    builder.addCase(getIncentiveDetailsFilters.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
      console.log(action);
    });
  },
});

export default incetiveSlice.reducer;
