import React, { useEffect, useMemo, useState, useReducer } from 'react';
import { withTranslation } from 'react-i18next';
import TableContainer from '../../Components/Common/TableContainer';
import { formatCurrency, formatDate } from '../../helpers/format';
import { useDispatch } from 'react-redux';
import { Can } from '../../helpers/casl';
import { getCustomerOperations } from '../../slices/customers/thunk';
import EditOrderDropdown from '../OrdersManagement/components/EditOrderDropdown';
import ApproveOrder from '../OrdersManagement/components/ApproveOrder';
import CancelOrder from '../OrdersManagement/components/CancelOrder';
import { ToastContainer } from 'react-toastify';

const Operations = ({ document, setLoading, isLoading, t }) => {
  const dispatch = useDispatch();
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});

  const getData = async () => {
    setLoading(true);
    const order = 'desc';
    const response = await dispatch(
      getCustomerOperations({ document, params: { page, order } })
    );
    setLoading(false);

    if (response?.error) {
      return;
    }

    const payload = response.payload;

    setData(payload.data);
    setMeta(payload.meta);
  };

  useEffect(() => {
    getData();
  }, [dispatch, page]);

  const [modals, dispatchModal] = useReducer(
    (state, action) => {
      switch (action.type) {
      case 'toggleApproveOrderModal':
        return {
          ...state,
          isApproveOrderModalOpened: !state?.isApproveOrderModalOpened,
        };
      case 'toggleCancelOrderModal':
        return {
          ...state,
          isCancelOrderModalOpened: !state?.isCancelOrderModalOpened,
        };
      default:
        throw Error('Unknown modal action.');
      }
    },
    {
      isApproveOrderModalOpened: false,
      isCancelOrderModalOpened: false,
    }
  );

  const translations = {
    created: {
      label: t(`orders-management.cashback-status-created`),
      color: 'bg-primary-subtle text-primary',
    },

    scheduled: {
      label: t(`orders-management.cashback-status-scheduled`),
      color: 'bg-warning-subtle text-warning',
    },

    done: {
      label: t(`orders-management.cashback-status-done`),
      color: 'bg-success-subtle text-success',
    },

    error: {
      label: t(`orders-management.cashback-status-erro`),
      color: 'bg-warning-subtle text-warning',
    },
    invalid: {
      label: t(`orders-management.cashback-status-erro`),
      color: 'custom-invalid',
    },

    abandoned: {
      label: t(`orders-management.cashback-status-cancelled`),
      color: 'bg-danger-subtle text-danger',
    },
    cancelled: {
      label: t(`orders-management.cashback-status-cancelled`),
      color: 'bg-danger-subtle text-danger',
    },

    expired: {
      label: t(`orders-management.cashback-status-expired`),
      color: 'bg-primary-subtle text-light',
    },
  };

  const getTranslationOrderStatus = (status) => {
    return translations[status];
  };

  const columns = useMemo(
    () => [
      {
        Header: t('type'),
        accessor: 'type',
        filterable: false,
        Cell: (props) => t(props.row.original.type),
      },
      {
        Header: t('ticket'),
        accessor: 'external_id',
        filterable: false,
      },
      {
        Header: t('operation-total'),
        accessor: 'total',
        filterable: false,
        Cell: (props) => formatCurrency(props.row.original.total),
      },
      {
        Header: t('received-cashback'),
        accessor: 'cashback',
        filterable: false,
        Cell: (props) => formatCurrency(props.row.original.cashback),
      },
      {
        Header: t('order-status'),
        accessor: 'status',
        filterable: false,
        Cell: (props) => {
          const translation = getTranslationOrderStatus(
            props.row.original.status
          );

          return (
            <React.Fragment>
              <span className={`badge rounded-pill ${translation?.color}`}>
                {translation?.label}
              </span>
            </React.Fragment>
          );
        },
      },
      {
        Header: t('created-at'),
        accessor: 'created_at',
        filterable: false,
        Cell: (props) => formatDate(props.row.original.created_at),
      },
      {
        Header: t('actions'),
        Cell: (props) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <Can I="update" a="orders">
                <li className="list-inline-item" title="Edit">
                  <EditOrderDropdown
                    order={props.row.original}
                    onClickApprove={() => {
                      setSelectedOrder(props.row.original);
                      dispatchModal({ type: 'toggleApproveOrderModal' });
                    }}
                    onClickCancel={() => {
                      setSelectedOrder(props.row.original);
                      dispatchModal({ type: 'toggleCancelOrderModal' });
                    }}
                  />
                </li>
              </Can>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TableContainer
        isLoading={isLoading}
        columns={columns}
        data={data || []}
        activePage={page}
        pageSize={meta?.take || 10}
        totalPages={meta?.pages || 0}
        count={meta?.total || 0}
        setPage={setPage}
        className="custom-header-css"
        divClass="table-responsive table-card mb-0"
        tableClass="align-middle table-nowrap"
        theadClass="table-light"
      />

      <ApproveOrder
        isOpen={modals.isApproveOrderModalOpened}
        toggle={() => dispatchModal({ type: 'toggleApproveOrderModal' })}
        onSuccess={() => getData()}
        order={selectedOrder}
      />

      <CancelOrder
        isOpen={modals.isCancelOrderModalOpened}
        toggle={() => dispatchModal({ type: 'toggleCancelOrderModal' })}
        onSuccess={() => getData()}
        order={selectedOrder}
      />

      <ToastContainer position="top-center" closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default withTranslation()(Operations);
