import { createSlice } from '@reduxjs/toolkit';
import {
  deleteProduct,
  getBlockedProducts,
  getCategories,
  getProducts,
} from './thunk';

export const initialState = {
  isLoading: false,
  catalog: {
    products: [],
    categories: [],
    collections: [],
    blocked: [],
  },
  meta: {},
  error: {},
  isError: false,
};

const catalogSlice = createSlice({
  name: 'Catalogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.catalog.products = action.payload?.data;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });

    builder.addCase(getCategories.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.catalog.categories = action.payload?.data;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });

    builder.addCase(deleteProduct.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.meta = action.payload?.meta;
    });
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });

    builder.addCase(getBlockedProducts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBlockedProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.catalog.blocked = action.payload?.data;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getBlockedProducts.rejected, (state, action) => {
      state.isError = true;
      state.error = action.payload?.error || null;
    });
  },
});

export default catalogSlice.reducer;
