import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import classnames from 'classnames';

const InformativeBar = ({ infos }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            {(infos || []).map((item, index) => (
              <Col
                lg={item.col_lg}
                xl={item.col_xl}
                className={classnames({
                  'border-end': infos.length - 1 !== index,
                })}
                key={index}
              >
                <div className="mt-3 mt-md-0 py-4 px-3">
                  <h5 className="text-muted text-uppercase fs-13">
                    {item.label}
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className={`display-6 text-muted ${item.icon}`}></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0">
                        <span className="counter-value">
                          <CountUp
                            start={0}
                            prefix={item.prefix || ''}
                            suffix={item.suffix || ''}
                            separator={item.separator || ''}
                            end={item.value || 0}
                            decimals={item.decimals || 0}
                            duration={item.duration || 4}
                          />
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default InformativeBar;
