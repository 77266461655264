import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getIntegrations as getIntegrationsApi,
  putIntegration as putIntegrationApi,
  getIntegrationStatus as getIntegrationStatusApi,
} from '../../helpers/bff_helper';

export const getIntegrations = createAsyncThunk(
  'integrations/getIntegrations',
  async () => {
    try {
      return getIntegrationsApi();
    } catch (error) {
      return error;
    }
  }
);

export const putIntegration = createAsyncThunk(
  'integrations/putIntegration',
  async ({ success, error, values }) => {
    try {
      const response = await putIntegrationApi(values);
      toast.success(success, { autoClose: 5000 });
      return response;
    } catch (e) {
      if (!e?.id) toast.error(error, { autoClose: 5000 });
      return { error: true, message: e };
    }
  }
);

export const getIntegrationStatus = createAsyncThunk(
  'integrations/getIntegrationStatus',
  async () => {
    try {
      return getIntegrationStatusApi();
    } catch (error) {
      return error;
    }
  }
);
