import { isEmpty, isNil } from 'lodash';
import { cloneElement } from 'react';
import { Importer, ImporterField, enUS, ptBR } from 'react-csv-importer';
import { getI18n } from 'react-i18next';

/**
 * Renders react-csv-importer
 *
 * @example
 * return (
 *  <CsvImporter
 *    fields={[
 *      { name: 'fieldName', label: 'Field Name', optional: true }
 *    ]}
 *    onComplete={(fields) => console.info(fields)}
 *    onClose={(fields) => console.info(fields)}
 *    dataHandler={(fields) => console.info(fields)}
 *    restartable={false}
 *  />
 * )
 *
 * @param fields Array of fields to be matched (eg: [{ name: string, label: string, optinoal: boolean }])
 * @param onComplete Callback function to be called when the user finish matching fields
 * @param onClose Callback function to be called when the process finishes
 * @param dataHandler Callback function to be called after the file is parsed
 * @param restartable Boolean to inform multiple file uploads
 * @returns {JSX.Element}
 */
const CsvImporter = ({
  fields,
  onClose,
  onComplete,
  dataHandler,
  restartable = false,
}) => {
  const count = [];

  return (
    <Importer
      newline=""
      delimiter={[',', ';', '\t', '\n']}
      locale={getI18n().language === 'pt' ? ptBR : enUS || ptBR}
      restartable={restartable}
      defaultNoHeader={false}
      skipEmptyLines={'greedy'}
      chunkSize={10000}
      onClose={onClose}
      onComplete={onComplete}
      dataHandler={(rows) => {
        if (rows?.length) count.push(rows?.length);
        dataHandler(count);
      }}
    >
      {fields?.length &&
				fields?.map((props, i) =>
				  cloneElement(<ImporterField key={`fI-${i}`} />, props)
				)}
    </Importer>
  );
};

export default CsvImporter;
