import { withTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';

import useQsParams from '../../Hooks/QueryString';
import classNames from 'classnames';
import { useState } from 'react';

function TextFilter({
  item,
  key,
  t,
  value = undefined,
  onChange = undefined,
  ...rest // disabled, readOnly, showToggle, description etc
}) {
  const { qs, setSearchParamsAsObject } = useQsParams();
  const field = value ?? qs.get(item.field);

  const [hideContent, setHideContent] = useState(true);
  const showToggle = rest?.showToggle ?? false;

  const isControlledField = onChange && value !== undefined;

  return (
    <div
      className={classNames({
        'mb-4 ': true,
        'filter-item': !rest?.dry ?? false,
      })}
    >
      <Label
        htmlFor={`text-${key}`}
        className="d-flex flex-column text-muted mb-3"
      >
        <span className="form-label text-muted text-uppercase fw-semibold m-0">
          {item.name}
        </span>

        {rest?.description ? (
          <span className="fs-9 mt-1 me-5" style={{ lineHeight: '1.3em' }}>
            {rest?.description}
          </span>
        ) : null}
      </Label>

      <div className="position-relative">
        {showToggle && field !== '' && (
          <div className="position-absolute z-1 top-50 end-0 me-2 translate-middle-y">
            <i
              style={{ cursor: 'pointer' }}
              className="ri-eye-line align-middle text-dark"
              onClick={() => setHideContent(!hideContent)}
            />
          </div>
        )}

        <Input
          type="text"
          id={isControlledField ? item.field : `text-${key}`}
          className={classNames({
            'form-control position-relative': true,
            'pe-5': showToggle,
          })}
          placeholder={t('enter-the-text')}
          value={field ?? ''}
          onChange={(e) => {
            const val = e?.target?.value;
            const payload = { [item.field]: val, page: 1 };
            !onChange ? setSearchParamsAsObject(payload) : onChange(e);
          }}
          {...rest}
          {...(showToggle && !hideContent ? { type: 'text' } : {})}
        />
      </div>
    </div>
  );
}

export default withTranslation()(TextFilter);
