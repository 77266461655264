import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import {
  getCommunicationRulesByChannel,
  getCommunicationTemplate,
  getTemplateVariables,
  updateCommunicationTemplate,
  updateCommunicationTemplateAssociatedRules,
} from '../../../../slices/communication/thunk';

import { downloadHtml, formatHtml } from '../../../../helpers/html_helper';
import Editor2 from '../../../../Components/Common/Editor2';
import ActionButton from '../../../../Components/Common/ActionButton';
import useQsParams from '../../../../Components/Hooks/QueryString';
import { cleanup } from '../../../../slices/communication/reducer';
import Dropdown from '../../../../Components/Common/Dropdown';
import { type } from '../types';
import { sanitizeSmsBodyAndAppendSuffix } from '../helper';

const MAX_TITLE_EMAIL = 60;
const MAX_BODY_SMS = 140;

const TemplateForm = ({ t }) => {
  const dispatch = useDispatch();
  const [prevTitle, setPrevTitle] = useState('');
  const [editTitle, setEditTitle] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [contentError, setContentError] = useState(false);
  const { qs, setQs } = useQsParams();

  const { template, templateType, isLoading } = useSelector(
    createSelector(
      (state) => state.Communication,
      ({ isLoading, template }) => ({
        isLoading,
        template,
        templateType: template?.type ?? '',
      })
    )
  );

  const { rules, isLoadingRules, selectedRules } = useSelector(
    createSelector(
      (state) => state.Communication,
      ({ isLoading, rules }) => {
        const items = rules?.map((v) => ({
          template_id: v?.template_id,
          label: v?.event_name,
          value: v?.id,
        }));

        return {
          isLoadingRules: isLoading,
          selectedRules: items.filter((r) => r.template_id == qs.get('edit')),
          rules: items,
        };
      }
    )
  );

  const isSms = ['sms'].includes(String(templateType).toLowerCase());
  const isEmail = ['email', 'e-mail'].includes(templateType);
  const isWhatsapp = ['whatsapp'].includes(String(templateType).toLowerCase());

  const formik = useFormik({
    title: '',
    subject: '',
    content: '',
    selectedRules: [],

    enableReinitialize: true,

    initialValues: {
      title: template?.title || '',
      content: template?.content || '',
      subject: template?.subject || '',
      selectedRules: [...selectedRules.map((v) => v.value)],
    },

    validationSchema: Yup.object({
      title: Yup.string().required(),
      subject: Yup.string(),
      content: isEmail
        ? Yup.string().required()
        : isSms
          ? Yup.string().max(MAX_BODY_SMS).required()
          : Yup.string(),
    }),

    onSubmit: async ({ selectedRules, ...values }) => {
      setDisabled(true);
      let content = values.content;

      const updateAssociatedRules = await dispatch(
        updateCommunicationTemplateAssociatedRules({
          templateId: qs.get('edit'),
          selectedRules,
        })
      );

      if (!updateAssociatedRules) {
        toast.error(t('communication-templates.form.error'));
        return false;
      }

      if (isSms) {
        content = sanitizeSmsBodyAndAppendSuffix(
          content,
          t('communication-templates.form.sms.rules')
        );
      }

      if (!isWhatsapp) {
        const updateCommunication = await dispatch(
          updateCommunicationTemplate({
            id: qs.get('edit'),
            title: values.title,
            subject: values.subject,
            ...(isWhatsapp ? {} : { content }),
          })
        );

        if (!updateCommunication) {
          toast.error(t('communication-templates.form.error'));
          return false;
        }
      }

      setDisabled(false);
      toast.success(t('communication-templates.form.success'));
      setQs('edit', '');
    },
  });

  useEffect(() => {
    const id = qs.get('edit');
    if (id) dispatch(getCommunicationTemplate(id));
    dispatch(getTemplateVariables());
  }, [qs]);

  useEffect(() => {
    if (template?.type) dispatch(getCommunicationRulesByChannel(template.type));
  }, [template]);

  useEffect(() => {
    const updateFields = async () => {
      let content = '';

      try {
        content = await formatHtml(template?.content);
        setContentError(false);
      } catch (_) {
        content = template?.content;
        setContentError(true);
      }

      setTimeout(() => {
        setPrevTitle(template?.title);
        formik.setFieldValue('subject', template?.subject ?? '');
        formik.setFieldValue('content', content);
      }, 100);
    };

    if (template) updateFields();
  }, [template]);

  return (
    <div className="d-flex flex-column gap-5">
      <div className="row">
        <div className="col flex-grow-1">
          {isLoading ? (
            <div className="card" style={{ height: 92 }}>
              <div className="card-body bg-light h-100 skeleton" />
            </div>
          ) : (
            <Card className="m-0">
              <CardBody>
                <div className="d-flex gap-2">
                  {editTitle ? (
                    <div
                      className="w-100 d-flex flex-row gap-3 align-items-center"
                      style={{ height: 40 }}
                    >
                      <h4 className="m-0">
                        {t('communication-templates.form.heading')}
                      </h4>

                      <Input
                        type="text"
                        className="w-50 m-0 py-1"
                        value={formik.values.title}
                        onChange={(e) => {
                          formik.setFieldValue('title', e.target.value);
                        }}
                      />

                      <div className="d-flex flex-row gap-2 mt-1">
                        <button
                          type="button"
                          className="p-0 lw-1 bg-white border-0"
                          onClick={() => {
                            formik.setFieldValue('title', prevTitle);
                            setEditTitle(!editTitle);
                          }}
                        >
                          <i className="bx bx-x fs-2 text-warning" />
                        </button>

                        <button
                          type="button"
                          className="p-0 lw-1 bg-white border-0"
                          onClick={() => {
                            setPrevTitle(formik.values.title);
                            setEditTitle(!editTitle);
                          }}
                        >
                          <i className="bx bx-check fs-2 text-success" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="d-flex flex-row gap-3 align-items-center"
                      style={{ height: 40 }}
                    >
                      <h4 className="m-0">
                        {t('communication-templates.form.heading')}
												&nbsp;&nbsp;&nbsp;&ldquo;{formik.values.title}
												&rdquo;
                      </h4>

                      {!isWhatsapp ? (
                        <button
                          type="button"
                          className="p-0 mt-1 lw-1 bg-white border-0"
                          onClick={() => setEditTitle(!editTitle)}
                        >
                          <i className="bx bxs-edit-alt fs-3 text-muted" />
                        </button>
                      ) : null}
                    </div>
                  )}
                </div>

                <span className="mt-2 text-muted">
                  {t('communication-templates.form.email.description')}
                </span>
              </CardBody>
            </Card>
          )}
        </div>

        <div className="col-lg-auto col-12 mt-lg-0 mt-3">
          {isLoading ? (
            <div className="card" style={{ height: 92, width: 400 }}>
              <div className="card-body w-100 bg-light h-100 skeleton" />
            </div>
          ) : (
            <Card className="m-0 h-100">
              <CardBody className="d-flex flex-column flex-md-row align-items-sm-center gap-3">
                <input
                  hidden
                  id="html-upload"
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();

                    reader.onload = async (e) => {
                      const raw = e.target.result;
                      const formatted = await formatHtml(raw);
                      formik.setFieldValue('content', formatted);
                    };

                    if (reader && file) reader.readAsText(file);
                  }}
                />

                {isEmail ? (
                  <ActionButton
                    style="primary"
                    label={t('communication-templates.form.email.upload')}
                    icon="bx-upload"
                    onClick={() =>
                      document.getElementById('html-upload')?.click()
                    }
                    disabled={disabled}
                  />
                ) : null}

                {isEmail ? (
                  <ActionButton
                    style="primary"
                    label={t('communication-templates.form.email.download')}
                    icon="bx-download"
                    onClick={() =>
                      downloadHtml(
                        formik.values.content,
                        `${formik.values.title}.html`
                      )
                    }
                    disabled={disabled}
                  />
                ) : null}

                <ActionButton
                  label={t('communication-templates.form.save')}
                  icon="bx-check"
                  onClick={formik.submitForm}
                  disabled={!formik.isValid || disabled}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>

      <div>
        {selectedRules?.length || rules?.length ? (
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <Dropdown
                    isMulti
                    disabled={isLoading}
                    label={t('communication-templates.form.rules.label')}
                    description={t(
                      'communication-templates.form.rules.description'
                    )}
                    values={selectedRules}
                    options={rules}
                    onChange={(selectedValues) => {
                      const valuesArr = selectedValues.reduce(
                        (acc, curr) => [...acc, curr.value],
                        []
                      );

                      formik.setFieldValue('selectedRules', valuesArr);
                    }}
                  />

                  {!formik.values.selectedRules?.length ? (
                    <span className="form-label fs-6 d-block text-danger mt-3">
                      {t('communication-templates.form.rules.empty')}
                    </span>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <div className="card" style={{ height: 150 }}>
            <div className="w-100 card-body bg-light h-100 skeleton" />
          </div>
        )}

        <Row>
          <Col sm={12}>
            {isLoading ? (
              <div className="card" style={{ height: 800 }}>
                <div className="w-100 card-body bg-light h-100 skeleton" />
              </div>
            ) : (
              <Card>
                <CardBody>
                  {isEmail ? (
                    <div className="mb-4">
                      <Editor2
                        id="subject"
                        label={t('communication-templates.form.email.subject')}
                        placeholder={t(
                          'communication-templates.form.email.subject-placeholder'
                        )}
                        onChange={(v) => formik.setFieldValue('subject', v)}
                        invalid={!!formik.errors.subject}
                        value={formik.values.subject}
                        config={{
                          formatOnPaste: false,
                          lineNumbers: false,
                          highlightActiveLine: false,
                          maxCharacters: MAX_TITLE_EMAIL,
                        }}
                      />
                    </div>
                  ) : null}

                  <Editor2
                    id="content"
                    label={t(
                      isEmail
                        ? 'communication-templates.form.email.content'
                        : 'communication-templates.form.sms.content'
                    )}
                    error={
                      contentError
                        ? t('communication-templates.form.email.error')
                        : null
                    }
                    placeholder={t(
                      isEmail
                        ? 'communication-templates.form.email.content-placeholder'
                        : 'communication-templates.form.sms.content-placeholder'
                    )}
                    onChange={(v) => {
                      const content = isSms ? _.deburr(v) : v;
                      formik.setFieldValue('content', content);
                    }}
                    invalid={!!formik.errors.content}
                    value={formik.values.content}
                    config={{
                      type: templateType,
                      height: isEmail ? 545 : 200,
                      formatOnPaste: isEmail,
                      lineNumbers: isEmail,
                      highlightActiveLine: isEmail,
                      maxCharacters: isSms || isWhatsapp ? MAX_BODY_SMS : 0,
                      lineWrap: isSms || isWhatsapp,
                      disabled: isWhatsapp,
                    }}
                  />
                </CardBody>
              </Card>
            )}
          </Col>

          <Col
            sm={12}
            md={isSms || isWhatsapp ? 8 : 12}
            className={isSms || isWhatsapp ? 'px-3' : 'px-5'}
          >
            {isLoading ? (
              <div className="card" style={{ height: 800 }}>
                <div className="w-100 card-body bg-light h-100 skeleton" />
              </div>
            ) : (
              <div
                style={{
                  border: '15px solid black',
                  borderRadius: 30,
                  width: '100%',
                }}
              >
                <div
                  style={{ borderRadius: '15px 15px 0 0' }}
                  className="p-3 fs-5 bg-light"
                >
                  <div
                    className={classNames({
                      'd-flex row': true,
                      'justify-content-between': isEmail,
                    })}
                  >
                    {isEmail ? (
                      <div className="col">
                        <p className="m-0">
                          {t('communication-templates.form.email.subject')}
                        </p>
                        <span className="fw-semibold">
                          {formik.values.subject || '-'}
                        </span>
                      </div>
                    ) : null}

                    <div
                      className={classNames({
                        'd-flex justify-content-end align-items-center': true,
                        'col-auto': isEmail,
                      })}
                    >
                      <div className="border border-2 border-success py-2 px-4 rounded-pill shadow-lg">
                        <span className="fs-5 fw-semibold">
                          {type[template.type] ?? '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="w-100 bg-white overflow-x-auto"
                  style={{
                    borderRadius: '0 0 15px 15px',
                    height: isEmail ? 700 : 250,
                    padding: isSms || isWhatsapp ? 10 : 0,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
												isEmail || isWhatsapp
												  ? formik.values.content
												  : sanitizeSmsBodyAndAppendSuffix(
												    formik.values.content,
												    t('communication-templates.form.sms.rules')
												  ),
                    }}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(TemplateForm);
