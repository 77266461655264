import React, { useEffect, useMemo, useState, useReducer } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';

import { Can } from '../../../helpers/casl';
import Search from '../../../Components/Common/Search';
import Breadcrumb from '../../../Components/Common/BreadCrumb';
import ExportReport from '../../../Components/Common/ExportReport';
import TableContainer from '../../../Components/Common/TableContainer';
import RangeDatePicker from '../../../Components/Common/RangeDatePicker';
import DetailIncentiveDropdown from './components/DetailIncentiveDropdown';
import {
  getIncentiveDetailsFilters,
  getIncentiveGroup,
  getIncentiveGroupUsers,
} from '../../../slices/thunks';
import {
  formatCurrency,
  formatDate,
  formatDocument,
} from '../../../helpers/format';
import CancelIncentive from './components/CancelIncentive';
import PresetsFilter from '../../../Components/Common/PresetsFilter';
import useQsParams from '../../../Components/Hooks/QueryString';
import Filter, { getFilters } from '../../../Components/Common/Filter';
import EmptyList from '../../../Components/Common/EmptyList';
import ReactSelect from 'react-select';

const IncentiveDetail = (props) => {
  document.title = 'Grupo de incentivo | Opencashback';

  const dispatch = useDispatch();
  const { groupId } = useParams();
  const [createdAt, setCreatedAt] = useState([]);

  const { setQs, qs, searchParams, setSearchParams, setSearchParamsAsObject } =
		useQsParams();

  const _parseCreatedAt = () => {
    const hasCreatedAt = qs.get('created_at');
    let dateRange = [];

    if (hasCreatedAt) {
      const from = hasCreatedAt?.split(',')?.[0];
      const to = hasCreatedAt?.split(',')?.[1];

      dateRange = [
        moment.unix(from).format('D/M/YY'),
        moment.unix(to || from).format('D/M/YY'),
      ];
    }

    setCreatedAt(dateRange);
  };

  useEffect(() => {
    _parseCreatedAt();
  }, [qs.get('created_at')]);

  const groupSelector = createSelector(
    (state) => state.Incentive.group,
    (group) => group
  );

  const metaSeletor = createSelector(
    (state) => state.Incentive.meta,
    (meta) => meta
  );

  const isLoadingSelector = createSelector(
    (state) => state.Incentive.isLoading,
    (isLoading) => isLoading
  );

  const usersSelector = createSelector(
    (state) => state.Incentive.users,
    (group) => group
  );

  const meta = useSelector(metaSeletor);
  const group = useSelector(groupSelector);
  const users = useSelector(usersSelector);
  const isLoading = useSelector(isLoadingSelector);

  const incentivesFilter = useSelector(
    createSelector(
      (state) => state.Incentive.filters,
      (filters) => filters
    )
  );

  const filters = [
    {
      field: 'incentive_amount',
      name: 'Incentivo Enviado',
      type: 'range',
    },
    {
      field: 'amount_redeemed',
      name: 'Saldo consumido',
      type: 'range',
    },
    {
      field: 'amount_available',
      name: 'Saldo disponível',
      type: 'range',
    },
    {
      field: 'created_at',
      name: 'Disponibilização',
      type: 'date',
      // options: { maxDate: false },
    },
    {
      field: 'amount_expires_at',
      name: 'Expiração',
      type: 'date',
      options: { maxDate: false },
    },
    // {
    //   field: "user_id",
    //   name: "Usuário",
    //   type: "checkbox",
    //   values: [...users],
    // },
    {
      field: 'customer_cellphone',
      name: 'Telefone completo',
      type: 'radio',
    },
    {
      field: 'customer_cellphone_verified',
      name: 'Telefone validado',
      type: 'radio',
    },
    {
      field: 'customer_email',
      name: 'E-mail completo',
      type: 'radio',
    },
    {
      field: 'customer_birthdate',
      name: 'Data de aniversário',
      type: 'date',
      options: { maxDate: false },
    },
  ];

  const parsedFilters = getFilters(filters, [
    { field: 'customer_document', type: 'like' },
    { field: 'status', type: 'in' },
    { field: 'user_id', type: 'in' },
    { field: 'amount_expires_at', type: 'between_date' },
    { field: 'num_orders_related', type: 'between' },
    { field: 'incentive_group_id', type: 'between' },
    { field: 'customer_cellphone_verified', type: 'eq-as-raw' },
  ]);

  const getData = () => {
    let params = { page: searchParams.get('page') ?? 1 };
    const appliedFilters = parsedFilters;

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    dispatch(getIncentiveGroup({ groupId, ...params }));
    dispatch(getIncentiveGroupUsers(groupId));
  };

  const [selectedIncentive, setSelectedIncentive] = useState(null);

  const [modals, dispatchModal] = useReducer(
    (state, action) => {
      switch (action.type) {
      case 'toggleCancelIncentiveModal':
        return {
          ...state,
          isCancelIncentiveModalOpened: !state?.isCancelIncentiveModalOpened,
        };
      default:
        throw Error('Unknown modal action.');
      }
    },
    {
      isCancelIncentiveModalOpened: false,
    }
  );

  const translations = {
    done: {
      label: props.t(`orders-management.cashback-status-done`),
      color: 'bg-success-subtle text-success',
    },
    cancelled: {
      label: props.t(`orders-management.cashback-status-cancelled`),
      color: 'bg-danger-subtle text-danger',
    },
    expired: {
      label: props.t(`orders-management.cashback-status-expired`),
      color: 'bg-primary-subtle text-light',
    },
  };

  const getTranslationIncentiveStatus = (status) => {
    return translations[status];
  };

  useEffect(() => {
    getData();
    dispatch(getIncentiveDetailsFilters());
  }, [qs.toString()]);

  const columns = useMemo(
    () => [
      {
        Header: props.t('incentives-details.document'),
        Cell: ({ row }) => formatDocument(row.original.customer_document),
        reportProps: {
          accessor: 'customer_document',
          formatter: {
            type: 'mask',
            properties: { custom: 'document' },
          },
        },
      },
      {
        Header: props.t('incentives-details.name'),
        Cell: ({ row }) => row.original.customer_name || '-',
        reportProps: {
          accessor: 'customer_name',
        },
      },
      {
        Header: props.t('incentives-details.incentive'),
        Cell: ({ row }) => formatCurrency(row.original.incentive_amount),
        reportProps: {
          accessor: 'incentive_amount',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('incentives-details.available'),
        Cell: ({ row }) => formatCurrency(row.original.amount_available),
        reportProps: {
          accessor: 'amount_available',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('incentives-details.redeemed'),
        Cell: ({ row }) => formatCurrency(row.original.amount_redeemed),
        reportProps: {
          accessor: 'amount_redeemed',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('Status'),
        accessor: 'status',
        filterable: false,
        Cell: (props) => {
          const translation = getTranslationIncentiveStatus(
            props.row.original.status
          );

          return (
            <React.Fragment>
              <span className={`badge rounded-pill ${translation?.color}`}>
                {translation?.label}
              </span>
            </React.Fragment>
          );
        },
      },
      {
        Header: props.t('incentives-details.user'),
        Cell: ({ row }) => row.original.user_name || '-',
        reportProps: {
          accessor: 'user_name',
        },
      },
      {
        Header: props.t('incentives-details.expiration'),
        Cell: ({ row }) =>
          formatDate(row.original.amount_expires_at, 'DD/MM/YYYY'),
        reportProps: {
          accessor: 'amount_expires_at',
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },
      {
        Header: props.t('incentives-details.created'),
        Cell: ({ row }) => formatDate(row.original.date, 'DD/MM/YYYY'),
        reportProps: {
          accessor: 'date',
          formatter: {
            type: 'date',
            properties: { tz: 'America/Sao_Paulo', format: 'DD/MM/YYYY' },
          },
        },
      },
      {
        Header: props.t('orders-generated'),
        accessor: 'orders-generated',
        filterable: false,
        Cell: ({ row }) => row.original.num_orders_related,
        reportProps: {
          accessor: 'num_orders_related',
        },
      },
      {
        Header: props.t('incremental-billing-generated'),
        accessor: 'incremental-billing-generated',
        filterable: false,
        Cell: ({ row }) =>
          formatCurrency(row.original.total_amount_orders_related),
        reportProps: {
          accessor: 'total_amount_orders_related',
          formatter: {
            type: 'currency',
            properties: { currency: 'BRL', centsToCurrency: true },
          },
        },
      },
      {
        Header: props.t('discount-in-incremental-billing'),
        accessor: 'discount-in-incremental-billing',
        filterable: false,
        Cell: ({ row }) => {
          const raw = row.original.discount_in_total_orders_amount;

          let value = Math.round(raw * 100);
          let color = '#212529';

          const low = value >= 0.1 && value <= 10;
          const regular = value >= 11 && value <= 40;
          const good = value >= 41 && value <= 70;
          const high = value >= 71;

          if (low) color = 'green';
          if (regular) color = 'blue';
          if (good) color = 'orange';
          if (high) color = 'tomato';

          return (
            <span className="fw-semibold" style={{ color }}>
              {value > 100 ? 100 : value}%
            </span>
          );
        },
        reportProps: {
          accessor: 'discount_in_total_orders_amount',
          formatter: {
            type: 'decimal_percentage',
            properties: { value: 0 },
          },
        },
      },
      {
        Header: props.t('actions'),
        Cell: (props) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <Can I="update" a="incentives">
                <li className="list-inline-item" title="Edit">
                  <DetailIncentiveDropdown
                    incentive={props.row.original}
                    onClickCancel={() => {
                      setSelectedIncentive(props.row.original);
                      dispatchModal({ type: 'toggleCancelIncentiveModal' });
                    }}
                  />
                </li>
              </Can>
            </ul>
          );
        },
        reportProps: {
          exclude: true,
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            showBackButton="/incentivos"
            pageTitle={props.t('incentives')}
            title={`${props.t('incentives-details.header')} ${
              group?.group?.name ?? ''
            }`}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <Row className="row-cols-1 row-cols-sm-2 row-cols-md-12 g-2 g-lg-3">
                    <Col xl={3} xxl={3}>
                      <Search
                        placeholder={props.t('document')}
                        inputMode="numeric"
                        value={
                          [11, 14].includes(
                            qs?.get('customer_document')?.length
                          )
                            ? formatDocument(
                              searchParams?.get('customer_document')
                            )
                            : searchParams?.get('customer_document')
                        }
                        onSubmit={({ id }) => {
                          const clean = String(id).replace(/[^0-9]/g, '');
                          setSearchParamsAsObject({
                            customer_document: id ? clean : '',
                            page: 1,
                          });
                        }}
                      />
                    </Col>

                    <Col xl={3} xxl={2}>
                      <RangeDatePicker
                        onClear={() => setSearchParams('created_at', '')}
                        placeholder={props.t('incentives-details.created')}
                        value={createdAt}
                        onChange={(v) => {
                          if (v[0] && v[1]) {
                            const d1 = moment(v[0]).unix();
                            const d2 = moment(v[1]).unix();
                            setSearchParamsAsObject({
                              created_at: !d2 ? d1 : `${d1},${d2}`,
                              page: 1,
                            });
                          }
                        }}
                      />
                    </Col>

                    {users?.length ? (
                      <Col xl={3} xxl={2}>
                        <ReactSelect
                          isClearable
                          name="user_id"
                          options={[...users]}
                          onChange={(e) => {
                            setSearchParamsAsObject({
                              user_id: e?.value || '',
                              page: 1,
                            });
                          }}
                          placeholder={props.t('incentives-details.user')}
                          value={
                            users?.length
                              ? users.find(
                                (k) =>
                                  k.value ===
																		parseFloat(searchParams?.get('user_id'))
                              )
                              : undefined
                          }
                        />
                      </Col>
                    ) : null}

                    <Col className="d-flex w-auto flex-grow-1 justify-content-center justify-content-md-end">
                      {isLoading ? (
                        <div className="me-4 me-md-auto">
                          <Spinner color="primary" />
                        </div>
                      ) : null}

                      <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={() => setQs('showFilter', true)}
                        disabled={isLoading}
                      >
                        <i className="ri-filter-3-line align-bottom me-1"></i>{' '}
                        {props.t('advanced-filters')}
                      </button>

                      <ExportReport
                        pages={meta?.pages}
                        disabled={isLoading || !group?.data?.length}
                        filename="Relatório OCK: Detalhes Grupo de incentivo"
                        service={`incentives/groups/${groupId}/details`}
                        columns={columns}
                        filters={parsedFilters}
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <div className="mt-3">
                  <PresetsFilter
                    items={[
                      {
                        label: 'Aprovados',
                        value: { status: 'done' },
                        key: 'incentives_done',
                      },
                      {
                        label: 'Cancelados',
                        value: { status: 'cancelled' },
                        key: 'incentives_cancelled',
                      },

                      {
                        label: 'Saldos expirados',
                        value: {
                          amount_expires_at: [
                            moment()
                              .subtract('10', 'years')
                              .startOf('day')
                              .unix(),
                            moment().subtract('1', 'day').endOf('day').unix(),
                          ].join(','),
                        },
                        key: 'amount_expired',
                      },
                      {
                        label: 'Saldos a expirar',
                        value: {
                          amount_expires_at: [
                            moment().startOf('day').unix(),
                            moment().add('10', 'years').endOf('day').unix(),
                          ].join(','),
                        },
                        key: 'amount_to_expire',
                      },

                      {
                        label: 'Com compras geradas',
                        value: { num_orders_related: [1, 300].join(',') },
                        key: 'with_orders',
                      },
                      {
                        label: 'Sem compras geradas',
                        value: { num_orders_related: [0, 0].join(',') },
                        key: 'without_orders',
                      },
                    ]}
                  />
                </div>

                <CardBody className="pt-0">
                  <Can I="read" a="incentives">
                    <div>
                      {!group?.data?.length && qs.toString() ? (
                        <div className="mt-3">
                          <EmptyList
                            heading={props.t('search-empty-heading')}
                            body={props.t(
                              'table-content-empty-results-incentive'
                            )}
                          />
                        </div>
                      ) : (
                        <TableContainer
                          isLoading={isLoading}
                          columns={columns}
                          data={group?.data || []}
                          pageSize={meta?.take || 10}
                          totalPages={meta?.pages || 0}
                          count={meta?.total || 0}
                          activePage={searchParams.get('page')}
                          setPage={(p) => setSearchParams('page', p)}
                          className="custom-header-css"
                          divClass="table-responsive table-card mb-0"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light"
                        />
                      )}
                    </div>
                  </Can>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Filter
        filters={filters}
        values={incentivesFilter}
        localePrefix="incentives-management"
        useGroups
      />

      <CancelIncentive
        isOpen={modals.isCancelIncentiveModalOpened}
        toggle={() => dispatchModal({ type: 'toggleCancelIncentiveModal' })}
        onSuccess={() => getData()}
        incentive={selectedIncentive}
      />
    </>
  );
};

export default withTranslation()(IncentiveDetail);
