import { useDebounce } from '@react-hooks-hub/use-debounce';
import { Card, CardBody, CardFooter, Input, Label } from 'reactstrap';
import ActionButton from '../../../../../../Components/Common/ActionButton';

const Tab1 = ({ dispatch, formik, disabled, setActiveTab }) => {
  const debounceDispatch = useDebounce(dispatch, 500);

  return (
    <Card>
      <CardBody className="d-flex flex-column gap-4 pb-4">
        <h3 className="mb-2">Tela inicial</h3>

        <div>
          <Label
            htmlFor="step1Heading"
            className="form-label fs-5 fw-semibold text-muted"
          >
						Título
          </Label>

          <Input
            type="text"
            id="step1Heading"
            name="step1Heading"
            className="form-control"
            placeholder="Texto título da sessão que contém o formulário"
            value={formik?.values?.step1Heading || ''}
            invalid={formik?.errors?.step1Heading}
            disabled={disabled}
            onBlur={() => formik.validateField('step1Heading')}
            onChange={(e) => {
              formik.handleChange(e);
              debounceDispatch({ step1Heading: e.target.value });
            }}
          />
        </div>
      </CardBody>

      <CardFooter className="text-end">
        <ActionButton
          label="Salvar e continuar"
          icon="bx-right-arrow-alt"
          onClick={async () => {
            const response = await formik.submitForm();
            if (response) setActiveTab(2);
          }}
          disabled={disabled}
        />
      </CardFooter>
    </Card>
  );
};

export default Tab1;
