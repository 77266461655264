import React, { useContext } from 'react';
import { Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { AbilityContext } from '../../../../helpers/casl';

const RedeemTimeForm = ({ form, ...props }) => {
  const ability = useContext(AbilityContext);

  const isInvalid = (form, field) => {
    return form.touched[field] && form.errors[field];
  };

  return (
    <div
      className="d-flex flex-column gap-3 mb-2 pb-4"
      style={{ borderBottom: '2px solid #eee' }}
    >
      <div>
        <h6 style={{ fontWeight: 'bold' }}>Prazo de Disponibilização</h6>

        <Row className="flex-column gap-3 flex-lg-row mt-3">
          <Col>
            <Label
              className="form-label"
              htmlFor="store-order-availability-period"
            >
              {props.t('store-order-availability-period')}
            </Label>
            <div className="input-group">
              <Input
                name="store_order_availability_time"
                type="text"
                className="form-control"
                id="store-order-availability-period"
                placeholder="2"
                invalid={isInvalid(form, 'store_order_availability_time')}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.store_order_availability_time}
                disabled={ability.cannot('update', 'account_strategies')}
              />
              <span className="input-group-text">{props.t('days')}</span>
              {isInvalid(form, 'store_order_availability_time') && (
                <FormFeedback type="invalid">
                  {form.errors.store_order_availability_time}
                </FormFeedback>
              )}
            </div>
          </Col>

          <Col>
            <Label
              className="form-label"
              htmlFor="ecommerce-order-availability-period"
            >
              {props.t('ecommerce-order-availability-period')}
            </Label>
            <div className="input-group">
              <Input
                name="ecommerce_order_availability_time"
                type="text"
                className="form-control"
                id="ecommerce-order-availability-period"
                placeholder="7"
                invalid={isInvalid(form, 'ecommerce_order_availability_time')}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.ecommerce_order_availability_time}
                disabled={ability.cannot('update', 'account_strategies')}
              />
              <span className="input-group-text">{props.t('days')}</span>
              {isInvalid(form, 'ecommerce_order_availability_time') && (
                <FormFeedback type="invalid">
                  {form.errors.ecommerce_order_availability_time}
                </FormFeedback>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(RedeemTimeForm);
