import { useEffect } from 'react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import useQsParams from '../../../Components/Hooks/QueryString';
import FileUpload from '../../../Components/Common/FileUpload';
import ActionButton from '../../../Components/Common/ActionButton';
import ColorPicker from '../../../Components/Common/ColorPicker';

const Step1 = ({ state, dispatch, formik, initialValues, ...props }) => {
  const { setQs } = useQsParams();

  useEffect(() => {
    dispatch({ step: 'general' });
  }, []);

  const VISUAL_PICKERS = [
    {
      label: props.t('my-wallet.step1.picker.spinner-title'),
      description: props.t('my-wallet.step1.picker.spinner-description'),
      state: 'spinnerColor',
    },
  ];

  return (
    <Card className="m-0">
      <CardBody>
        <h3 className="mb-4">{props.t('my-wallet.step1.logo')}</h3>
        <FileUpload
          stylePanelLayout="circle"
          styleLoadIndicatorPosition="center"
          styleProgressIndicatorPosition="center"
          styleButtonRemoveItemPosition="center"
          styleButtonProcessItemPosition="center"
          className="w-50"
          invalid={formik.errors?.logoUrl}
          onRemoveFile={() => {
            dispatch({ logoUrl: '' });
            formik?.setFieldValue('logoUrl', null);
          }}
          onFinish={(file) => {
            if (file) {
              dispatch({ logoUrl: file?.url });
              formik.setFieldValue('logoUrl', file?.url);
            }
          }}
          values={
            formik?.values?.logoUrl
              ? [
                {
                  source: formik?.values?.logoUrl,
                  options: { type: 'local' },
                },
              ]
              : []
          }
        />

        <h3 className="my-4 pt-4">{props.t('my-wallet.step1.elements')}</h3>
        <Row className="row row-cols-1 g-3 mb-5">
          {Object.keys(formik?.values)?.length
            ? VISUAL_PICKERS?.map(({ state: key, ...props }, i) => (
              <Col key={`pickr-${i}`}>
                <ColorPicker
                  {...props}
                  value={formik?.values?.[key] || '#fff'}
                  invalid={formik?.errors[key]}
                  onChange={(c) => {
                    dispatch({ [key]: c });
                    formik.setFieldValue(key, c);
                  }}
                />
              </Col>
            ))
            : null}
        </Row>
      </CardBody>

      <CardFooter className="text-end">
        <ActionButton
          icon="bx-right-arrow-alt"
          label="Salvar e continuar"
          onClick={async () => {
            const response = await formik.submitForm();
            if (response) setQs('active', 2);
          }}
        />
      </CardFooter>
    </Card>
  );
};

export default withTranslation()(Step1);
