import { useDebounce } from '@react-hooks-hub/use-debounce';
import { Card, CardBody, CardFooter, Input, Label } from 'reactstrap';
import ActionButton from '../../../../../../Components/Common/ActionButton';
import Editor from '../../../../../../Components/Common/Editor';
import useQsParams from '../../../../../../Components/Hooks/QueryString';

const Tab3 = ({ dispatch, formik, disabled }) => {
  const { setQs } = useQsParams();
  const debounceDispatch = useDebounce(dispatch, 500);
  const debounceField = useDebounce(formik.setFieldValue, 500);

  return (
    <Card>
      <CardBody className="d-flex flex-column gap-4 pb-4">
        <h3 className="mb-2">Erro ao criar carteira</h3>

        <div>
          <Label
            htmlFor="step3Heading"
            className="form-label fs-5 fw-semibold text-muted"
          >
						Título
          </Label>

          <Input
            type="text"
            id="step3Heading"
            name="step3Heading"
            className="form-control"
            placeholder="Título da sessão"
            value={formik.values.step3Heading || ''}
            invalid={formik?.errors?.step3Heading}
            disabled={disabled}
            onBlur={() => formik.validateField('step3Heading')}
            onChange={(e) => {
              formik.handleChange(e);
              debounceDispatch({ step3Heading: e.target.value });
            }}
          />
        </div>

        <div>
          <Editor
            id="step3Body"
            label="Mensagem de erro"
            value={formik?.values?.step3Body || ''}
            invalid={formik?.errors?.step3Body}
            disabled={disabled}
            onChange={(html) => {
              const isEmpty = html === '<p><br></p>';
              const value = isEmpty ? '' : html;
              debounceField('step3Body', value);
              debounceDispatch({ step3Body: value });
            }}
          />
        </div>
      </CardBody>

      <CardFooter className="text-end">
        <ActionButton
          label="Salvar e continuar"
          icon="bx-right-arrow-alt"
          onClick={async () => {
            const response = await formik.submitForm();
            if (response) setQs('active', 3);
          }}
          disabled={disabled}
        />
      </CardFooter>
    </Card>
  );
};

export default Tab3;
